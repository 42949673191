import {
	PatientRecord,
	ChatCompletions,
	DocumentType,
	SectionFormatting,
	DocumentStatus,
	MultiPatientData,
	TranscriptSection,
	TranscriptSectionStatus,
} from "@common/utils/types";
import _ from "lodash";
import { cloneDeep } from "lodash";
import { v4 as uuidv4 } from "uuid";

export function transformTableRecordToPatientRecord(patientRecordData: PatientRecord): PatientRecord {
	const { id, doctors, patient_id, patient_name, status, appointment_duration, scheduled_at, created_at, stage } =
		patientRecordData;
	const doctor_id = doctors?.id || null;

	return {
		id,
		doctor_id,
		patient_id,
		patient_name,
		status,
		appointment_duration,
		scheduled_at,
		created_at,
		stage,
	};
}

export function isSameDay(date1: Date, date2: Date | null) {
	if (!date2) return false;
	return (
		date1.getFullYear() === date2.getFullYear() &&
		date1.getMonth() === date2.getMonth() &&
		date1.getDate() === date2.getDate()
	);
}

export function createNewAssistantMessage(
	documentId: string,
	header: string,
	patientRecordId: string,
	transcriptionId: string,
	isJson: boolean = false,
	document: DocumentType | null,
	document_snapshot: SectionFormatting | null,
) {
	let newAssistantMessage: ChatCompletions | null = null;
	let newMessageId = uuidv4();
	newAssistantMessage = {
		id: newMessageId,
		role: "assistant",
		chat_json: {
			header: header,
			chat_text: null,
		},
		document_type_id: documentId,
		created_at: new Date().toISOString(),
		patient_record_id: patientRecordId,
		transcription_id: transcriptionId,
		document_types: {
			is_json: isJson,
			document_type_name: document?.document_type_name,
			creator_prompt_components: document?.creator_prompt_components,
		},
		document_snapshot: document_snapshot,
		header: header,
		status: DocumentStatus.Processing,
		chat_json_original: {
			header: header,
			chat_text: null,
		},
	} as ChatCompletions;

	return newAssistantMessage;
}

export function createTabId(documentId: string, header: string) {
	return `${documentId}_${header}`;
}

export function cloneDocument(document: ChatCompletions, newChatText: string, status: DocumentStatus) {
	let documentClone = cloneDeep(document);

	if (documentClone && documentClone?.chat_json) {
		documentClone.chat_json = {
			...documentClone.chat_json,
			chat_text: newChatText,
		};

		documentClone.status = status;
	}

	return documentClone;
}

/**
 * Determines the chat header and patient for a multiple patient case.
 * @param selectedMultiPatient - The selected patient name.
 * @param multiplePatients - An array of MultiPatientData objects representing multiple patients.
 * @param originalHeader - The original chat header.
 * @returns An object containing the updated header, a flag indicating if it's a multiple patient case, and the patient name to parse.
 */
export function determineChatHeaderAndPatientForMultiplePatientCase(
	selectedMultiPatient: string | null,
	multiplePatients: MultiPatientData[],
	originalHeader: string,
): { header: string; multiplePatientCase: boolean; patientNameToParse: string } {
	// Test original header to see if we're in a retry case
	let patientMatch = multiplePatients.find((patient: MultiPatientData) => {
		const regex = new RegExp(`\\(${patient.name}\\)`);
		return regex.test(originalHeader);
	});

	//Test or retry and patient match in original header
	if (patientMatch) {
		return {
			header: originalHeader,
			multiplePatientCase: true,
			patientNameToParse: patientMatch.name,
		};
		//Test whether or not we have a selected patient and are generating from Generate Documents
		//even if they're not still selected they need to be able to be re-ran
	} else if (selectedMultiPatient && multiplePatients?.length >= 2) {
		return {
			header: `${originalHeader}(${selectedMultiPatient})`,
			multiplePatientCase: true,
			patientNameToParse: selectedMultiPatient,
		};
		//No multiple patients
	} else {
		return {
			header: originalHeader,
			multiplePatientCase: false,
			patientNameToParse: "",
		};
	}
}

export const isTranscriptError = (transcriptSection: TranscriptSection) => (
	transcriptSection.status === TranscriptSectionStatus.FailedIncompleteUpload
	|| transcriptSection.status === TranscriptSectionStatus.FailedErrorProcessing
	|| transcriptSection.status === TranscriptSectionStatus.FailedBlankTranscript
	|| transcriptSection.status === TranscriptSectionStatus.FailedIrrelevantInformation
	|| (transcriptSection.status !== TranscriptSectionStatus.InProgress && transcriptSection?.data?.trim() === '')
)

/**
 * Determines the error state of a transcript section.
 * @param transcriptSection - The transcript section to check.
 * @param documentCreationTime - The creation time of the document.
 * @returns The error state of the transcript section. as 'error', 'warning', or 'none'.
 */
export function determineErrorState(transcriptSection: TranscriptSection | null, documentCreationTime: Date | null): 'error' | 'warning' | 'none' {
	if (!transcriptSection?.status) return 'none';
	if (transcriptSection?.status === TranscriptSectionStatus.InProgress) return 'none';
	if (transcriptSection?.error) return 'error';
	if (isTranscriptError(transcriptSection)) return 'error';
	if (documentCreationTime && (new Date(transcriptSection.created_at || '') > documentCreationTime)) return 'warning';

	return 'none';
}

/**
 * Determines the error message for a transcript section.
 * @param TranscriptStatus - The status of the transcript section.
 * @returns The error message for the transcript section.
 */
export function determineErrorMessage(transcriptSection: TranscriptSection): string {
	if (transcriptSection?.data?.trim() === "") {
		return "Transcript contains no content. Check for valid audio and retry."
	}

	let transcriptStatus = transcriptSection?.status;

	switch (transcriptStatus) {
		case TranscriptSectionStatus.FailedIncompleteUpload:
			return "There was a problem uploading the clip. Check Incomplete Uploads";
		case TranscriptSectionStatus.FailedErrorProcessing:
			return "There was an issue creating the transcript. Retry transcript processing.";
		case TranscriptSectionStatus.FailedBlankTranscript:
			return "Transcript contains no content. Check for valid audio and retry.";
		case TranscriptSectionStatus.FailedIrrelevantInformation:
			return "Transcript does not contain enough relevant information.";
		default:
			return "";
	}
}

/**
 * Determines if a string is a valid email address.
 * @param email - The email address to check.
 * @returns Whether the email address is valid.
 */
export function isValidEmail(email: string): boolean {
	// Regex explanation:
	// ^        - Start of string
	// [^\s@]+  - One or more characters that are not whitespace or @ symbol
	// @        - Literal @ symbol
	// [^\s@]+  - One or more characters that are not whitespace or @ symbol
	// \.       - Literal dot (period)
	// [^\s@]+  - One or more characters that are not whitespace or @ symbol
	// $        - End of string
	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return emailRegex.test(email);
}