import ProgressBar from "@/common/components/ProgressBar";
import {
	createJson,
	isJSON,
	copySectionToClipboard,
} from "@/common/utils/formatter";
import React, { useState, useEffect, useCallback } from "react";
import {
	ChatCompletions,
	DocumentStatus,
	DisplayDocument,
	PIMSType,
	DisplaySection,
} from "@/common/utils/types";
import { useAccountContext } from "@/state/providers/AccountProvider";
import { ErrorBoundary } from "@sentry/react";
import { cloneDeep } from "lodash";
import SyncConfirmation from "../sidebar/SyncConfirmation";
import Section from "@/common/components/json-document/Section";
import SendIcon from "@icons/send-01.svg?react";
import HDDCopyButton from "@/common/components/HDCopyButton";
import classNames from "classnames";

interface JsonContentProps {
	content: string;
	isStreaming: boolean;
	isEditing: boolean;
	newContent: string;
	setNewContent: React.Dispatch<React.SetStateAction<any>>;
	activeDocument: ChatCompletions | null;
	tabId: string;
}

/**
 * Render the JSON content of the document
 * @param props - the props containing content, isStreaming, isEditing, newHeader, newContent, setNewHeader, setNewContent, activeDocument, and tabId
 * @returns JSX.Element
 */
const JsonContent: React.FC<JsonContentProps> = ({
	content,
	isStreaming,
	isEditing,
	newContent,
	setNewContent,
	activeDocument,
	tabId,
}) => {
	const [open, setOpen] = useState(false);
	const [editedJson, setEditedJson] = useState<DisplayDocument | null>(null);
	const [progress, setProgress] = useState<number>(0);

	const { accountData, hasChromeExtension } = useAccountContext();
	const markdownEnabled = (accountData?.meta_data as { markdown_copy_enabled?: boolean })?.markdown_copy_enabled;

	// Update the edited JSON object when the content changes and the user is streaming
	useEffect(() => {
		if (isJSON(content) && isStreaming && activeDocument) {
			const { json, progress } = createJson(content, activeDocument?.document_snapshot ?? undefined);

			if (progress) {
				setProgress(progress);
			}

			setEditedJson(json ? cloneDeep(json) : null);
		}
	}, [content, isStreaming, activeDocument]);


	// Update the edited JSON object when the content changes and the user is not streaming
	useEffect(() => {
		if (isJSON(newContent) && !isStreaming && !isEditing && activeDocument) {
			const { json } = createJson(newContent);

			setEditedJson(json ? cloneDeep(json) : null);
		}
	}, [newContent, isStreaming, activeDocument, isEditing]);

	// Update the new content when the edited JSON object changes
	useEffect(() => {
		if (editedJson && !isStreaming && isEditing) {
			setNewContent(cloneDeep(editedJson));
		}
	}, [editedJson, setNewContent, isStreaming, isEditing]);

	// Add event listener to handle copying of text
	useEffect(() => {
		const handleCopy = (e: any) => {
			const selection = window.getSelection();
			const selectedText = selection?.toString();

			if (!selectedText) {
				// copy as normal if no text is selected
				return;
			}
			e.clipboardData.setData('text/plain', selectedText);
			e.preventDefault();
		};

		document.addEventListener('copy', handleCopy);

		return () => {
			document.removeEventListener('copy', handleCopy);
		};
	}, []);

	/**
	 * Checks if a DisplaySection is empty.
	 * @param {DisplaySection} section - The section to check.
	 * @returns {boolean} True if the section is empty, false otherwise.
	 */
	const isFieldEmpty = (section?: DisplaySection): boolean => {
		// check if the section is empty
		if (!section) {
			return true;
		}

		// check if the section has an array value with no elements
		if (Array.isArray(section.value) && section.value.length === 0) {
			return true;
		}

		// check if the section has a string value that is empty, and also no fields
		if (section.value === "" && (Array.isArray(section.fields) && section.fields.length === 0)) {
			return true;
		}

		// otherwise, the section is not empty
		return false;
	}

	/**
	 * Determines whether to show sync buttons based on various conditions.
	 * @param {DisplaySection} [section] - The section to check for emptiness (optional).
	 * @returns {boolean} True if sync buttons should be shown, false otherwise.
	 */
	const showSyncButtons = useCallback((section?: DisplaySection): boolean => {
		// base conditions that must always be true to show sync buttons
		const baseConditions = hasChromeExtension &&
			accountData?.current_pims === PIMSType.EZYVET &&
			!isEditing &&
			!isStreaming;

		// if a section is provided, check if it's not empty
		if (section !== undefined) {
			return baseConditions && !isFieldEmpty(section);
		}

		// if no section is provided, only consider the base conditions
		return baseConditions;
	}, [hasChromeExtension, accountData, isEditing, isStreaming]);


	/**
	 * Convert the json object to JSX
	 * @param json
	 * @returns JSX.Element
	 */
	const jsonToJSX = (json: DisplayDocument) => {
		if (!Array.isArray(json)) {
			return null;
		}

		return json?.map((section, index) => {
			// check the next section to see if the title is empty
			// if so, don't render the <hr> tag
			// will eval to false if out of bounds (thanks ?.)
			const nextSection = json[index + 1];
			const nextSectionHasTitle = nextSection?.name !== "";

			return (
				<React.Fragment key={index}>
					<div key={index} className="flex flex-row w-full">
						<div className="flex flex-1 items-center pl-4 pr-4 py-3">
							<div className="flex-1 ">
								<span className="">
									<strong className="text-sm font-semibold text-gray-900 leading-5">{section?.name?.toUpperCase()}</strong>
								</span>
								<div className="whitespace-pre-wrap mt-2">
									<ErrorBoundary fallback={<p>Unable to generate document. Please Retry.</p>} showDialog={true}>
										<Section section={section} isEditing={isEditing} sectionKey={index} editedJson={editedJson} setEditedJson={setEditedJson} />
									</ErrorBoundary>
								</div>
							</div>
						</div>
						{isEditing || isFieldEmpty(section) ? null : (
							<HDDCopyButton
								id={`copy-section-${section?.id}`}
								onCopy={() => copySectionToClipboard(index, section, markdownEnabled)}
							/>
						)}
					</div>
					<div className={classNames("my-2 flex justify-end gap-2 happydoc-ext-section-btns px-4 pb-4", showSyncButtons(section) ? "" : "hidden")}>
						<button
							id="approve-section"
							type="button"
							className="inline-flex items-center gap-x-1.5 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 happydoc-exportbtn"
							data-happydoc-id="happydoc-exportbtn"
							data-happydoc-content={JSON.stringify(section)}
							data-happydoc-section={section?.name}
						>
							<SendIcon className="w-4 h-4" />
							Approve & Sync
						</button>
					</div>
					{index < Object.keys(json)?.length - 1 && nextSectionHasTitle && <hr className="my-2" />}
				</React.Fragment>
			)

		});
	};

	// if the content is not a JSON string, render it as a paragraph
	if (!isJSON(content)) {

		return (
			<div className="p-4">
				{activeDocument?.status === DocumentStatus?.Processing ? <ProgressBar seconds={60} currentProgress={progress} tabId={tabId} isJson={true} /> : null}
				<p>{content}</p>
			</div>)
	}


	return (
		<ErrorBoundary fallback={<p>Unable to generate document. Please Retry.</p>} showDialog={true}>
			<div className="">
				{activeDocument?.status === DocumentStatus?.Processing ? <div className={classNames(editedJson?.length !== 0 ? "pt-3 pb-0" : "py-3", "px-4")}><ProgressBar seconds={60} currentProgress={progress} tabId={tabId} isJson={true} /> </div> : null}
				{editedJson && jsonToJSX(editedJson)}
				<div className={classNames("p-4  w-full happydoc-approveAllSection", showSyncButtons() ? "" : "hidden")}>
					<button
						id="approve-all-section"
						data-happydoc-id="happydoc-exportbtn"
						type="button"
						className="w-full inline-flex items-center justify-center gap-x-1.5 rounded bg-max-700 px-2 py-2 text-xs font-semibold text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 "
						onClick={() => {
							setOpen(true);
						}}
					>
						<SendIcon className="w-4 h-4" />
						Approve & Sync ALL
					</button>
				</div>
				<SyncConfirmation open={open} setOpen={setOpen} content={JSON.stringify(editedJson) || ""} />
			</div>
		</ErrorBoundary>
	);
};

export default JsonContent
