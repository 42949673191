import { PatientRecord } from "@/common/utils/types";
import EditIcon from "@icons/edit-05.svg?react";
import classNames from "classnames";

interface PatientInfoProps {
    patient: PatientRecord;
    isDisabled: boolean;
    handleEditButtonClick: (patient: any) => void;
}

const PatientInfo: React.FC<PatientInfoProps> = ({
    patient,
    isDisabled,
    handleEditButtonClick,
}) => {
    return (
        <div className="flex justify-between items-center">
            <div className="text-wrap inline-flex w-full flex-col justify-start">
                <div className="truncate max-w-[100px] md:max-w-[125px] lg:max-w-[150px]">
                    {patient.patient_name ? (
                        <span className="whitespace-normal">{patient.patient_name}</span>
                    ) : (
                        <span className="text-gray-500">-</span>
                    )}
                </div>

            </div>
            {/* Edit Button */}
            <div>
                <button
                    id="in-line-edit-patient"
                    type="button"
                    onClick={() => handleEditButtonClick(patient)}
                    disabled={isDisabled}
                    className={classNames(
                        isDisabled
                            ? 'hover:cursor-not-allowed text-gray-200'
                            : 'text-gray-400 hover:bg-gray-100',
                        'sm:inline-block ml-1 rounded-md px-2 py-1 text-sm font-medium focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-max-700'
                    )}
                >
                    <EditIcon className="h-4 w-4" aria-hidden="true" />
                </button>
            </div>
        </div>
    );
};

export default PatientInfo;