import CircleIcon from "@icons/circle.svg?react";
import CheckCircleIcon from "@icons/check-circle.svg?react";
import classNames from "classnames";
import { useState, useEffect, useCallback, useMemo } from "react";
import DocumentSelect from "@common/components/DocumentSelect";
import { toast } from "react-toastify";
import { useAppSelector, useAppDispatch } from "@/common/hooks/useRedux";
import {
	getTranscriptSections,
	getMultiplePatients,
	setSelectedMultiPatient,
	getPatientData,
} from "../../state/redux/activePatientDataSlice";
import { FeatureFlags, TranscriptSection, TranscriptSectionStatus } from "@common/utils/types";
import { getDocumentTypesByIdsList } from "@/common/lib/supabaseClient";
import FilePlusIcon from "@icons/file-plus-02.svg?react";
import InfoCircleIcon from "@icons/info-circle.svg?react";
import { MultiPatientData } from "@common/utils/types";
import _ from "lodash";
import { createTabId, determineChatHeaderAndPatientForMultiplePatientCase } from "@/common/utils/helpers";
import { documentTypeRemoved, removeDocumentForDoctor } from "@/state/redux/documentSlice";
import ConfirmModal from "@/common/components/ConfirmModal";
import { Tooltip } from "react-tooltip";
import { useAccountContext } from "@/state/providers/AccountProvider";
import TitleTooltipSwitch from "@/common/components/TitleTooltipSwitch";
import { v4 as uuidv4 } from "uuid";

const GenerateDocuments = ({
	setCompletionId,
	generateDocuments,
	generateLegacyDocument,
	isLoadingCompletion,
}: {
	setCompletionId: (id: string | null) => void;
	completionId: string | null;
	generateDocuments: (
		patientRecordId: string,
		transcriptionId: string,
		documentIds: string[],
		multiplePatientCase: boolean,
		patientNameToParse: string,
		fastGenerate: boolean
	) => void;
	generateLegacyDocument: (
		tabId: string,
		chatId: string,
		patientRecordId: string,
		transcriptionId: string,
		documentId: string,
		multiplePatientCase: boolean,
		patientNameToParse: string,
		fastGenerate: boolean
	) => void;
	isLoadingCompletion: boolean;
}) => {
	const dispatch = useAppDispatch();
	const { selectedDocuments } = useAppSelector((state) => state.documents);
	const transcriptSections: TranscriptSection[] = useAppSelector(getTranscriptSections);
	const multiplePatients: MultiPatientData[] = useAppSelector(getMultiplePatients);
	const patientData = useAppSelector(getPatientData);

	const [showHasArchivedModal, setShowHasArchivedModal] = useState(false);
	const [unUseableTranscript, setIsUnusableTranscript] = useState(false);

	const [fastGenerate, setFastGenerate] = useState(false);
	const { containsFeatureFlagOrIsTest } = useAccountContext();

	useEffect(() => {
		if (Array.isArray(transcriptSections)) {
			const isUnusable = transcriptSections.every((section) => ["loading", "", "error"].includes(section?.data || ""));
			if (isUnusable) {
				setIsUnusableTranscript(true);
			} else {
				setIsUnusableTranscript(false);
			}
		}
	}, [transcriptSections]);

	const handleGenerateDocuments = useCallback(async () => {
		if (selectedDocuments?.length > 0) {
			if (multiplePatients?.length > 1) {
				for (let patient of _.filter(multiplePatients, { isSelected: true })) {
					await generateDocumentsForPatient(selectedDocuments, patient.name);
				}
			}
			else {
				await generateDocumentsForPatient(selectedDocuments, patientData?.patient_name || "");
			}
		} else {
			toast.warn("Please select at least one document to generate");
			return;
		}

		/**
		 * Generates a document for a patient.
		 * 
		 * @param documentIds - The document ids to generate.
		 * @param patientName - The name of the patient.
		 * @returns The generated document.
		 */
		async function generateDocumentsForPatient(documentIds: string[], patientName: string) {
			const dbDocuments = await getDocumentTypesByIdsList(documentIds);

			if (!dbDocuments || dbDocuments.length < 1) {
				toast.error("Error fetching document types");
				return;
			}

			//HDD-594: always go get the most recent document definition from the DB before generating a document
			let { multiplePatientCase, patientNameToParse } =
				determineChatHeaderAndPatientForMultiplePatientCase(
					patientName,
					multiplePatients,
					dbDocuments[0]?.document_type_name
				);

			const docIds = [];
			for (const document of dbDocuments) {
				// check if the document is archived and if so, do not generate a new document and display a modal
				if (document?.is_archived) {

					// remove the document from the selected documents
					await dispatch(removeDocumentForDoctor({ documentTypeId: document?.id }) as any);

					dispatch(documentTypeRemoved(document?.id));

					// show the modal
					setShowHasArchivedModal(true);

					continue;
				}

				// gotta make the tab id
				let { header } =
					determineChatHeaderAndPatientForMultiplePatientCase(
						patientName,
						multiplePatients,
						document?.document_type_name
					);

				let tabId = createTabId(document?.id, header)

				// handle the legacy streaming case
				if (!document.is_json) {
					const chatId = uuidv4()
					setCompletionId(chatId);

					// generate legacy document
					await generateLegacyDocument(
						tabId,
						chatId,
						patientData.id,
						patientData?.transcriptions?.id,
						document?.id,
						multiplePatientCase,
						patientNameToParse,
						fastGenerate
					);
					continue;
				}

				// add the document to the list of documents to generate
				docIds.push(document?.id);
			}

			// generate em
			if (docIds.length > 0) {
				await generateDocuments(patientData.id, patientData?.transcriptions?.id, docIds, multiplePatientCase, patientNameToParse, fastGenerate);
			}
		}
	}, [
		selectedDocuments,
		dispatch,
		generateDocuments,
		multiplePatients,
		patientData,
		setCompletionId,
		fastGenerate,
	]);

	/**
	 * Determines whether the generate button should be disabled.
	 * The button is disabled if any of the following conditions are met:
	 * - No documents are selected
	 * - The completion is currently loading
	 * - There are no transcript sections
	 * - The transcript is unusable
	 * - There are multiple patient but all of them are unchecked
	 */
	const generateDisabled = selectedDocuments?.length < 1 ||
		isLoadingCompletion ||
		transcriptSections?.length < 1 ||
		unUseableTranscript ||
		(multiplePatients?.length > 0 && _.countBy(multiplePatients, "isSelected").false == multiplePatients.length);

	const isProcessingTranscriptSections = useMemo(() => {
		return transcriptSections.some(section => section.status === TranscriptSectionStatus.InProgress);
	}, [transcriptSections]);

	return (
		<div className="border-b border-gray-200 bg-white shadow-sm ring-1 ring-gray-200 rounded-md">
			<div className={classNames("flex items-center justify-between p-4 bg-gray-50 border-b border-gray-200 rounded-t-md")}>
				<div className={"flex items-center p-0 m-0"}>
					<h1
						className={classNames(
							transcriptSections?.length < 1 || unUseableTranscript ? "text-gray-400" : "text-gray-900",
							"text-lg lg:text-xl whitespace-nowrap",
						)}
					>
						Generate Documents
					</h1>
				</div>
			</div>
			<div className={"px-4 py-4"}>
				<DocumentSelect
					from="patient-page"
					disabled={isLoadingCompletion || transcriptSections?.length < 1 || unUseableTranscript}
					verticalLayout={true}
				/>
				<ConfirmModal
					id="archived-document-modal"
					onExit={() => setShowHasArchivedModal(false)}
					headerText="Document does not exist"
					contentText="The selected document no longer exists. Select a different document."
					isOpen={showHasArchivedModal}
					onCancel={() => setShowHasArchivedModal(false)}
					onConfirm={() => setShowHasArchivedModal(false)}
					confirmButtonText="OK"
					cancelButtonText="Cancel"
					confirmButtonColor="bg-max-700"
					confirmHoverButtonColor="hover:bg-max-800"
				/>
				{multiplePatients?.length > 0 && (
					<div className="flex flex-col gap-y-2 mt-2 ">
						<hr className="mt-2" />

						<div className="text-sm flex flex-row items-center justify-between">
							<div className="whitespace-break-spaces relative flex items-center">
								<div className="group flex items-center gap-2">
									Generate for:
									<span>
										<Tooltip
											style={{ maxWidth: '200px', zIndex: 9999, fontSize: '12px', fontFamily: 'Plus Jakarta Sans' }}
											anchorSelect="#generate-for"
											content="Document(s) selected above will generate for the chosen patient(s)."
										/>
										<span className="text-gray-400">
											<InfoCircleIcon id="generate-for" className={`h-[16px] w-[16px] mr-1 `} />
										</span>
									</span>
								</div>
							</div>
						</div>

						<div className="flex flex-row gap-x-4 gap-y-4 justify-start items-center flex-wrap">
							{multiplePatients.map((patient: MultiPatientData, index: number) => (
								<button
									id={`7d83073d-53a2-4278-9a52-4a63bd9712cd-${index}-${patient.name}`}
									key={`${index}-${patient}`}
									className={classNames(
										(patient.isSelected)
											? "ring-max-700"
											: "text-gray-900 ring-gray-300",
										"focused bg-white rounded-md  px-2 py-2 text-sm font-semibold hover:cursor-pointer shadow-sm ring-1 ring-inset ",
									)}
									onClick={() => { dispatch(setSelectedMultiPatient(patient)) }}
								>
									<span>
										{(patient.isSelected
											? <CheckCircleIcon className="h-4 w-4 mr-1 pb-1 inline text-max-700" />
											: <CircleIcon className="h-4 w-4 mr-1 pb-1 inline text-gray-400" />)}
										{patient.name}
									</span>
								</button>
							))}
						</div>
					</div>
				)}

				<div className="flex w-full justify-end mt-3 mb-2">
					<button
						id="generate-documents"
						type="button"
						className={classNames(
							'primary-button',
						)}
						disabled={generateDisabled}
						onClick={(e) => {
							e.preventDefault();
							if (
								selectedDocuments?.length < 1 ||
								isLoadingCompletion ||
								transcriptSections?.length < 1 ||
								unUseableTranscript
							) {
								toast.warn("Transcript is loading or blank! Try clicking, Open Transcript");
								return;
							}
							handleGenerateDocuments();
						}}
					>
						<FilePlusIcon className="h-5 w-5 mr-1" />
						{isProcessingTranscriptSections ? "Clip processing..." : "Generate"}
					</button>
				</div>
				{containsFeatureFlagOrIsTest(FeatureFlags.ENABLE_FAST_GENERATION) && (
					<div className="justify-start w-1/2">
						<TitleTooltipSwitch
							id="fast-generate-slider"
							title="Fast Generation"
							tooltip="Enable to generate documents faster (only for test accounts or feature flag enabled)"
							checked={fastGenerate}
							onCheck={(value) => setFastGenerate(value)}
						/>
					</div>
				)}
			</div>
		</div >
	);
};

export default GenerateDocuments;
