
import { copyEntireJsonToClipboard } from "@/common/utils/formatter";
import { ChatCompletions } from "@/common/utils/types";
import { useAccountContext } from "@/state/providers/AccountProvider";
import { useDataContextApi } from "@/state/providers/DataProvider";
import CopyIcon from "@icons/copy-03.svg?react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import { toolTipStyles } from "@/theme";

interface CopyProps {
    content: ChatCompletions;
}

function Copy({ content }: CopyProps) {
    const { sectionsAndFieldsToBold } = useDataContextApi();
    const { accountData } = useAccountContext();

    const markdownEnabled = (accountData?.meta_data as { markdown_copy_enabled?: boolean })?.markdown_copy_enabled;

    //Copies the entire Document
    const handleCopyEntireDocument = (isJson: boolean) => {
        if (isJson) {
            copyEntireJsonToClipboard(content?.chat_json?.chat_text, markdownEnabled);
        } else {
            // Split the text into lines
            const lines = content?.chat_json?.chat_text?.split("\n");
            let processedLines;
            // Process each line
            if (markdownEnabled) {
                processedLines = lines.map((line: string) => {
                    // Prepare the line for comparison by converting it to lowercase and removing colons
                    const lineForComparison = line.toLowerCase().replace(/:/g, "")?.trim() || "";

                    // Check if the processed line is in the creatorSectionNames set and not already wrapped in asterisks
                    if (sectionsAndFieldsToBold.has(lineForComparison) && !line.startsWith("**") && !line.endsWith("**")) {
                        // Wrap the line in asterisks for Markdown
                        return `**${line}**`;
                    }
                    return line;
                });
            } else {
                processedLines = lines.map((line: string) => {
                    // Remove asterisks from the line, whether or not it's in creatorSectionNames
                    const cleanedLine = line.replace(/\*\*/g, "");
                    return cleanedLine;
                });
            }
            // Join the processed lines back into a single string
            const processedText = processedLines?.join("\n");
            // Use navigator.clipboard.writeText to copy the processed text to the clipboard
            navigator.clipboard.writeText(processedText);
        }

        toast.success("Document copied to clipboard!");
    };

    return (
        <>
            <Tooltip
                content={"Copy Document"}
                anchorSelect="#copy-doc-button"
                style={toolTipStyles}
            />
            <button
                id="copy-doc-button"
                type="button"
                className="p-1 hover:bg-gray-100  hover:rounded-sm hover:text-gray-700 focused"
                onClick={() => handleCopyEntireDocument(content?.document_types?.is_json || false)}
            >
                <CopyIcon className="h-4 w-4" />

            </button>
        </>
    )
}

export default Copy