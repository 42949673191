import { useEffect, useMemo, useState } from "react";
import Document from "./Document";
import useScroll from "@common/hooks/useScroll";
import { DocumentTab, DocumentType, TranscriptSection, TranscriptSectionStatus } from "@common/utils/types";
import { useAppSelector, useAppDispatch } from "@/common/hooks/useRedux";
import {
	getAllDocumentTabs,
	getActiveTabId,
	updateActiveTabAndDocumentIndex,
	getActiveDocumentIndex,
	getActiveDocuments,
} from "../../state/redux/activeDocumentsSlice";
import { getPatientData, getMultiplePatients, getTranscriptSections } from "../../state/redux/activePatientDataSlice";
import { determineChatHeaderAndPatientForMultiplePatientCase } from "@/common/utils/helpers";
import TableTabs from "@/common/components/HDTabs";
import React from "react";
import FilePlusIcon from "@assets/hd-icons/file-plus-02.svg?react";

const DocumentTabs = ({
	setCompletionId,
	completionId,
	generateDocuments,
	isLoadingCompletion,
	completion,
	stop,
	stopGenerate,
}: {
	setCompletionId: (id: string | null) => void;
	completionId: string | null;
	generateDocuments: (
		patientRecordId: string,
		transcriptionId: string,
		documentIds: string[],
		multiplePatientCase: boolean,
		patientNameToParse: string,
		fastGenerate: boolean
	) => void;
	isLoadingCompletion: boolean;
	completion: any;
	stop: () => void;
	stopGenerate: (patientRecordId: string, documentTypeId: string) => void;
}) => {
	const dispatch = useAppDispatch();
	const documentTabs = useAppSelector(getAllDocumentTabs);
	const activeTabId = useAppSelector(getActiveTabId);
	const patientData = useAppSelector(getPatientData);
	const transcriptSections: TranscriptSection[] = useAppSelector(getTranscriptSections);

	const currentDocumentIndex = useAppSelector(getActiveDocumentIndex);
	const activeDocument = useAppSelector(getActiveDocuments)[currentDocumentIndex];
	const multiplePatients = useAppSelector(getMultiplePatients);
	const scrollRef = useScroll();
	const [showLeftGradient, setShowLeftGradient] = useState(false);
	const [showRightGradient, setShowRightGradient] = useState(true);

	// TODO move to retry button component
	// Handle the Retry Button
	const handleRetry = async (document: DocumentType, header: string) => {
		//(1) Determine if Multiple Patient Case
		let { multiplePatientCase, patientNameToParse } = determineChatHeaderAndPatientForMultiplePatientCase(
			"",
			multiplePatients,
			header,
		);

		//(4) Generate the document
		await generateDocuments(patientData.id, patientData.transcriptions.id, [activeDocument?.document_types?.id!], multiplePatientCase, patientNameToParse, false);
	};

	//Handle the Tab Clicks
	const handleTab = (tabId: string) => {
		dispatch<any>(updateActiveTabAndDocumentIndex(tabId));
	};

	const activeTab = useMemo(() => {
		return documentTabs.find((tab) => tab?.tabId === activeTabId) as DocumentTab;
	}, [activeTabId, documentTabs]);

	const tabs = useMemo(() => documentTabs.map((tab) => {
		return {
			id: tab.tabId,
			header: tab.header,
			isActive: tab.tabId === activeTabId,
		};
	}), [documentTabs, activeTabId]);

	// Create refs for each tab
	const tabRefs = useMemo(
		() => documentTabs.map(() => React.createRef<HTMLButtonElement>()),
		[documentTabs.length]
	);

	useEffect(() => {
		const activeTabIndex = documentTabs.findIndex((tab) => tab.tabId === activeTabId);
		if (activeTabIndex !== -1 && tabRefs[activeTabIndex]?.current) {
			tabRefs[activeTabIndex].current.scrollIntoView({
				behavior: 'smooth',
				inline: 'center',
				block: 'nearest',
			});
		}
	}, [activeTabId, tabRefs]);

	//Adds horizontal scrolling effect to the tabs.
	useEffect(() => {
		const slider = scrollRef.current;
		if (!slider) return;

		const checkScroll = () => {
			setShowLeftGradient(slider.scrollLeft > 0);
			setShowRightGradient(slider.scrollLeft < slider.scrollWidth - slider.clientWidth);
		};
		checkScroll();

		slider.addEventListener("scroll", checkScroll);
		return () => {
			slider.removeEventListener("scroll", checkScroll);
		};
	}, [documentTabs]);

	const isProcessingTranscriptSections = useMemo(() => {
		return transcriptSections.some(section => section.status === TranscriptSectionStatus.InProgress);
	}, [transcriptSections]);

	return (
		<div className={`bg-white border-b border-gray-200 bg-gray-50 shadow-sm ring-1 ring-gray-200 rounded-md ${documentTabs?.length === 0 && "h-full"}`}>
			{documentTabs?.length > 0 ? (
				<div className="">
					<div className={`px-6 pt-2 gradient-tabs ${showLeftGradient ? "show-left-gradient" : ""} ${showRightGradient ? "show-right-gradient" : ""}`}>
						<div
							ref={scrollRef}
							className="flex overflow-x-auto scrollbar-hover max-w-full"
						>
							<TableTabs tabs={tabs} changeTab={handleTab} tabRefs={tabRefs} />
						</div>
					</div>
					{activeTabId && (
						<Document
							key={activeTabId}
							activeTabId={activeTabId}
							tab={activeTab}
							isLoadingCompletion={isLoadingCompletion}
							completionId={completionId}
							completion={completion}
							handleRetry={handleRetry}
							stop={stop}
							setCompletionId={setCompletionId}
							stopGenerate={stopGenerate}
						/>
					)}
				</div>
			) : (
				<div className="flex flex-col justify-center items-center w-full h-full px-6 py-6">
					<FilePlusIcon className="w-12 h-12 text-gray-500" />
					<p className="mt-2 text-sm text-gray-900">No Documents</p>
					<p className="mt-1 text-sm text-gray-500">{isProcessingTranscriptSections ? "Waiting for clip to process…" : `Get started by selecting a document and then clicking "Generate"`}</p>
				</div>
			)}
		</div>
	);
};

export default DocumentTabs;
