import { Transition, Dialog, DialogPanel, DialogTitle, TransitionChild } from "@headlessui/react";
import ChevronUpIcon from "@icons/chevron-up.svg?react";
import ChevronDownIcon from "@icons/chevron-down.svg?react";
import moment from "moment";
import React, { useEffect, Fragment } from "react";
import { Doctor, PatientRecord } from "@common/utils/types";
import usePatientState from "@features/list-view/state/usePatientState";
import { useAppSelector } from "@/common/hooks/useRedux";

type EditAppointmentModalProps = {
	isOpen: boolean;
	onClose: () => void;
	patientTableRecord: PatientRecord;
};

const EditAppointmentModal: React.FC<EditAppointmentModalProps> = ({ isOpen, onClose, patientTableRecord }) => {
	const {
		patientName,
		setPatientName,
		adjustTime,
		scheduledAtDay,
		scheduledAtTime,
		appointmentDuration,
		setAppointmentDuration,
		generateTimeOptions,
		patientId,
		setPatientId,
		setScheduledAtDay,
		setScheduledAtTime,
		handleUpdatePatientRecord,
		formatDate,
		setLocalSelectedDoctor,
		localSelectedDoctor,
	} = usePatientState(patientTableRecord);

	const { doctors } = useAppSelector((state) => state.doctors);

	useEffect(() => {
		if (patientTableRecord) {
			setPatientName(patientTableRecord.patient_name as string);
			setPatientId(patientTableRecord.patient_id as string);

			// split scheduled_at into date and time
			const scheduledAt = new Date(patientTableRecord?.scheduled_at as string || "");
			setScheduledAtDay(formatDate(scheduledAt));
			setScheduledAtTime(moment(scheduledAt).format("HH:mm"));
			setAppointmentDuration(patientTableRecord?.appointment_duration as number);

			if (patientTableRecord?.doctors) {
				const d = doctors.find((doctor: Doctor) => doctor?.id === patientTableRecord?.doctors?.id) || null;
				setLocalSelectedDoctor(d);
			}
		}
	}, [patientTableRecord]);


	const handleDoctorChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const selectedId = e.target.value;
		if (selectedId === "") {
			setLocalSelectedDoctor(null);
		} else {
			const selectedDoctor =
				doctors.find((doctor: Doctor) => doctor?.id === selectedId) || null;
			setLocalSelectedDoctor(selectedDoctor);
		}
	}

	const handleSave = async () => {
		await handleUpdatePatientRecord();
		onClose();
	}

	return (
		<Transition show={isOpen} as={React.Fragment}>
			<Dialog as="div" className="relative z-50" onClose={onClose}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<div className="fixed inset-0 z-10 w-screen overflow-y-auto text-gray-900">
					<div className="flex min-h-full items-center justify-center p-4 text-center">
						<TransitionChild
							as={React.Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-sm transition-all p-4 sm:my-8 w-[80%] min-h-[450px] sm:max-w-lg">
								<div className="px-4 pt-5 pb-4 sm:p-6 sm:pb-4 flex flex-col">
									<DialogTitle as="h1" className="text-xl leading-6 font-semibold text-gray-900">
										<div className="flex flex-col w-full items-center justify-center">
											Edit Appointment
											<div className="flex text-lg items-center mt-2 font-medium text-gray-900">
												Doctor:
												<select
													className="ml-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-max-700 focus:ring focus:ring-max-700 focus:outline-none"
													value={localSelectedDoctor ? localSelectedDoctor.id : ""}
													onChange={(e) => handleDoctorChange(e)}
													tabIndex={-1}
												>
													<option value="">None</option>
													{doctors.map((doctor: Doctor) => (
														<option key={doctor.id} value={doctor.id}>
															{doctor.doctor_name}
														</option>
													))}
												</select>
											</div>
										</div>
									</DialogTitle>

									{/* Patient Name */}
									<div className="mt-3">
										<label htmlFor="patient-name" className="block text-sm font-medium text-gray-700">
											Patient Name(s):
										</label>
										<div>
											<input
												type="text"
												className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-max-700 focus:ring focus:ring-max-700 focus:outline-none"
												placeholder="Patient Name"
												value={patientName}
												onChange={(e) => setPatientName(e.target.value)}
											/>
										</div>
									</div>

									{/* Patient ID */}
									<div className="mt-3">
										<label htmlFor="patient-name" className="block text-sm font-medium text-gray-700">
											Patient ID:
										</label>
										<div>
											<input
												type="text"
												className="block w-full rounded-md border-gray-300 shadow-sm focus:border-max-700 focus:ring focus:ring-max-700 focus:outline-none"
												placeholder="Patient ID"
												value={patientId}
												onChange={(e) => setPatientId(e.target.value)}
											/>
										</div>
									</div>

									{/* Input for Record/Appointment Time */}
									<div className="mt-3">
										<label htmlFor="schedule-at" className="block text-sm font-medium">
											Scheduled Time:
										</label>

										<div className="flex flex-col sm:flex-row space-y-3 sm:space-y-0">
											{/* Appointment Date */}
											<div className="flex-1">
												<input
													type="date"
													className="block w-full rounded-md sm:rounded-none sm:rounded-l-md border-gray-300 shadow-sm focus:border-max-700 focus:ring focus:ring-max-700 focus:outline-none"
													value={scheduledAtDay}
													onChange={(e) => setScheduledAtDay(e.target.value)}
													onClick={(e) => {
														(e.target as any).showPicker();
													}}
												/>
											</div>
											{/* Appointment Time */}
											<div className="flex-1">
												<div className="flex-1 relative">
													<select
														className="bg-none block w-full border-t border-b sm:rounded-none rounded-md border-gray-300 shadow-sm focus:border-max-700 focus:ring focus:ring-max-700 focus:outline-none"
														value={scheduledAtTime}
														onChange={(e) => setScheduledAtTime(e.target.value)}
													>
														{generateTimeOptions()}
													</select>
													<div className="absolute inset-y-0 right-0 flex items-center">
														<button
															className="absolute right-0 top-0 mt-1 mr-2"
															onClick={() => adjustTime("up")}
															aria-label="Increase time"
														>
															<ChevronUpIcon className="h-5 w-5 pr-0.5 text-gray-700"></ChevronUpIcon>
														</button>
														<button
															className="absolute right-0 top-0 mt-4 mr-2"
															onClick={() => adjustTime("down")}
															aria-label="Decrease time"
														>
															<ChevronDownIcon className="h-5 w-5 pr-0.5 text-gray-700"></ChevronDownIcon>
														</button>
													</div>
												</div>
											</div>
											{/* Appointment Duration */}
											<div className="flex-1">
												<div className="relative">
													<select
														className="block w-full rounded-md sm:rounded-none sm:rounded-r-md border-gray-300 shadow-sm focus:border-max-700 focus:ring focus:ring-max-700 focus:outline-none"
														value={appointmentDuration}
														onChange={(e) => setAppointmentDuration(Number(e.target.value))}
													>
														<option value="15">15 min</option>
														<option value="30">30 min</option>
														<option value="45">45 min</option>
														<option value="60">60 min</option>
													</select>
												</div>
											</div>
										</div>
									</div>

									{/* BUTTONS */}
									<div className="mt-5 flex flex-col sm:flex-row sm:justify-end space-y-3 sm:space-y-0 sm:space-x-2 w-full">
										<div>
											<button
												type="button"
												className="secondary-button"
												onClick={onClose}
											>
												Cancel
											</button>
										</div>
										<div>
											<button
												type="button"
												className="primary-button"
												onClick={handleSave}
											>
												Save
											</button>
										</div>

									</div>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default EditAppointmentModal;
