import DatePicker from '@/common/components/DatePicker';
import { RecordingState } from '@/common/utils/types';
import React, { useState } from 'react';
import { useAppSelector } from "@/common/hooks/useRedux";
import SelectedActionButtons from './patient-table/components/buttons/SelectedActionButtons';
import SyncAppointmentsBtn from './patient-table/components/buttons/SyncAppointmentsBtn';
import AddAppointmentModal from './patient-table/components/modals/AddAppointmentModal';
import DoctorSelect from '@/common/components/DoctorSelect';

/**
 * Component for the controls in the list view
 * @returns JSX.Element
 * */
const Controls: React.FC = () => {
    const { selectedTableRecords } = useAppSelector((state) => state.patientTableRecords);
    const { globalRecordingState } = useAppSelector((state) => state.globalState);


    const [isAddAppointmentModalOpen, setIsAddAppointmentModalOpen] = useState(false);

    const isRecording = globalRecordingState === RecordingState?.recording || globalRecordingState === RecordingState?.paused

    return (
        <div className='flex flex-col items-start justify-center gap-2 flex-grow w-full'>
            <DoctorSelect disabled={isRecording} />
            <div className="flex flex-grow flex-col  gap-2 items-center justify-end w-full">
                {selectedTableRecords.length > 0 ? (
                    <SelectedActionButtons />
                ) : (
                    <div className="flex justify-around flex-grow  gap-2 items-center w-full ">

                        <AddAppointmentModal
                            open={isAddAppointmentModalOpen}
                            setOpen={setIsAddAppointmentModalOpen}
                            disabled={isRecording}
                        />
                        <SyncAppointmentsBtn
                            disabled={isRecording}
                        />


                    </div>
                )}

            </div>

            <DatePicker
                displayPosition={"right"}
                isDisabled={isRecording}
            />


        </div >
    );
};

export default Controls;