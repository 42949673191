import { PatientRecord, TranscriptSection } from "@/common/utils/types";
import FileSearchIcon from "@icons/file-search-02.svg?react";
import MicrophoneIcon from "@icons/microphone-01.svg?react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import StageSelect from "@/common/components/StageSelect";
import { AuthError } from "@supabase/supabase-js";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";

interface ActionButtonsProps {
    patient: PatientRecord;
    isDisabled: boolean;
    isRecordingError: boolean;
    isUploading: boolean;
    startRecording: (patientRecord: PatientRecord, onAudioDataAvailable: (audioFile: File, patientRecord: PatientRecord, transcriptSectionId: string) => Promise<void>) => any
    uploadAudio: (audioFile: File, audioName: string, transcriptId: string, transcriptSectionId: string, patientRecord: PatientRecord) => Promise<{
        transcriptSection: TranscriptSection | null;
        err: Error | AuthError | any;
        is409: Boolean;
    }>

}

/**
 * ActionButtons component
 * @param patient - The patient record
 * @param isDisabled - Whether the buttons are disabled
 * @param isRecordingError - Whether there is a recording error
 * @param isUploading - Whether the audio is uploading
 * @param startRecording - The function to start recording
 * @param uploadAudio - The function to upload audio
 */
const ActionButtons: React.FC<ActionButtonsProps> = ({
    patient,
    isDisabled,
    isRecordingError,
    isUploading,
    startRecording,
    uploadAudio,
}) => {

    /**
     * Uploads audio data to the server
     * @param audioFile - The audio file to upload
     * @param patientRecord - The patient record to upload the audio for
     * @param transcriptSectionId - The ID of the transcript section to upload the audio for
     */
    const onAudioDataAvailable = async (
        audioFile: File,
        patientRecord: PatientRecord,
        transcriptSectionId: string,
    ) => {
        if (!patientRecord.transcriptions?.id || !patientRecord.id) {
            Sentry.captureMessage("Transcription ID or Patient ID not found in onAudioDataAvailable - TableRow");
            return;
        }

        try {
            // upload audio
            const uploadResponse = await uploadAudio(
                audioFile,
                audioFile.name,
                patientRecord.transcriptions.id,
                transcriptSectionId,
                patientRecord,
            );
            if (uploadResponse.err) {
                throw new Error("Error while uploading audio");
            }
        } catch (error) {
            Sentry.captureException(error);
            toast.error("Error while uploading audio");
        }
    };

    const handleStartRecording = (e: React.MouseEvent) => {
        e.stopPropagation();
        startRecording(patient, onAudioDataAvailable);
    };

    return (
        <div className="flex w-full flex-col items-center justify-center">
            <div className="w-full flex items-center justify-end gap-x-4">
                {/* Start Recording Button */}
                <button
                    id="in-line-start-recording"
                    className={classNames(
                        isDisabled || isRecordingError || isUploading
                            ? 'text-gray-400 bg-max-200'
                            : 'text-white bg-max-700 hover:bg-max-800 focus-visible:outline-bg-max-700',
                        'w-12 h-12 md:w-20 h-auto inline-flex items-center justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-max-700'
                    )}
                    disabled={isDisabled || isRecordingError || isUploading}
                    onClick={handleStartRecording}
                >
                    <MicrophoneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                    <span className="hidden md:inline-block">
                        {patient.transcriptions?.transcript_sections?.length === 0
                            ? 'Start'
                            : 'Add'}{' '}
                    </span>
                    <span className="sr-only">, {patient.patient_name}</span>
                </button>

                {/* View Appointment Link */}
                <Link
                    id="in-line-view-appointment"
                    className={classNames(
                        isDisabled || isUploading
                            ? 'bg-gray-50 text-gray-400 pointer-events-none'
                            : 'bg-white text-gray-900 hover:bg-gray-50',
                        'h-12 w-12 md:w-auto h-auto inline-flex items-center justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset ring-gray-300 focus-visible:outline-max-700'
                    )}
                    to={
                        isDisabled || isUploading ? '/' : `/appointments/${patient.id}`
                    }
                    state={{ appointmentId: patient.id }}
                    onClick={(e) => e.stopPropagation()}
                >
                    <FileSearchIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                    <span className="hidden md:inline-block">View</span>
                    <span className="sr-only">, {patient.patient_name}</span>
                </Link>
            </div>

            {/* Stage Selector for Small Screens */}
            <div
                id="in-line-stage-buttons"
                className="w-full flex justify-end sm:hidden mt-3"
            >
                <StageSelect
                    currentStage={patient?.stage as string}
                    patientId={patient?.id as string}
                    disabled={isDisabled || isUploading}
                />
            </div>
        </div>
    );
};

export default ActionButtons;