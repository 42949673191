import { DisplayDocument } from "@/common/utils/types";
import FormatList from "./FormatList";
import JsonDocInput from "./Input";
import { cloneDeep } from "lodash";
import PlusCircleIcon from "@icons/plus-circle.svg?react";

interface ListProps {
    sectionKey: number;
    value: string[];
    fieldIndex: number | null;
    format: string;
    isEditing: boolean;
    editedJson: DisplayDocument | null;
    isListType: boolean;
    isNormal: boolean;
    setEditedJson: React.Dispatch<React.SetStateAction<DisplayDocument | null>>
    handleFieldChange: (sectionKey: number, fieldIndex: number | null, value: string) => void;
}

/**
 * Render the list based on the format
 * @param props - the props containing sectionKey, value, fieldIndex, and format
 * @returns JSX.Element
 */
const List: React.FC<ListProps> = ({ sectionKey, value, fieldIndex, format, isEditing, editedJson, isListType, setEditedJson, handleFieldChange }) => {

    /**
         * Handles changes to individual items within an array section or field.
         * @param {string} sectionKey - The key of the section containing the array.
         * @param {string} fieldIndex - The key of the field if the array is nested within a field.
         * @param {number} index - The index of the item within the array.
         * @param {string} newValue - The new value for the array item.
         */
    const handleArrayItemChange = (sectionKey: number, fieldIndex: number | null, index: number, newValue: string) => {
        if (editedJson) {
            setEditedJson((prev) => {
                if (prev) {
                    const section = prev[sectionKey];
                    const fields = section?.fields;
                    if (fieldIndex !== null && fields && fields[fieldIndex]) {
                        let fieldValue = fields[fieldIndex]?.value;

                        // Ensure fieldValue is an array before applying map
                        if (Array.isArray(fieldValue)) {
                            fieldValue = fieldValue?.map((item: string, i: number) =>
                                i === index ? newValue : item
                            );
                        } else {
                            // If not an array, handle the case appropriately (e.g., wrap it in an array)
                            fieldValue = [newValue];
                        }

                        const updatedField = {
                            ...fields[fieldIndex],
                            value: fieldValue,
                            isEdited: true
                        };

                        const updatedJson = [
                            ...prev?.slice(0, sectionKey),
                            {
                                ...section,
                                fields: [
                                    ...fields.slice(0, fieldIndex),
                                    updatedField,
                                    ...fields.slice(fieldIndex + 1)
                                ],
                            },
                            ...prev?.slice(sectionKey + 1),
                        ];
                        return cloneDeep(updatedJson);
                    } else {
                        const updatedJson = [
                            ...prev?.slice(0, sectionKey),
                            {
                                ...section,
                                isEdited: true,
                                value: Array.isArray(section?.value)
                                    ? section?.value?.map((item: string, i: number) =>
                                        i === index ? newValue : item
                                    )
                                    : [newValue],  // If value is not an array, convert it to an array with newValue
                            },
                            ...prev?.slice(sectionKey + 1),
                        ];
                        return cloneDeep(updatedJson);
                    }
                }
                return prev;
            });
        }
    };


    /**
     * Handles adding a new item to an array section or field.
     * @param {string} sectionKey - The key of the section containing the array.
     * @param {string} fieldIndex - The key of the field if the array is nested within a field.
     */
    const handleAddArrayItem = (sectionKey: number, fieldIndex: number | null) => {
        if (editedJson) {
            setEditedJson((prev) => {
                if (prev) {
                    const section = prev[sectionKey];
                    const fields = section?.fields;
                    if (fieldIndex !== null && fields && fields[fieldIndex]) {
                        let fieldValue = fields[fieldIndex]?.value;

                        // Ensure fieldValue is an array before adding new item
                        if (Array.isArray(fieldValue)) {
                            fieldValue = [...fieldValue, ""];
                        } else {
                            // If not an array, handle the case appropriately (e.g., start a new array)
                            fieldValue = [""];
                        }

                        const updatedField = {
                            ...fields[fieldIndex],
                            value: fieldValue,
                            isEdited: true
                        };

                        const updatedJson = [
                            ...prev?.slice(0, sectionKey),
                            {
                                ...section,
                                fields: [
                                    ...fields.slice(0, fieldIndex),
                                    updatedField,
                                    ...fields.slice(fieldIndex + 1)
                                ],
                            },
                            ...prev?.slice(sectionKey + 1),
                        ];
                        return cloneDeep(updatedJson);
                    } else {
                        const updatedJson = [
                            ...prev?.slice(0, sectionKey),
                            {
                                ...section,
                                isEdited: true,
                                value: Array.isArray(section?.value)
                                    ? [...section?.value, ""]
                                    : [""],  // If value is not an array, convert it to an array with one empty item
                            },
                            ...prev?.slice(sectionKey + 1),
                        ];
                        return cloneDeep(updatedJson);
                    }
                }
                return prev;
            });
        }
    };

    /**
   * Handles removing an item from an array section or field.
   * @param {string} sectionKey - The key of the section containing the array.
   * @param {string} fieldIndex - The key of the field if the array is nested within a field.
   * @param {number} index - The index of the item to be removed.
   */
    const handleRemoveArrayItem = (sectionKey: number, fieldIndex: number | null, index: number) => {
        if (editedJson) {
            setEditedJson((prev) => {
                if (prev) {
                    const section = prev[sectionKey];
                    const fields = section?.fields;

                    if (fieldIndex !== null && fields && fields[fieldIndex]) {
                        let fieldValue = fields[fieldIndex]?.value;

                        // Ensure fieldValue is an array before applying filter
                        if (Array.isArray(fieldValue)) {
                            fieldValue = fieldValue?.filter((_: string, i: number) => i !== index);
                        } else {
                            // If not an array, return the original value (or handle as needed)
                            fieldValue = fieldValue;  // Keep it unchanged, or handle as necessary
                        }

                        const updatedField = {
                            ...fields[fieldIndex],
                            value: fieldValue,
                            isEdited: true
                        };

                        const updatedJson = [
                            ...prev?.slice(0, sectionKey),
                            {
                                ...section,
                                fields: [
                                    ...fields.slice(0, fieldIndex),
                                    updatedField,
                                    ...fields.slice(fieldIndex + 1)
                                ],
                            },
                            ...prev?.slice(sectionKey + 1),
                        ];
                        return cloneDeep(updatedJson);
                    } else {
                        let sectionValue = prev[sectionKey]?.value;

                        // Ensure sectionValue is an array before applying filter
                        if (Array.isArray(sectionValue)) {
                            sectionValue = sectionValue?.filter((_: string, i: number) => i !== index);
                        } else {
                            // If not an array, return the original value (or handle as needed)
                            sectionValue = sectionValue;  // Keep it unchanged, or handle as necessary
                        }

                        const updatedJson = [
                            ...prev?.slice(0, sectionKey),
                            {
                                ...prev[sectionKey],
                                value: sectionValue,
                                isEdited: true
                            },
                            ...prev?.slice(sectionKey + 1),
                        ];
                        return cloneDeep(updatedJson);
                    }
                }
                return prev;
            });
        }
    };


    return (
        <>
            {isEditing && isListType ? (
                <ul className="w-full list-disc">
                    {value.map((item, index) => {
                        return (
                            <li key={index} className="w-full text-[12px] leading-5 flex flex-wrap lg:flex-nowrap gap-2 items-center lg:col-span-3 sm:col-span-2 col-span-1">
                                <>
                                    <JsonDocInput
                                        sectionKey={sectionKey}
                                        value={item}
                                        index={index}
                                        isArrayInput={true}
                                        isNormal={true}
                                        fieldIndex={fieldIndex}
                                        handleArrayItemChange={handleArrayItemChange}
                                        handleFieldChange={handleFieldChange}
                                        editedJson={editedJson}
                                        setEditedJson={setEditedJson}
                                    />
                                    <button
                                        className="focused rounded-md bg-white px-2 py-1 ml-1 mb-2 lg:mb-0 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                                        onClick={() => handleRemoveArrayItem(sectionKey, fieldIndex, index)}
                                    >
                                        Remove
                                    </button>
                                </>
                            </li>
                        );
                    })}
                </ul>

            ) : !isListType && isEditing ? (
                <JsonDocInput
                    sectionKey={sectionKey}
                    value={value.join(' ')}
                    index={fieldIndex}
                    isArrayInput={false}
                    isNormal={true}
                    fieldIndex={fieldIndex}
                    handleFieldChange={handleFieldChange}
                    setEditedJson={setEditedJson}
                    editedJson={editedJson}
                />
            ) : (
                <FormatList list={value} format={format} />
            )}

            {isEditing && isListType && (
                <button
                    onClick={() => handleAddArrayItem(sectionKey, fieldIndex)}
                    className="focused flex items-center justify-center my-2 ml-1 rounded-md bg-max-700 w-[110px] box-border  px-2 py-1 text-xs font-semibold text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700 "
                >
                    <PlusCircleIcon className="w-4 h-4 inline-block mr-1" />
                    Add Item
                </button>
            )}
        </>
    );
};

export default List;