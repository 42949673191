import { Fragment, useRef } from "react";
import { Dialog, DialogPanel, DialogTitle, Transition, TransitionChild } from "@headlessui/react";

const SyncConfirmation = ({
	open,
	setOpen,
	content,
}: {
	open: boolean;
	setOpen: (value: boolean) => void;
	content: string;
}) => {
	const cancelButtonRef = useRef(null);

	return (
		<Transition show={open} as={Fragment}>
			<Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>
				<div className="fixed inset-0 z-20 w-screen overflow-y-auto">
					<div className="flex min-h-full  justify-center p-4 text-center items-center sm:p-0">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
								<div>
									<div className="mt-3 text-center sm:mt-5">
										<DialogTitle as="h1" className="text-xl font-semibold leading-6 text-gray-900">
											Confirm Sync
										</DialogTitle>
										<div className="mt-2">
											<p className="text-[14px] text-gray-500">
												By selecting “Approve & Sync” below, you will be authorizing HappyDoc to automatically send and
												save the transcription in your Practice Management System.{" "}
											</p>
										</div>
									</div>
								</div>
								<div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
									<button
										type="button"
										className="inline-flex w-full justify-center rounded-md bg-max-700 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700  sm:col-start-2 happydoc-approveAndSyncAllButton"
										onClick={() => setOpen(false)}
										data-happydoc-id="approveAndSyncAllButton"
										data-happydoc-content={content}
									>
										Approve & Sync
									</button>
									<button
										type="button"
										className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
										onClick={() => setOpen(false)}
										ref={cancelButtonRef}
									>
										Cancel
									</button>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default SyncConfirmation;
