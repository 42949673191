import classNames from "classnames";
import { useState } from "react";
import { TranscriptSection, FeatureFlags, PatientRecord } from "@common/utils/types";
import ConfirmModal from "@common/components/ConfirmModal";
import TranscriptionModalButton from "../transcription-modal/TranscriptionModalButton";
import TranscriptionModal from "../transcription-modal/TranscriptionModal";
import { useAppSelector } from "@/common/hooks/useRedux";
import { useAccountContext } from "@providers/AccountProvider";
import { getPatientData } from "@features/patient-view/state/redux/activePatientDataSlice";
import Clip from "./Clip";

interface RecordingsProps {
	documentCreationTime: Date | null;
	sortedSections: TranscriptSection[];
}

const Recordings = ({ sortedSections, documentCreationTime }: RecordingsProps) => {
	const { containsFeatureFlag } = useAccountContext();

	const [recordingToDelete, setRecordingToDelete] = useState<any>(null);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [openTranscriptModal, setOpenTranscriptModal] = useState(false);
	const [selectedTranscriptSectionId, setSelectedTranscriptSectionId] = useState<string | null>(null);
	const patientData: PatientRecord = useAppSelector(getPatientData);

	const transcriptId = patientData?.transcriptions?.id

	return (
		<div className={classNames(containsFeatureFlag(FeatureFlags.HIDE_TRANSCRIPTIONS) ? "hidden" : "")}	>
			<div className=" border border-gray-200 shadow-sm bg-gray-50  rounded-md">
				{/* Header for list of clips */}
				<div className="flex items-center justify-between p-4 bg-gray-50 border-b border-gray-200 rounded-t-md  ">
					<h1 className="text-lg lg:text-xl  whitespace-nowrap">
						Clips
					</h1>
					{transcriptId && containsFeatureFlag(FeatureFlags.ENABLE_TRANSCRIPTS_BUTTON) && <TranscriptionModalButton setOpenTranscriptModal={setOpenTranscriptModal} />}
				</div>

				<div className="">
					{sortedSections?.length > 0 && (
						<div >
							{sortedSections.map((transcriptSection: TranscriptSection, index: any) => {
								const isLastClip = index === sortedSections.length - 1;
								return <Clip
									key={transcriptSection?.id}
									transcriptSection={transcriptSection}
									documentCreationTime={documentCreationTime}
									setOpenTranscriptModal={setOpenTranscriptModal}
									setSelectedTranscriptSectionId={setSelectedTranscriptSectionId}
									isLastClip={isLastClip}
								/>

							})}
						</div>
					)}
				</div>

			</div>
			{
				transcriptId && (
					<TranscriptionModal
						patientRecordId={patientData?.id}
						transcriptionId={transcriptId}
						transcriptSections={sortedSections}
						isOpen={openTranscriptModal}
						setIsOpen={setOpenTranscriptModal}
						selectedTranscriptSectionId={selectedTranscriptSectionId}
						setSelectedTranscriptSectionId={setSelectedTranscriptSectionId}
						documentCreationTime={documentCreationTime}
					/>
				)
			}
			<ConfirmModal
				id="DeleteRecording"
				headerText="Are you sure you want to delete this clip?"
				cancelButtonText="Cancel"
				confirmButtonText="Delete"
				confirmButtonColor="bg-red-600"
				confirmHoverButtonColor="bg-red-500"
				isOpen={isModalOpen}
				onCancel={() => {
					setIsModalOpen(false);
				}}
				onConfirm={() => {
					if (recordingToDelete?.sectionId && recordingToDelete?.transcriptId) {
						setRecordingToDelete(null);
						setIsModalOpen(false);
					}
				}}
			/>

		</div >
	);
};

export default Recordings;
