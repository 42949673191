let env: any;

if (typeof process !== "undefined") {
	env = process.env; // Node.js environment
} else {
	env = import.meta.env; // Browser environment
}

export default env;

const {
	VITE_HAPPYDOC_API_SERVER_URL,
	VITE_HAPPYDOC_TRANSCRIBE_SERVER_URL,
	VITE_PUBLIC_LOGFLARE_ID,
	VITE_PUBLIC_LOGFLARE_API_KEY,
	VITE_PUBLIC_ENV,
	VITE_PUBLIC_SUPABASE_URL,
	VITE_PUBLIC_SUPABASE_ANON_KEY,
	VITE_PUBLIC_BRAND,
	VITE_PENDO_API_KEY,
	VITE_ONBOARDING_HELP_URL,
	VITE_HAPPY_SOAP_ID,
	VITE_NOTIFICATIONS_BANNER_MESSAGE,
} = env;

export {
	VITE_HAPPYDOC_API_SERVER_URL,
	VITE_HAPPYDOC_TRANSCRIBE_SERVER_URL,
	VITE_PUBLIC_LOGFLARE_ID,
	VITE_PUBLIC_LOGFLARE_API_KEY,
	VITE_PUBLIC_ENV,
	VITE_PUBLIC_SUPABASE_URL,
	VITE_PUBLIC_SUPABASE_ANON_KEY,
	VITE_PUBLIC_BRAND,
	VITE_PENDO_API_KEY,
	VITE_ONBOARDING_HELP_URL,
	VITE_HAPPY_SOAP_ID,
	VITE_NOTIFICATIONS_BANNER_MESSAGE,
};

export const HD_CHROME_EXTENSION_ID = "cipkdomngkmgmfekckaokaidmnikjdco";

export const HAPPY_SOAP = '4383c4ae-9215-49c2-b984-5d1436c0b3a7';

export const US_STATES = [
	'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California', 'Colorado', 'Connecticut', 'Delaware',
	'Florida', 'Georgia', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky',
	'Louisiana', 'Maine', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi',
	'Missouri', 'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico',
	'New York', 'North Carolina', 'North Dakota', 'Ohio', 'Oklahoma', 'Oregon', 'Pennsylvania',
	'Rhode Island', 'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
	'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
];


export const COUNTRIES: string[] = [
	// north america
	'United States',
	'Canada',
	'Mexico',
	'United Kingdom',
	// EU
	'Germany',
	'France',
	'Italy',
	'Spain',
	'Netherlands',
	'Belgium',
	'Sweden',
	'Norway',
	'Denmark',
	'Finland',
	'Austria',
	'Switzerland',
	'Ireland',
	'Portugal',
	'Greece',
	'Czech Republic',
	'Poland',
	// ... all other countries
	'Afghanistan',
	'Albania',
	'Algeria',
	'Andorra',
	'Angola',
	'Antigua and Barbuda',
	'Argentina',
	'Armenia',
	'Australia',
	'Azerbaijan',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belarus',
	'Belize',
	'Benin',
	'Bhutan',
	'Bolivia',
	'Bosnia and Herzegovina',
	'Botswana',
	'Brazil',
	'Brunei',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Cabo Verde',
	'Cambodia',
	'Cameroon',
	'Central African Republic',
	'Chad',
	'Chile',
	'China',
	'Colombia',
	'Comoros',
	'Congo, Democratic Republic of the',
	'Congo, Republic of the',
	'Costa Rica',
	'Croatia',
	'Cuba',
	'Cyprus',
	'Djibouti',
	'Dominica',
	'Dominican Republic',
	'East Timor (Timor-Leste)',
	'Ecuador',
	'Egypt',
	'El Salvador',
	'Equatorial Guinea',
	'Eritrea',
	'Estonia',
	'Eswatini',
	'Ethiopia',
	'Fiji',
	'Gabon',
	'Gambia',
	'Georgia',
	'Ghana',
	'Grenada',
	'Guatemala',
	'Guinea',
	'Guinea-Bissau',
	'Guyana',
	'Haiti',
	'Honduras',
	'Hungary',
	'Iceland',
	'India',
	'Indonesia',
	'Iran',
	'Iraq',
	'Israel',
	'Jamaica',
	'Japan',
	'Jordan',
	'Kazakhstan',
	'Kenya',
	'Kiribati',
	'Korea, North',
	'Korea, South',
	'Kosovo',
	'Kuwait',
	'Kyrgyzstan',
	'Laos',
	'Latvia',
	'Lebanon',
	'Lesotho',
	'Liberia',
	'Libya',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Madagascar',
	'Malawi',
	'Malaysia',
	'Maldives',
	'Mali',
	'Malta',
	'Marshall Islands',
	'Mauritania',
	'Mauritius',
	'Micronesia',
	'Moldova',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Morocco',
	'Mozambique',
	'Myanmar (Burma)',
	'Namibia',
	'Nauru',
	'Nepal',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'North Macedonia',
	'Oman',
	'Pakistan',
	'Palau',
	'Panama',
	'Papua New Guinea',
	'Paraguay',
	'Peru',
	'Philippines',
	'Qatar',
	'Romania',
	'Russia',
	'Rwanda',
	'Saint Kitts and Nevis',
	'Saint Lucia',
	'Saint Vincent and the Grenadines',
	'Samoa',
	'San Marino',
	'Sao Tome and Principe',
	'Saudi Arabia',
	'Senegal',
	'Serbia',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'Slovakia',
	'Slovenia',
	'Solomon Islands',
	'Somalia',
	'South Africa',
	'South Sudan',
	'Sri Lanka',
	'Sudan',
	'Suriname',
	'Syria',
	'Taiwan',
	'Tajikistan',
	'Tanzania',
	'Thailand',
	'Togo',
	'Tonga',
	'Trinidad and Tobago',
	'Tunisia',
	'Turkey',
	'Turkmenistan',
	'Tuvalu',
	'Uganda',
	'Ukraine',
	'United Arab Emirates',
	'Uruguay',
	'Uzbekistan',
	'Vanuatu',
	'Vatican City',
	'Venezuela',
	'Vietnam',
	'Yemen',
	'Zambia',
	'Zimbabwe'
];