import { Disclosure, DisclosurePanel } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment, useEffect, useState } from 'react';
import EditIcon from "@icons/edit-05.svg?react";
import { useAppDispatch, useAppSelector } from '@common/hooks/useRedux';
import { getPatientData, getPatientRecordFetchError, getPatientRecordFetchStatus, updatePatientData } from '../../state/redux/activePatientDataSlice';
import Spinner from '@/common/components/Spinner';
import MinimizeIcon from "@icons/minimize-01.svg?react";
import MaximizeIcon from "@icons/maximize-01.svg?react";
import moment from 'moment';
import { updatePatientRecord } from '@/state/redux/patientTableRecordsSlice';
import DoctorPicker from '@/common/components/HDDoctorPicker';
import { isSameDay } from '@/common/utils/helpers';
import { useDataContextApi } from '@/state/providers/DataProvider';
import { useRealtimeContextApi } from '@/state/providers/RealtimeProvider';
import { BroadcastEvents } from '@/common/utils/types';

const Visit: React.FC = () => {
    const dispatch = useAppDispatch();
    const fetchStatus = useAppSelector(getPatientRecordFetchStatus);
    const errorStatus = useAppSelector(getPatientRecordFetchError);
    const patientData = useAppSelector(getPatientData);
    const { doctors } = useAppSelector((state) => state.doctors);
    const { selectedDate } = useDataContextApi();
    const { emitEvent } = useRealtimeContextApi();

    const [isEditing, setIsEditing] = useState(false);
    const [editPatientData, setEditPatientData] = useState(patientData);

    useEffect(() => {
        setEditPatientData(patientData); // Update local state when Redux state changes
    }, [patientData]);

    const handleCancel = () => {
        setIsEditing(false);
        setEditPatientData(patientData); // Revert changes on cancel
    };

    const handleEdit = () => {
        setIsEditing(!isEditing);
    };

    /**
     * Handle save button click
     * Update the patient data in the Redux store and Supabase
     */
    const handleSave = async () => {
        if (!editPatientData || !editPatientData.id) {
            return;
        }

        // Determine if the patient table record needs to be updated based on certain conditions
        const updateTable =
            isSameDay(new Date(editPatientData?.scheduled_at || ""), selectedDate.startDate)

        // Dispatch the action to update the patient data in the Redux store, will update in supabase as well.
        // This will emit a broadcast event to update the patient data in all clients
        await dispatch(
            updatePatientRecord({
                updatedTableRecord: { ...editPatientData },
                updateTable
            }),
        );

        // emit the patient event
        await emitEvent(BroadcastEvents.patientEvent, { patientRecordId: editPatientData.id });

        dispatch(updatePatientData({ ...editPatientData }));

        setIsEditing(false);
    };

    const formatDateAndTime = (d: string) => {
        return moment(d).local().format("M/D/YYYY, h:mm A");
    };


    if (!patientData && fetchStatus === "loading") {
        return (
            <div className="border-b border-gray-200 bg-white p-4  shadow-sm ring-1 ring-gray-200 rounded-md flex justify-center">
                <Spinner size="small" />
            </div>
        );
    }

    if (errorStatus) {
        return (
            <div className="border-b border-gray-200 bg-white p-4  shadow-sm ring-1 ring-gray-200 rounded-md flex justify-center">
                <p className="text-red-500">Error fetching patient data</p>
            </div>
        )
    }

    const contact = patientData?.contacts || { first_name: "", last_name: "" };

    return (
        <Disclosure defaultOpen={true} as={Fragment}>
            {({ open }) => (
                <div className="h-full border-b border-gray-200 bg-white shadow-sm ring-1 ring-gray-200  rounded-md">
                    <div className="flex justify-between items-center  p-4">
                        <div className="w-full h-full flex items-start gap-2 justify-between">
                            {!isEditing ? (
                                <div className='flex gap-2 items-start '>
                                    <h1 className="text-2xl xl:text-2xl text-gray-900 font-semibold">
                                        {patientData?.patient_name || "(blank)"}
                                    </h1>

                                </div>
                            ) : (
                                <div className="flex gap-2 items-center flex-wrap" >

                                    <div className="w-full">
                                        <label htmlFor="patient-name" className="pl-1  text-sm font-normal  text-gray-900">
                                            Patient Name
                                        </label>
                                        <div className="mt-1">
                                            <input
                                                type="text"
                                                name="patient-name"
                                                id="patient-name"
                                                value={editPatientData?.patient_name || ""}
                                                onChange={(e) => setEditPatientData({ ...editPatientData, patient_name: e.target.value, id: patientData?.id || "" })}
                                                className=" w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
                                            />
                                        </div>
                                    </div>
                                    <div className='w-full'>
                                        <label htmlFor="vet-picker" className="pl-1  text-sm font-normal  text-gray-900">
                                            Doctor
                                        </label>
                                        <DoctorPicker
                                            key={"vet-picker"}
                                            doctors={doctors}
                                            editedData={editPatientData}
                                            setEditedData={setEditPatientData}
                                        />
                                    </div>

                                    <div className="flex  w-full justify-end items-center h-full mt-2 gap-2  ">
                                        <div>
                                            <button
                                                type="button"
                                                className='secondary-button'
                                                onClick={handleCancel}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                type="button"
                                                className='primary-button'
                                                onClick={() => {
                                                    handleSave();
                                                }}
                                            >
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='flex items-center gap-5'>
                                <button
                                    type="button"
                                    className="  px-1 py-1  text-gray-600 hover:text-gray-900 hover:bg-gray-100 rounded-md focused"
                                    onClick={handleEdit}
                                >
                                    <EditIcon className="h-4 w-4" />
                                </button>
                                {!isEditing && <Disclosure.Button className="xl:hidden flex items-center justify-center text-gray-700 text-xs font-semibold hover:text-gray-900 bg-white rounded-md border border-gray-300 hover:bg-gray-100 gap-2 px-2 py-1">
                                    {open ? <MinimizeIcon className="h-4 w-4" /> : <MaximizeIcon className="h-4 w-4" />}
                                    {open ? 'Minimize' : 'Expand'}
                                </Disclosure.Button>}
                            </div>

                        </div>
                    </div>
                    <DisclosurePanel>
                        {isEditing ? (
                            <div className="flex flex-col justify-between items-center flex-wrap  p-4 ">
                                { /* nothing to show here for now */}
                            </div>
                        ) : (
                            <div className="flex flex-col gap-4 justify-center items-start">
                                <div className="flex gap-2  px-4">
                                    <label htmlFor="patient-name" className=" text-sm font-normal  text-gray-900">
                                        Contact:
                                    </label>
                                    <div
                                        className={classNames(
                                            "break-words",
                                            patientData ? "text-sm text-gray-900 font-semibold" : "text-gray-400 text-sm",
                                        )}
                                    >
                                        {contact.first_name}{" "}{contact.last_name}
                                    </div>
                                </div>
                                <div className="flex gap-2 px-4">
                                    <label htmlFor="patient-name" className=" text-sm font-normal  text-gray-900">
                                        Doctor:
                                    </label>
                                    <div
                                        className={classNames(
                                            "break-words",
                                            patientData ? "text-sm text-gray-900 font-semibold" : "text-gray-400 text-sm",
                                        )}
                                    >
                                        {patientData?.doctors?.doctor_name}
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2 px-4 py-3 bg-max-50 w-full border-y border-gray-200 rounded-b-md xl:rounded-none '>
                                    <p className='text-sm font-semibold'>Visit</p>
                                    <div className="text-sm text-gray-900 font-normal">{formatDateAndTime(patientData?.scheduled_at || "")}</div>
                                </div>
                            </div>
                        )}
                    </DisclosurePanel>
                </div >
            )}
        </Disclosure >
    );
};

export default Visit;