import { TranscriptSection, TranscriptSectionStatus } from "@common/utils/types";
import UploadProgressBar from "@common/components/UploadProgressBar";
import { useEffect, useState } from "react";
import RetryTranscriptSection from "@/common/components/RetryTranscriptSection";
import { useAppSelector } from "@/common/hooks/useRedux";

interface Props {
	isUploading: boolean;
	uploadProgress: number;
	patient: any;
	isDisabled: () => boolean;
}

/**
 * PatientNameCol component
 * @param {Props} props - The props for the component
 * @returns {React.ReactNode} - The PatientNameCol component
 */
function PatientNameCol({
	isUploading,
	uploadProgress,
	patient,
	isDisabled,
}: Props) {
	const { selectedTableRecords } = useAppSelector(
		(state) => state.patientTableRecords
	);
	const [erroredTranscriptSection, setErroredTranscriptSection] = useState<TranscriptSection | null>(null);

	useEffect(() => {
		if (patient.transcriptions?.transcript_sections) {
			const erroredSection = patient.transcriptions?.transcript_sections.find(
				(section: TranscriptSection) => section?.status === TranscriptSectionStatus.FailedIncompleteUpload || section?.status === TranscriptSectionStatus.FailedErrorProcessing
			);

			setErroredTranscriptSection(erroredSection || null);
		}

		return () => setErroredTranscriptSection(null)

	}, [patient]);

	return (
		<div className="flex flex-col gap-y-0.5 items-start w-full">
			{isUploading ? (
				<div className="flex-grow w-full">
					<UploadProgressBar percentage={uploadProgress} />
				</div>
			) : (
				patient?.transcriptions?.transcript_sections && (
					<div className="flex-grow">
						<p className="inline whitespace-nowrap text-xs lg:text-sm mr-1 w-26">
							<b className="mr-1 inline-block text-right">{`${patient.transcriptions?.transcript_sections?.length || 0
								}`}</b>
							Recording(s)
						</p>

						{patient.chat_completions && (
							<div className="flex-grow items-center">
								<p className="whitespace-nowrap inline text-xs lg:text-sm mr-1 w-26">
									<b className="mr-1 inline-block text-right">
										{patient.chat_completions[0]?.count !== undefined
											? patient.chat_completions[0]?.count
											: patient.chat_completions?.length}
									</b>
									Document(s)
								</p>
							</div>
						)}
						{erroredTranscriptSection && <RetryTranscriptSection
							disabled={isDisabled() || selectedTableRecords.length > 0}
							patientRecord={patient}
							transcriptSection={erroredTranscriptSection}
						>
							<span className="text-red-600 text-xs leading-6 font-medium underline">Retry</span>
						</RetryTranscriptSection>
						}
					</div>
				)
			)}
		</div>
	);
}

export default PatientNameCol;
