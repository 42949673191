import ArrowDownIcon from "@icons/arrow-down.svg?react";
import ArrowUpIcon from "@icons/arrow-up.svg?react";

const TableHeaderBtn = ({ text, columnName, sortedColumn, handleClick }: any) => {
	return (
		<button className="group inline-flex whitespace-nowrap items-center focus:ring-2 focus:ring-max-700 focus:outline focus:outline-none " onClick={() => handleClick(columnName)}>
			{text}
			{sortedColumn.column === columnName ? (
				<span id="column-header" className="ml-1 flex-none rounded bg-gray-50 text-gray-600 group-hover:bg-gray-200">
					{sortedColumn.direction === "asc" ? (
						<ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
					) : (
						<ArrowUpIcon className="h-5 w-5" aria-hidden="true" />
					)}
				</span>
			) : (
				<span
					id="column-header"
					className=" ml-1 invisible flex-none rounded text-gray-400 group-hover:visible group-focus:visible"
				>
					<ArrowDownIcon className="h-5 w-5" aria-hidden="true" />
				</span>
			)}
		</button>
	);
};
export default TableHeaderBtn;
