import { RecordingState, PatientRecord, TranscriptSection, TranscriptSectionStatus } from "@common/utils/types";
import StageSelect from "@/common/components/StageSelect";
import classNames from "classnames";
import PatientNameCol from "./PatientNameCol";
import { useCallback } from "react";
import { useAudioRecordingProvider } from "@features/list-view/state/recordingProvider";
import { useAppSelector, useAppDispatch } from "@/common/hooks/useRedux";
import { addSelectedTableRecord, removeSelectedTableRecord } from "@redux/patientTableRecordsSlice";
import PatientInfo from "./PatientInfo";
import ActionButtons from "./ActionButtons";

interface NotRecordingProps {
	patient: PatientRecord;
	index: number;
	handleEditButtonClick: (patient: any) => void;
	activeRecordingId: string | null;
	uploadProgress: number;
	isUploading: boolean;
	uploadAudio: (audioFile: File, audioName: string, transcriptId: string, transcriptSectionId: string, patientRecord: PatientRecord) => Promise<{
		transcriptSection: TranscriptSection | null;
		err: Error | any;
		is409: Boolean;
	}>
}

const NotRecordingRow: React.FC<NotRecordingProps> = ({
	patient,
	index,
	handleEditButtonClick,
	activeRecordingId,
	uploadProgress,
	isUploading,
	uploadAudio
}) => {
	const dispatch = useAppDispatch();
	const { selectedTableRecords } = useAppSelector(
		(state) => state.patientTableRecords
	);

	const {
		recorderControls: {
			startRecording,
			recordingState,
			isQuickStart,
		},
	} = useAudioRecordingProvider();

	// Helper function to check for errors
	const hasError = (status: TranscriptSectionStatus) =>
		patient.transcriptions?.transcript_sections?.some(
			(section) => section.status === status
		) || false;

	// Determine if the row should be disabled
	const isDisabled = useCallback(() => {
		if (
			((recordingState === RecordingState.recording ||
				recordingState === RecordingState.paused) &&
				patient.id !== activeRecordingId) ||
			isQuickStart
		) {
			return true;
		}
		return false;
	}, [activeRecordingId, isQuickStart, recordingState, patient.id]);

	// Handle selection change for the checkbox
	const handleSelectionChange = (
		patient: PatientRecord,
		isChecked: boolean
	) => {
		if (isChecked) {
			dispatch(addSelectedTableRecord(patient));
		} else {
			dispatch(removeSelectedTableRecord({ id: patient.id }));
		}
	};

	// Handle recording errors
	const isRecordingError = (state: RecordingState) =>
		[
			RecordingState.error,
			RecordingState.no_device,
			RecordingState.no_permission,
		].includes(state);

	// Main render
	return (
		<tr
			key={`${patient.id}-${index}`}
			className={classNames(
				patient.id !== activeRecordingId &&
					!isQuickStart &&
					(recordingState === RecordingState.recording ||
						recordingState === RecordingState.paused)
					? 'opacity-80'
					: '',
				hasError(TranscriptSectionStatus.FailedIncompleteUpload) || hasError(TranscriptSectionStatus.FailedErrorProcessing)
					? 'bg-red-50'
					: 'bg-gray-50',
				index === selectedTableRecords.length - 1 ? 'border border-gray-200 rounded-b-md' : '',

			)}
		>
			{/* Checkbox Column */}
			<td className="py-4 md:pl-4 pl-2 text-center">
				<input
					disabled={isDisabled() || isUploading}
					type="checkbox"
					className="form-checkbox h-5 w-5  text-max-700 border-gray-200 rounded focus:ring-max-700"
					checked={selectedTableRecords.some(
						(appt: PatientRecord) => appt.id === patient.id
					)}
					onChange={(e) => handleSelectionChange(patient, e.target.checked)}
				/>
			</td>

			{/* Patient Information */}
			<td
				className={classNames(
					isDisabled() ? 'text-gray-400' : 'text-gray-900',
					'py-4  px-3 md:pr-5 md:pl-2 text-sm font-medium'
				)}
			>
				<div>
					<PatientInfo
						patient={patient}
						isDisabled={isDisabled()}
						handleEditButtonClick={handleEditButtonClick}
					/>
					<dl className="mt-2 lg:hidden font-normal">
						<dd
							className={classNames(
								isDisabled() ? "text-gray-400" : "text-gray-600",
								"mt-2 truncate text-sm sm:hidden",
							)}
						>
							{new Date(patient?.created_at as string).toLocaleTimeString("en-US", {
								hour: "numeric",
								minute: "numeric",
								hour12: true,
							})}
						</dd>
						<div>
							<PatientNameCol
								isUploading={isUploading}
								uploadProgress={uploadProgress}
								patient={patient}
								isDisabled={isDisabled}
							/>

							<dt className="sr-only sm:hidden">Time</dt>
						</div>
					</dl>
				</div>

			</td>

			{/* Appointment Time */}
			<td
				className={classNames(
					isDisabled() ? 'text-gray-400' : 'text-gray-500',
					'hidden px-3 md:px-5 py-4  font-medium text-sm sm:table-cell'
				)}
			>
				{new Date(patient?.scheduled_at || "").toLocaleTimeString('en-US', {
					hour: 'numeric',
					minute: 'numeric',
					hour12: true,
				})}
			</td>

			{/* Doctor's Name */}
			<td
				className={classNames(
					isDisabled() ? 'text-gray-400' : 'text-gray-500',
					'hidden max-w-[120px] px-5 py-4 font-medium text-sm lg:table-cell'
				)}
			>
				{patient.doctors?.doctor_name || 'None'}
			</td>

			{/* Patient Status */}
			<td
				className={classNames(
					isDisabled() ? 'text-gray-400' : 'text-gray-500',
					'hidden lg:w-auto lg:table-cell font-medium py-2 text-xs px-3 md:px-5 lg:py-4 lg:text-sm'
				)}
			>
				<PatientNameCol
					isUploading={isUploading}
					uploadProgress={uploadProgress}
					patient={patient}
					isDisabled={isDisabled}
				/>
			</td>

			{/* Stage Selector */}
			<td
				className={classNames(
					isDisabled() ? 'text-gray-400' : 'text-gray-900',
					'hidden sm:table-cell px-3 md:px-5 py-4 text-sm whitespace-nowrap items-center'
				)}
			>
				<StageSelect
					currentStage={patient?.stage as string}
					patientId={patient?.id as string}
					disabled={isDisabled() || isUploading}
				/>
			</td>

			{/* Action Buttons */}
			<td
				className="py-4 w-1/2 sm:w-auto sm:pl-0 px-3 md:px-5 text-sm font-medium table-cell"
				colSpan={1}
			>
				<ActionButtons
					patient={patient}
					isDisabled={isDisabled()}
					isRecordingError={isRecordingError(recordingState)}
					isUploading={isUploading}
					startRecording={startRecording}
					uploadAudio={uploadAudio}
				/>
			</td>
		</tr>
	);
};

export default NotRecordingRow;
