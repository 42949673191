import React, { useState, useRef, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import AudioDb, { INDEX_DB_VERSION } from '@/common/classes/audioDb';
import { downloadAudioFileFromSupabase } from '@/common/lib/supabaseClient';
import { TranscriptSection } from '@/common/utils/types';
import PlayCircleIcon from "@icons/play-circle.svg?react";
import PauseCircleIcon from "@icons/pause-circle.svg?react";
import Spinner from '@/common/components/Spinner';
import VolumeIcon from "@icons/volume-max.svg?react";
import classNames from 'classnames';
import { determineErrorState } from '@/common/utils/helpers';

interface AudioPlayerProps {
    transcriptSection: TranscriptSection;
    documentCreationTime: Date | null;  // The creation time to set warning state
}

const AudioPlayer: React.FC<AudioPlayerProps> = ({ transcriptSection, documentCreationTime }) => {
    const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);
    const errorState = determineErrorState(transcriptSection, documentCreationTime);


    /**
     * Sets dynamic colors based on the error or warning state.
     */
    let backgroundColor = 'bg-gray-100';
    let playerBackgroundColor = '#98A2B3';
    let seekColor = "#667085"
    let iconColor = 'text-gray-600';
    let borderColor = 'border-gray-100';

    if (errorState === 'error') {
        backgroundColor = 'bg-red-100';
        playerBackgroundColor = '#FDA29B';
        seekColor = "#D92D20"
        iconColor = 'text-red-600';
        borderColor = 'border-red-100';
    } else if (errorState === 'warning') {
        backgroundColor = 'bg-yellow-100';
        playerBackgroundColor = '#FEC84B';
        seekColor = "#DC6803"
        iconColor = 'text-yellow-600';
        borderColor = 'border-yellow-100';
    }

    const audioRef = useRef<HTMLAudioElement>(new Audio());
    const [isLoading, setIsLoading] = useState(false);
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioLoaded, setAudioLoaded] = useState(false);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(transcriptSection?.audio_duration || 0);
    const [volume, setVolume] = useState<number>(1);
    const [isMuted, setIsMuted] = useState<boolean>(false);
    const [showVolumeControl, setShowVolumeControl] = useState<boolean>(false);

    /**
     * Loads audio from Supabase storage or local storage.
     */
    const loadAudio = async () => {
        setIsLoading(true);
        try {
            let blob = await downloadAudioFileFromSupabase(
                transcriptSection.transcription_id,
                transcriptSection.unprocessed_filename,
            );

            if (!blob && localAudioDb && transcriptSection.transcription_id) {
                const localAudio = await localAudioDb.getTranscriptSectionAudio(
                    transcriptSection.transcription_id,
                    transcriptSection.id,
                );
                if (localAudio) blob = localAudio.audioData;

                if (!blob) {
                    throw new Error('Audio not found in local db');
                }
            }

            const url = URL.createObjectURL(blob!);
            audioRef.current.src = url;
            audioRef.current.load();

            audioRef.current.addEventListener('loadedmetadata', () => {
                setAudioLoaded(true);
                setIsLoading(false);
            });

            audioRef.current.addEventListener('timeupdate', () => {
                setCurrentTime(audioRef.current.currentTime);
            });

            audioRef.current.addEventListener('ended', () => {
                setIsPlaying(false);
            });
        } catch (e) {
            Sentry.captureException(e);
            toast.error(
                'This audio is not in our system or on this computer. Try playing it on the computer you recorded it on.',
            );
            setIsLoading(false);
        }
    };

    useEffect(() => {
        return () => {
            audioRef.current.pause();
            audioRef.current.src = '';
        };
    }, []);

    /**
     * Handles play/pause functionality.
     */
    const togglePlayPause = () => {
        if (!audioLoaded) {
            loadAudio().then(() => {
                audioRef.current.play().catch((e) => {
                    Sentry.captureException(e);
                    toast.error('Error playing audio. If audio was recorded on safari try playing audio on safari');
                });
                setIsPlaying(true);
            });
        } else if (isPlaying) {
            audioRef.current.pause();
            setIsPlaying(false);
        } else {
            audioRef.current.play().catch((e) => {
                Sentry.captureException(e);
                toast.error('Error playing audio. If audio was recorded on safari try playing audio on safari');
            });
            setIsPlaying(true);
        }
    };

    /**
     * Handles seeking through the audio.
     */
    const handleSeek = (e: React.ChangeEvent<HTMLInputElement>) => {
        const time = parseFloat(e.target.value);
        audioRef.current.currentTime = time;
        setCurrentTime(time);
    };

    /**
     * Handles volume change.
     */
    const handleVolumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const vol = parseFloat(e.target.value);
        audioRef.current.volume = vol;
        setVolume(vol);
        setIsMuted(vol === 0);
    };

    /**
     * Handles mute/unmute.
     */
    const toggleMute = () => {
        if (isMuted) {
            audioRef.current.volume = volume;
            setIsMuted(false);
        } else {
            audioRef.current.volume = 0;
            setIsMuted(true);
        }
    };

    /**
     * Handles keyboard shortcuts when the component is focused.
     */
    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.code === 'Space') {
            e.preventDefault();
            togglePlayPause();
        } else if (e.code === 'ArrowRight') {
            audioRef.current.currentTime = Math.min(
                audioRef.current.currentTime + 5,
                audioRef.current.duration,
            );
        } else if (e.code === 'ArrowLeft') {
            audioRef.current.currentTime = Math.max(audioRef.current.currentTime - 5, 0);
        } else if (e.code === 'ArrowUp') {
            const newVolume = Math.min(volume + 0.1, 1);
            audioRef.current.volume = newVolume;
            setVolume(newVolume);
            setIsMuted(newVolume === 0);
        } else if (e.code === 'ArrowDown') {
            const newVolume = Math.max(volume - 0.1, 0);
            audioRef.current.volume = newVolume;
            setVolume(newVolume);
            setIsMuted(newVolume === 0);
        } else if (e.code === 'KeyM') {
            toggleMute();
        }
    };

    /**
     * Formats time in mm:ss format.
     */
    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60)
            .toString()
            .padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    return (
        <div className='w-full'>
            <div
                className={classNames(`focused w-full flex items-center py-2 px-3 gap-2 border rounded-md w-full  `, backgroundColor, borderColor)}
                tabIndex={0}
                onKeyDown={handleKeyDown}
            >
                {/* Play/Pause Button */}
                <button onClick={togglePlayPause} className={`focused ${iconColor}`}>
                    {isLoading ? (
                        <Spinner size='xsmall' color={iconColor} />
                    ) : isPlaying ? (
                        <PauseCircleIcon className="h-5 w-5" />
                    ) : (
                        <PlayCircleIcon className="h-5 w-5" />
                    )}
                </button>

                {/* Current Time / Duration */}
                <div className="text-xs text-gray-600 font-semibold ">
                    {formatTime(currentTime)} / {formatTime(duration)}
                </div>

                {/* Seek Bar */}
                <input
                    type="range"
                    min="0"
                    max={duration}
                    value={currentTime}
                    onChange={handleSeek}
                    style={{

                        width: '100%',
                        // @ts-ignore
                        '--track-color': playerBackgroundColor,
                        '--thumb-color': seekColor,
                    }}
                    className={`focused flex-1 mx-2 w-full audio-player-input`}
                />

                {/* Volume Control */}
                <button onClick={() => setShowVolumeControl(!showVolumeControl)} className={`focused ${iconColor}`}>
                    <VolumeIcon className="h-5 w-5" />
                </button>
            </div>
            {showVolumeControl && (
                <div className="">

                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={isMuted ? 0 : volume}
                        onChange={handleVolumeChange}
                        style={{
                            // @ts-ignore
                            '--track-color': playerBackgroundColor,
                            '--thumb-color': seekColor,
                        }}
                        className={`focused audio-player-input h-full `}
                    />
                </div>
            )}
        </div>

    );
};

export default AudioPlayer;
