import { useState, useCallback, useEffect } from "react";
import { toast } from "react-toastify";
import { getAppointments } from "@common/lib/api";
import { useDataContextApi } from "@providers/DataProvider";
import { useAccountContext } from "@providers/AccountProvider";
import { useAppDispatch } from "@/common/hooks/useRedux";
import { setStatus } from "@redux/patientTableRecordsSlice";
import Spinner from "@common/components/Spinner";
import ArrowPathIcon from "@icons/refresh-cw-03.svg?react"
import CheckIcon from "@icons/check-circle.svg?react";
import * as Sentry from "@sentry/react";

const SyncAppointmentsBtn = ({ disabled }: { disabled: boolean }) => {
	const { isLoadingData, setIsLoadingData, selectedDate, refreshPatientRecords } = useDataContextApi();
	const { pmsIntegrationData } = useAccountContext();
	const dispatch = useAppDispatch();

	const [isDisabled, setIsDisabled] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showCheckIcon, setShowCheckIcon] = useState(false);

	const getIntegratedAppointments = useCallback(async () => {

		if (!pmsIntegrationData || pmsIntegrationData?.length === 0) {
			toast.error("Uh oh, no integration found");
			return;
		}

		setLoading(true);
		dispatch(setStatus("loading"));
		let queryStartDate = selectedDate?.startDate;

		//for every integration, get appointments
		const { data, error } = await getAppointments(
			queryStartDate,
			[]
		);

		if (error) {
			Sentry.captureException(error);
			toast.error("Uh oh, something went wrong syncing appointments");
			dispatch(setStatus("failed"));
			setLoading(false);
			return;
		}

		if (!data || data.length === 0) {
			toast.success("No new appointments to sync");
			dispatch(setStatus("succeeded"));
			setLoading(false);
			return;
		}
		toast.success("Appointments Synced!");
		dispatch(setStatus("succeeded"));
		setLoading(false);
		setShowCheckIcon(true);
		refreshPatientRecords();
	}, [selectedDate, setIsLoadingData, pmsIntegrationData]);

	useEffect(() => {
		if (!pmsIntegrationData ||
			pmsIntegrationData.length === 0 ||
			!pmsIntegrationData.some(record => record.is_active) ||
			isLoadingData) {
			setIsDisabled(true);
		} else {
			setIsDisabled(false);
		}
	}, [pmsIntegrationData, isLoadingData]);

	useEffect(() => {
		let timeoutId: NodeJS.Timeout;
		if (showCheckIcon) {
			timeoutId = setTimeout(() => {
				setShowCheckIcon(false);
			}, 5000);
		}

		return () => {
			clearTimeout(timeoutId);
		};
	}, [showCheckIcon]);

	return (
		<div className="flex flex-grow w-full  ">
			<button
				type="button"
				className={'secondary-button'}
				onClick={() => {
					if (!isDisabled && !disabled) {
						getIntegratedAppointments();
					}
				}}
				disabled={isDisabled || disabled}
			>
				<div className="flex flex-row items-center justify-center whitespace-nowrap">
					<span className=" flex items-center justify-center mr-1">
						{loading ? (
							<Spinner size="xsmall" />
						) : showCheckIcon ? (
							<CheckIcon className="h-5 w-5 inline-block transition-opacity duration-500" aria-hidden="true" />
						) : (
							<ArrowPathIcon className="h-4 w-4 mr-1" />
						)}
					</span>

					<span className="text-sm text-gray-700 font-semibold">Sync</span>
				</div>
			</button>
		</div>
	);
};
export default SyncAppointmentsBtn;
