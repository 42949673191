import React from 'react'
import { Tooltip } from 'react-tooltip'
import { toolTipStyles } from '@/theme';

interface Props {
    onClick: () => void;
    toolTipContent: string;
    toolTipId: string;
}


function GenericControl({ onClick, toolTipContent, toolTipId, children }: React.PropsWithChildren<Props>) {
    return (
        <>
            <Tooltip
                content={toolTipContent}
                anchorSelect={`#${toolTipId}`}
                style={toolTipStyles}
            />
            <button
                id={toolTipId}
                type="button"
                className="p-1 hover:bg-gray-100 hover:text-gray-700 hover:rounded-sm focused"
                onClick={onClick}
            >
                {children}
            </button >
        </>
    )
}

export default GenericControl