import { Menu, Transition, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/16/solid";
import classNames from "classnames";
import { Fragment } from "react";
import { Doctor } from "../utils/types";

interface Props {
    doctors: Doctor[];
    editedData: any;
    setEditedData: any;
    disabled?: boolean;
}

const DoctorPicker = ({ doctors, editedData, setEditedData, disabled = false }: Props) => {

    if (!doctors) return null;

    return (
        <Menu
            as="div"
            className="relative w-full "
        >
            <MenuButton className=" w-full rounded-md border-0 py-2 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6 focused"
                disabled={disabled}>
                <span className="sr-only">Select Doctors</span>
                <span className="flex items-center justify-between w-full">
                    <span
                        className={classNames(
                            disabled ? "text-gray-400" : "text-gray-900",
                            "text-sm font-normal leading-6 ",
                        )}
                        aria-hidden="true"
                    >
                        {editedData?.doctors?.doctor_name || "None"}
                    </span>
                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
            </MenuButton>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems className="absolute w-full right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-sm ring-1 ring-gray-300 focus:outline-none  cursor-pointer">
                    {doctors?.map((doctor: any, index: number) => (
                        <MenuItem key={index}>
                            {({ focus }) => (
                                <div
                                    onClick={() => {
                                        setEditedData({
                                            ...editedData,
                                            doctors: {
                                                doctor_name: doctor.doctor_name,
                                                id: doctor.id,
                                            },
                                        });
                                    }}
                                    className={classNames(
                                        focus ? "bg-gray-50" : "",
                                        doctor?.id === editedData?.doctors?.id ? "bg-max-800    text-white" : "",
                                        "block px-3 py-1 text-sm leading-6 text-gray-900",
                                    )}
                                >
                                    {doctor.doctor_name}
                                </div>
                            )}
                        </MenuItem>
                    ))}
                    {/* seperator */}
                    <div className="border-t border-gray-200" />
                    <MenuItem>
                        {({ focus }) => (
                            <div
                                onClick={() => {
                                    setEditedData({
                                        ...editedData,
                                        doctors: null,
                                    });
                                }}
                                className={classNames(
                                    focus ? "bg-gray-50" : "",
                                    !editedData?.doctors?.id ? "bg-max-800    text-white" : "",
                                    "block px-3 py-1 text-sm leading-6 text-gray-900",
                                )}
                            >
                                None
                            </div>
                        )}
                    </MenuItem>
                </MenuItems>
            </Transition>
        </Menu>
    );
};

export default DoctorPicker;