import ThumbsDownIcon from "@icons/thumbs-down.svg?react";
import ThumbsUpIcon from "@icons/thumbs-up.svg?react"; import classNames from "classnames";
import { toast } from "react-toastify";
import { updateChatCompletions } from "@common/lib/supabaseClient";
import { Tooltip } from 'react-tooltip'
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { useState } from "react";
import { ChatCompletions } from "@common/utils/types";
import { getActiveTabId, updateDocumentInTab } from "@features/patient-view/state/redux/activeDocumentsSlice";
import FeedbackModal from "@common/components/FeedbackModal";
import { postNewFeedbackToSlack } from "@common/lib/api";
import { useSessionContext } from "@supabase/auth-helpers-react";
import * as Sentry from "@sentry/react";
import { VITE_PUBLIC_ENV } from "@/common/utils/constants";
import { toolTipStyles } from '@/theme';

//enum for good bad and needs editing
enum DocumentFeedbackEnum {
    Good = "Output Perfect",
    NeedsEditing = "Formatting Issues",
    Bad = "Information Issues",
}

const Feedback = ({
    completionId,
    currentFeedback,
    documentName,
    feedbackType,
}: {
    completionId: string;
    currentFeedback: string | null;
    documentName: string;
    feedbackType: DocumentFeedbackEnum;
}) => {
    const dispatch = useAppDispatch();
    const tabId = useAppSelector(getActiveTabId);
    const { session } = useSessionContext();
    const [feedbackModalOpen, setFeedbackModal] = useState(false);
    const [feedback, setFeedback] = useState("");

    const sendFeedback = async (feedbackValue: DocumentFeedbackEnum, feedbackDescription: string | null) => {
        // update FeedbackValue in the db regardless of if the user writes feedback or not
        if (completionId) {
            await updateChatCompletions({ feedback: feedbackValue, feedback_description: feedbackDescription }, completionId);
            dispatch(
                updateDocumentInTab({
                    tabId: tabId,
                    document: { id: completionId, feedback: feedbackValue } as ChatCompletions,
                }),
            );

            const acct_id = session?.user?.id;
            const doc_name = documentName
            const email = session?.user?.email;

            if (feedbackValue === DocumentFeedbackEnum.Good) {
                feedbackDescription = "<output perfect>";
            }

            // if the user has provided written feedback OR clicked green thumbs up, send this to slack
            if (feedbackDescription || feedbackValue === DocumentFeedbackEnum.Good) {
                try {
                    if (!session) {
                        Sentry.captureMessage("Session not found in FeedbackButtons.tsx", {
                            level: "error",
                        });
                        return;
                    }

                    let description = feedbackDescription ? feedbackDescription : "";
                    VITE_PUBLIC_ENV !== 'local' && (await postNewFeedbackToSlack(session, completionId, feedbackValue, description, acct_id, doc_name, email));
                    showSuccess();
                } catch (error) {
                    Sentry.captureException(error);
                }
            }
        }
    };

    const onCancel = () => {
        sendFeedback(feedbackType, null);
        setFeedbackModal(false);

    };

    const onSubmit = () => {
        sendFeedback(feedbackType, feedback);
        setFeedbackModal(false);
    };

    const showSuccess = () => {
        toast.success("Thank you for the feedback! We will use it to improve the output");
    };

    return (
        <>
            {feedbackType === DocumentFeedbackEnum.Good ?
                <>  <Tooltip
                    content="Output Perfect"
                    anchorSelect="#thumbs-up"
                    style={toolTipStyles}
                />
                    <button
                        id="thumbs-up"
                        className={classNames(
                            currentFeedback === DocumentFeedbackEnum.Good ? "bg-gray-200 text-gray-700 " : "text-gray-700",
                            " p-1 hover:bg-gray-100 hover:text-gray-700 hover:rounded-sm focused",
                        )}
                        onClick={() => {
                            sendFeedback(DocumentFeedbackEnum.Good, null);
                        }}
                    >
                        <ThumbsUpIcon className="h-4 w-4" />
                    </button>
                </>
                :
                <>
                    <Tooltip
                        content="Information Issues"
                        anchorSelect="#thumbs-down"
                        style={toolTipStyles}
                    />
                    <button
                        id="thumbs-down"
                        className={classNames(
                            currentFeedback === DocumentFeedbackEnum.Bad ? "bg-gray-200 text-gray-700 " : "text-gray-700",
                            " p-1 hover:bg-gray-100 hover:text-gray-700 focused",
                        )}
                        onClick={() => {
                            setFeedbackModal(true);
                        }}
                    >
                        <ThumbsDownIcon className="h-4 w-4" />
                    </button>
                </>
            }
            <FeedbackModal
                isOpen={feedbackModalOpen}
                onCancel={onCancel}
                onSubmit={onSubmit}
                setFeedback={setFeedback}
            />

        </>
    )
}

export default Feedback