import { useEffect, useRef, useState } from "react";
import { PatientRecord, TranscriptSection, TranscriptSectionStatus } from "@common/utils/types";
import TrashIcon from "@icons/trash-03.svg?react";
import RefreshIcon from "@icons/refresh-cw-03.svg?react";
import InfoCircleIcon from "@icons/info-circle.svg?react";
import classNames from "classnames";
import ChevronDownIcon from "@icons/chevron-down.svg?react";
import ChevronRightIcon from "@icons/chevron-right.svg?react";
import SwitchHorizontalIcon from "@icons/switch-horizontal-01.svg?react";
import { useAppSelector } from "@/common/hooks/useRedux";
import { getPatientData } from "../../state/redux/activePatientDataSlice";
import ProgressBar from "@/common/components/ProgressBar";
import { determineErrorMessage, determineErrorState } from "@/common/utils/helpers";
import RetryTranscriptSection from "@/common/components/RetryTranscriptSection";

interface Props {
	transcriptSection: TranscriptSection;
	isSelected: boolean;
	documentCreationTime: Date | null;
	onDelete: () => void;
	onMove: () => void;
}

const TranscriptLine = ({ transcriptSection, isSelected = false, documentCreationTime, onDelete = () => { }, onMove = () => { } }: Props) => {
	const [showMoreInfo, setShowMoreInfo] = useState(false);
	const [isExpanded, setIsExpanded] = useState(isSelected);
	const [isOverflowing, setIsOverflowing] = useState(false);
	const textRef = useRef<HTMLDivElement>(null);

	const patientData: PatientRecord = useAppSelector(getPatientData);

	const handleDelete = () => {
		onDelete();
	};
	const handleMove = () => {
		onMove()
	};

	useEffect(() => {
		if (textRef.current) {
			const { scrollHeight, clientHeight } = textRef.current;
			setIsOverflowing(scrollHeight > clientHeight);
		}
	}, [transcriptSection?.data]);

	const creationTime = new Date(transcriptSection?.created_at || '');
	const formattedTime = creationTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
	const formattedDate = creationTime.toLocaleDateString();

	const wasRemoved = "No audio or removed during post-processing.";
	const wasRemovedDescriptive =
		"The transcription returned here was blank. Either no audio data existed or in select cases, very short audio <10s or poor quality audio, the data gets removed during post processing. If this is a concern contact support.";
	const warningMessage = "The clip is recorded but not used in a document";
	const errorState = determineErrorState(transcriptSection, documentCreationTime);

	const errorMessage = determineErrorMessage(transcriptSection);

	let textColor = 'text-gray-900';
	let statusStyles = "bg-white text-gray-900 border-gray-200";

	if (errorState === 'error') {
		textColor = 'text-red-900';
		statusStyles = "bg-red-50 text-red-900 border-red-50";
	} else if (errorState === 'warning') {
		textColor = 'text-yellow-900';
		statusStyles = "bg-yellow-50 text-yellow-900 border-yellow-50";
	}

	return (
		<div className={classNames("flex flex-col border-b border-gray-200 px-6 py-4", isSelected ? "bg-max-50" : "bg-white")}>
			<div className={classNames("flex flex-row justify-start")}>
				{isOverflowing && (
					<button
						onClick={() => setIsExpanded(!isExpanded)}
						className="focused self-start text-gray-900 pr-2"
					>
						{isExpanded ? <ChevronDownIcon className="h-5 w-5" /> : <ChevronRightIcon className="h-5 w-5" />}
					</button>
				)}
				<div className="flex-grow items-center">
					{transcriptSection?.data === "loading" || transcriptSection?.status === TranscriptSectionStatus.InProgress ? (
						<div className="w-full">
							<ProgressBar seconds={180} tabId={transcriptSection?.id} />
							<span className="text-gary-900 text-sm">Transcript is loading...</span>
						</div>
					) : transcriptSection?.was_removed ? (
						<div
							className="w-full whitespace-pre-line text-xs text-gray-500 flex flex-row justify-start items-center"
							onMouseEnter={() => setShowMoreInfo(true)}
							onMouseLeave={() => setShowMoreInfo(false)}
						>
							<InfoCircleIcon className="h-4 w-4 text-gray-500" />
							{showMoreInfo ? wasRemovedDescriptive : wasRemoved}
						</div>
					) : errorState === 'error' ? (
						<div className={classNames("flex flex items-center gap-4 text-sm px-6 py-3 w-full h-full border rounded-md text-semibold", statusStyles, textColor)}>
							<InfoCircleIcon className="h-4 w-4" />
							<span>{errorMessage}</span>
							<RetryTranscriptSection transcriptSection={transcriptSection} patientRecord={patientData}>
								<span className={classNames(textColor, " text-sm leading-6 font-medium underline")}>Retry</span>
							</RetryTranscriptSection>
						</div>
					) : (
						<div ref={textRef} className={`w-full whitespace-pre-line text-gray-900 text-sm font-normal leading-sm ${isExpanded ? '' : 'line-clamp-3'}`}>
							<span>{transcriptSection.data}</span>
							{errorState === 'warning' && (
								<div className={classNames("flex flex items-center gap-4 text-sm px-6 py-3 w-full h-full border rounded-md text-semibold mt-2", statusStyles, textColor)}>
									<InfoCircleIcon className="h-4 w-4" />
									<span>{warningMessage}</span>
								</div>
							)}
						</div>
					)}
				</div>
				<div className="ml-4 flex h-full flex-row space-x-2 rounded-md border border-gray-300 bg-gray-100 px-4 py-2 font-semibold text-gray-500 hover:text-gray-600 hover:shadow-sm hover:ring-1 hover:ring-inset hover:ring-gray-100 hover:bg-gray-200">
					<RetryTranscriptSection transcriptSection={transcriptSection} patientRecord={patientData} renderAlways={true}>
						<div className="btn btn-ghost btn-xs btn-circle">
							<RefreshIcon className="text-gray-900 h-4 w-4" />
						</div>
					</RetryTranscriptSection>
					<div onClick={handleMove} className="btn btn-ghost btn-xs btn-circle">
						<SwitchHorizontalIcon className="text-gray-900 h-4 w-4" />
					</div>
					<div onClick={handleDelete} className="btn btn-ghost btn-xs btn-circle">
						<TrashIcon className="text-gray-900 h-4 w-4" />
					</div>
				</div>
			</div>


			<div className="flex items-center gap-2 mt-2">
				<p className="text-xs font-normal text-gray-900">{formattedDate}</p>
				<p className="text-xs font-normal text-gray-900">{formattedTime}</p>
			</div>

		</div>
	);
};

export default TranscriptLine;
