import React, { useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@common/hooks/useRedux';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import { transcribeAudio } from '../lib/api';
import { PatientRecord, TranscriptSection, TranscriptSectionStatus } from '../utils/types';
import UploadModal from './reupload-modal/ReuploadModal';
import * as Sentry from '@sentry/react';
import { determineErrorMessage, isTranscriptError } from '../utils/helpers';
import Spinner from './Spinner';
import classNames from 'classnames';
import { updateOrAddTranscriptSection } from '@/features/patient-view/state/redux/activePatientDataSlice';
import { useAccountContext } from '@/state/providers/AccountProvider';

interface RetryTranscriptSectionProps {
    patientRecord: PatientRecord;
    transcriptSection: TranscriptSection;
    children: React.ReactNode;
    renderAlways?: boolean;
    disabled?: boolean;
    showTooltip?: boolean;
}

const RetryTranscriptSection: React.FC<RetryTranscriptSectionProps> = ({
    disabled = false,
    showTooltip = false,
    transcriptSection,
    renderAlways = false,
    children,
    patientRecord,
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [retryLoading, setRetryLoading] = useState(false);
    const { selectedDocuments } = useAppSelector((state) => state.documents);
    const { accountData } = useAccountContext();
    const dispatch = useAppDispatch();
    /**
    * Handles transcription errors
    * @returns void
    */
    const handleTranscriptionErrors = useCallback(async () => {
        setRetryLoading(true);

        try {

            // update transcript section status in state
            dispatch(
                updateOrAddTranscriptSection({
                    transcriptId: patientRecord?.transcriptions?.id || "",
                    transcriptSection: { id: transcriptSection?.id, status: TranscriptSectionStatus.InProgress },
                }),
            );

            const { error } = await transcribeAudio(
                patientRecord.transcriptions?.id || "",
                transcriptSection.id,
                transcriptSection?.unprocessed_filename || "",
                patientRecord.id,
                patientRecord?.patient_name || "",
                patientRecord?.doctors?.id || "",
                selectedDocuments,
            );

            if (error) throw new Error("Error while retrying transcription");

        } catch (error) {
            Sentry.captureException(error);
            toast.error("Error while retrying transcription.");
            dispatch(updateOrAddTranscriptSection({
                transcriptId: patientRecord?.transcriptions?.id || "",
                transcriptSection: { id: transcriptSection?.id, status: TranscriptSectionStatus.FailedErrorProcessing },
            }))
        } finally {
            setRetryLoading(false);
        }
    }, [patientRecord, accountData?.id, selectedDocuments, transcriptSection]);

    const handleReuploadModal = () => {
        setOpenModal(!openModal);
    }

    function handleClick(status?: TranscriptSectionStatus | null) {
        if (status === TranscriptSectionStatus.FailedIncompleteUpload) {
            handleReuploadModal();
        } else {
            handleTranscriptionErrors();
        }
    }

    const renderButton = () => {
        if (renderAlways || (transcriptSection?.status && isTranscriptError(transcriptSection))) {
            return (
                <div>
                    {showTooltip && <Tooltip
                        content={determineErrorMessage(transcriptSection)}
                        anchorSelect={`#transcription-error-retry-${transcriptSection.id}`}
                        place="top"
                        className='tooltip'
                    />}
                    {retryLoading ?
                        <span className='w-full flex flex-row items-center justify-center text-gray-900 h-full'>
                            <Spinner size='xsmall' color='text-gray-900' />
                        </span>
                        :
                        <div
                            id={`transcription-error-retry-${transcriptSection.id}`}
                            onClick={() => handleClick(transcriptSection?.status)}
                            className={classNames("w-full flex flex-row items-center font-medium hover:cursor-pointer", {
                                'cursor-not-allowed': disabled,
                            })}
                        >
                            {children}
                        </div>
                    }
                </div>
            );
        }
        return null;
    }

    return (
        <div className='flex'>
            {renderButton()}
            <UploadModal
                disabled={disabled}
                open={openModal}
                setOpen={setOpenModal}
                showButton={false}
            />
        </div >
    );
};

export default RetryTranscriptSection;