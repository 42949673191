//Open AI format for chat completions
export type OpenAIChatCompletions = {
	role: string;
	content: string | JSX.Element;
};

export type AppVersion = {
	id: number;
	version: number;
}

//DATABASE TYPES
export type Doctor = {
	id?: string;
	account_id?: string;
	created_at?: string | null;
	doctor_name?: string | null;
	email?: string | null;
	email_confirmed_at?: string | null;
	confirmation_sent_at?: string | null;
	doctor_document_types?: { document_type_id: string }[];
}

export type DocumentType = {
	id: string;
	document_type_name: string;
	account_id?: string;
	created_at?: string | null;
	output_parser?: JSON | null;
	prompt?: string;
	prompt_components?: JSON | null;
	prompt_override?: boolean;
	training_data?: JSON | null;
	auto_generate?: boolean;
	is_default?: boolean;
	is_archived?: boolean;
	description?: string;
	last_updated_at?: string;
	is_active?: boolean;
	creator_prompt_components?: SectionFormatting[] | null;
	custom_document_type?: string | null;
	is_json?: boolean;
	document_exports?: DocumentExport | null;
	document_foundation_id?: string | null;
};

export type PartialDocumentType = {
	id: string;
	document_type_name: string;
	is_archived: boolean;
	is_json: boolean | null;
}

/**
 * Represents a record in the document_exports table.
 */
export type DocumentExport = {
	/**
	 * The id of the export
	 */
	id: string;

	/**
	 * when the export was created
	 */
	created_at: string | null;

	/**
	 * what account the export is associated with
	 */
	account_id: string;

	/**
	 * what account integration the export is associated with
	 */
	account_integrations_id: string | null;

	/**
	 * true if the export is active
	 */
	is_active: boolean;

	/**
	 * the url of the export, relative to the base storage url
	 */
	url: string;

	/**
	 * the type of the export (pdf, docx, etc.)
	 */
	content_type: string | null;
}



export enum PatientStage {
	Ongoing = "ONGOING",
	Review = "REVIEW",
	Finalized = "FINALIZED",
}

export type Transcript = {
	id?: string;
	created_at?: string;
	doctor_id?: string | null;
	patient_record_id?: string;
	transcript_sections?: TranscriptSection[];
};

export enum DocumentStatus {
	Completed = "completed",
	Processing = "processing",
	Failed = "failed",
	Queued = "queued",
	Cancelled = "cancelled",
}

export type ChatCompletions = {
	account_id?: string | null;
	chat_json?: any | null;
	chat_json_original?: any | null;
	created_at?: string | null;
	document_type_id?: string | null;
	id?: string | null;
	patient_record_id?: string | null;
	role?: string | null;
	transcription_id?: string | null;
	type?: string | null;
	feedback?: string | null;
	exported?: boolean | null;
	logs?: any;
	count?: number | null;
	feedback_description?: string | null;
	document_types?: DocumentType | null;
	document_snapshot?: SectionFormatting[] | null;
	status?: DocumentStatus;
	header?: string | null;
};


export type DisplayDocument = DisplaySection[];


/**
 * Represents a section of the document that is ready to be displayed by any UI.
 */
export interface DisplaySection {
	/**
	 * The ID of the section. Included for reference.
	 */
	id: string;

	/**
	 * The name of the section.
	 */
	name: string;

	/**
	 * The value of the section.
	 */
	value: string | string[];

	/**
	 * The formatting of the section. Included so that the UI can display arrays correctly at runtime.
	 */
	formatting: FormattingOption;

	/**
	 * The ordered fields of the section.
	 */
	fields: DisplayField[];

	/**
	 * Boolean indicating if the section has errors.
	 */
	hasErrors?: boolean
}


/**
 * Represents a field of the document that is ready to be displayed by any UI.
 */
export interface DisplayField {
	/**
	 * The ID of the field. Included for reference.
	 */
	id: string;

	/**
	 * The name of the field.
	 */
	name: string;

	/**
	 * The value of the field.
	 */
	value: string | string[];

	/**
	 * The formatting of the field. Included so that the UI can display arrays correctly at runtime.
	 */
	formatting: FormattingOption;

	/**
	 * Whether the field is normal. Included so that the UI can display bolded fields correctly at runtime.
	 */
	isNormal: boolean;

	/**
	 * Boolean indicating if the field has been edited by the user.
	 */
	isEdited: boolean;

	/**
	 * Data type indicating the source of the response. Currently (USER_DEFINED, GPT)
	 */
	response_source: DocumentResponseSources;
}

export interface FieldFormatting {
	id: string;
	field_name: string;
	normal_values: string;
	formatting?: FormattingOption;
	displayTitle?: boolean;
	displayOutput?: boolean;
	display_transcript_value?: DisplayTranscriptOptions;
}

export interface SectionFormatting {
	id: string;
	section_name: string;
	formatting: FormattingOption;
	normal_values: string;
	display_transcript_value?: DisplayTranscriptOptions;
	displayTitle?: boolean;
	displayOutput?: boolean;
	fields: FieldFormatting[];
}

export type DocumentTab = {
	//document_type_id + header
	tabId: string;
	//document header
	header: string;
	//Group of records
	documents: ChatCompletions[];
	// is tab loading
	isLoading: boolean;
	// is tab error
	error: string | null;
};

export interface TableTab {
	// value to display
	header: string;
	// unique value for the tab
	id: string;
	// is active tab
	isActive: boolean;
	// show count of something
	count?: number;
	// show icon for tab
	icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
	// show error dot
	error?: boolean;

}

export type AccountMetaData = {
	markdown_copy_enabled?: boolean;
}

export type Account = {
	id?: string;
	clinic_location?: string | null;
	clinic_name?: string | null;
	created_at?: string | null;
	is_self_serve?: boolean;
	feature_flags?: string[] | null;
	meta_data?: AccountMetaData | null;
	api_key?: string | null;
	current_pims?: PIMSType | null;
	hidden_documents?: string[] | null;
	email?: string | null;
	trial_end_date?: string | null;
	has_completed_profile?: boolean;
};

export interface Address {
	id: string;
	account_id: string;
	street_address: string;
	street_address_2?: string;
	city: string;
	state_region: string;
	postal_code: string;
	country: string;
	latitude?: number;
	longitude?: number;
	created_at?: string;
	updated_at?: string;
}

export type UnNestedPatientRecordData = {
	account_id?: string;
	appointment_duration?: number;
	created_at?: string | null;
	doctor_id?: string | null;
	id?: string;
	patient_id?: string | null;
	patient_name?: string | null;
	status?: string | null;
	scheduled_at?: string | null;
	stage?: PatientStage;
};

export enum TranscriptSectionStatus {
	NotStarted = "not_started",
	InProgress = "in_progress",
	Cancelled = "cancelled",
	Completed = "completed",
	FailedIncompleteUpload = "failed_incomplete_upload",
	FailedBlankTranscript = "failed_blank_transcript",
	FailedErrorProcessing = "failed_error_processing",
	FailedIrrelevantInformation = "failed_irrelevant_information"
}

export const TranscriptSectionStatusFailedDisplay = {
	[TranscriptSectionStatus.FailedIncompleteUpload]: "Incomplete upload.",
	[TranscriptSectionStatus.FailedBlankTranscript]: "Transcript is blank. Check the clip for valid audio and retry.",
	[TranscriptSectionStatus.FailedErrorProcessing]: "Error in transcript processing. Retry.",
	[TranscriptSectionStatus.FailedIrrelevantInformation]: "Transcript does not contain enough relevant content.",
}

export type TranscriptSection = {
	transcription_id?: string;
	id: string;
	data?: string;
	unprocessed_filename?: string;
	processed_filename?: string;
	was_removed?: boolean;
	created_at?: string;
	account_id?: string;
	retried_automatically?: boolean;
	error?: string | null;
	uploadProgress?: number | null;
	count?: number | null;
	audio_duration?: number | null;
	status?: TranscriptSectionStatus | null;
};

export type PatientRecord = {
	appointment_duration?: number;
	created_at?: string | null;
	doctors?: Doctor | null;
	id: string;
	patient_id?: string | null;
	patient_name?: string | null;
	status?: string | null;
	stage?: PatientStage;
	transcriptions?: Transcript | null;
	scheduled_at?: string | null;
	chat_completions?: ChatCompletions[] | null;
	doctor_id?: string | null;
	contacts?: Contact | null;
	is_archived?: boolean | null;
	account_integrations?: AccountIntegration | null;
	patients?: Patient | null;
};

export type Contact = {
	id: string;
	created_at: string;
	email: string;
	first_name: string;
	last_name: string;
	primary_phone: string;
	primary_email: string;
	account_id: string;
	account_integrations_id: string;
	external_id: string;
	secondary_id: string;
};

export type Patient = {
	id: string;
	name: string;
	secondary_id: string;
};


export const enum RecordingState {
	inactive = "inactive",
	recording = "recording",
	paused = "paused",
	error = "error",
	no_permission = "no-permission",
	no_device = "no-device",
	loading = "loading",
}

export const enum MedicalRecordState {
	empty = "Empty", // No audio, no documents
	processing = "Processing", // at least one audio or document loading
	generated = "Generated", // at least one document generated
	completed = "Completed", // user acted on a document
	error = "Error",
	transcribed = "Transcribed", // at least one audio transcribed, but no documents
	FileSizeError = "Unsupported File Size",
	Uploading = "Uploading",
	//These are client side only
	recording = "Recording",
	paused = "Paused",
}

export type AccountIntegration = {
	id?: string | null;
	practice_id?: string | null;
	connected_third_party?: string | null;
	last_request?: string | null;
	api_url?: string | null;
	export_enabled?: boolean | null;
	integrations?: Integration | null;
	is_active?: boolean | null;
	integration_name?: string | null;
};

export type Integration = {
	integration_name?: string | null;
};

// stripe

export interface Appointment {
	patient_name: string;
	id: string;
	patient_id: string;
	scheduled_at: string;
	appointment_duration: number;
	columnIndex: number;
	doctors: Doctor
}

export type StripeProduct = {
	id: string;
	active: boolean;
	name: string;
	description: string;
	image: string | null;
	metadata: Record<string, any>;
	prices: StripePrice[];
};

export type StripePrice = {
	id: string;
	type: "recurring" | "one_time"; // Assuming these are the only two types.
	active: boolean;
	currency: string;
	interval: "day" | "week" | "month" | "year"; // Add more intervals as needed.
	metadata: {
		num_doctors: string;
		integrations: string;
		muli_location: string;
		num_documents: string;
		custom_doc_types: string;
	};
	product_id: string;
	description: string | null;
	unit_amount: number;
	interval_count: number;
	trial_period_days: number | null;
};

export type SubscriptionStatus =
	| "trialing"
	| "active"
	| "canceled"
	| "incomplete"
	| "incomplete_expired"
	| "past_due"
	| "unpaid"
	| "paused";

export interface SubscriptionFeatures {
	custom_document_types: boolean;
	documents_generated: any;
	integrations: boolean;
	multi_location_support: boolean;
	maximum_DVMs: any;
	[key: string]: any;
}

export interface Subscription {
	id: string; // Subscription ID from Stripe
	user_id: string; // UUID, assuming it's stored as a string
	status: SubscriptionStatus;
	is_self_serve: boolean;
	features: SubscriptionFeatures;
	metadata: Record<string, any>; // JSONB, assuming any shape of object
	price_id: string;
	quantity: number;
	num_doctors: number | null; // Assuming these can be null
	num_appts: number | null;
	cancel_at_period_end: boolean;
	created: Date; // Timestamps
	current_period_start: Date;
	current_period_end: Date;
	ended_at: Date | null;
	cancel_at: Date | null;
	canceled_at: Date | null;
	trial_start: Date | null;
	trial_end: Date | null;
}

export enum FeatureFlags {
	HIDE_TRANSCRIPTIONS = "HIDE_TRANSCRIPTIONS",
	ENABLE_ADVANCED_DOCUMENT_CREATION = "ENABLE_ADVANCED_DOCUMENT_CREATION",
	ENABLE_CUSTOM_DOCUMENT_CREATION = "ENABLE_CUSTOM_DOCUMENT_CREATION",
	JSON_DOCUMENT_CREATION = "JSON_DOCUMENT_CREATION",
	ENABLE_CUSTOM_FIELD_CREATION = "ENABLE_CUSTOM_FIELD_CREATION",
	ENABLE_CUSTOM_PROMPT = "ENABLE_CUSTOM_PROMPT",
	ENABLE_FAST_GENERATION = "ENABLE_FAST_GENERATION",
	ENABLE_TRANSCRIPTS_BUTTON = "ENABLE_TRANSCRIPTS_BUTTON",
}

export enum SectionType {
	SECTION = "section",
	FIELD = "field",
	JSON = "JSON",
	META = "meta-beginning"
}

export enum ReservedSectionNames {
	DocumentProgress = "documentProgress",
}

export type RecordComponent = CreatorPromptField & {
	fields?: CreatorPromptField[];
};

// Categories
export type CreatorPromptCategory = {
	id?: string;
	display_name?: string;
};

export type CreatorPromptFieldCategory = {
	id: string;
	field_id: string;
	category_id: string;
}

// Custom field category
export const CUSTOM_FIELD_CATEGORY: CreatorPromptCategory = {
	id: "589c9735-2508-47eb-8984-b2a867909d0d",
	display_name: "Custom Fields"
}

//Field
export type CreatorPromptField = {
	id: string;
	created_at?: string | null;
	field_name?: string;
	other_names?: string[];
	section_type?: SectionType;
	instructions?: string;
	normal_values?: string;
	has_normal_values?: boolean;
	description?: string;
	field_description?: string;
	example?: string;
	formatting?: FormattingOption;
	default_format?: FormattingOption;
	formatting_options?: FormattingOption[];
	display_transcript_value?: DisplayTranscriptOptions;
	display_transcript_options?: DisplayTranscriptOptions[];
	response_source?: DocumentResponseSources;
	hidden?: boolean;
	displayTitle?: boolean;
	displayOutput?: boolean;
	categories?: CreatorPromptCategory[];
	account_id?: string;
	display_output_default?: boolean;
	display_title_default?: boolean;
	example_prompts?: ExamplePrompt[]
	customPrompt?: string;
	customPromptOptions?: string[];
	is_archived?: boolean;
};

export type ExamplePrompt = {
	id: string;
	created_at: string;
	name: string | null;
	example: string | string[]
	field_id: string | null;
	species: string | null;
};

export type CustomFieldMeta = {
	account_id?: string;
	field_id?: string;
	custom_prompt?: string;
	document_type_id?: string;
}

export interface Stage {
	name: string;
	icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & {
		title?: string;
	}>;
	value: PatientStage;
}

// Type guard to check if the section is CreatorPromptField
export function isCreatorPromptField(section: any): section is CreatorPromptField {
	return (section as CreatorPromptField).field_name !== undefined;
}

export enum FormattingOption {
	Paragraph = "paragraph",
	Bulleted = "unordered-list",
	Dashed = "dashed-list",
	Numbered = "ordered-list",
	FlatList = "flat-list",
	None = "none",
}

export enum Species {
	canine = "canine",
	feline = "feline",
	equine = "equine",
	other = "other",
	bird = "bird",
	reptile = "reptile",
	rodent = "rodent",
	fish = "fish",
	cattle = "cattle",
	pig = "pig",
}

export enum FormattingOptionToDisplay {
	"paragraph" = "Paragraph",
	"unordered-list" = "Bulleted",
	"dashed-list" = "Dashed",
	"ordered-list" = "Numbered",
	"flat-list" = "Flat List",
	"none" = "None",
}

export enum DisplayTranscriptOptionsToDisplay {
	"always" = "Display discussed value always",
	"abnormal" = "Display discussed value if abnormal, otherwise use normal value",
}

export enum DocumentResponseSources {
	USER_DEFINED = "USER_DEFINED",
	GPT = "GPT",
}

// represents public.display_transcript_options
export enum DisplayTranscriptOptions {
	always = "always",
	abnormal = "abnormal",
	// never = "never",
}

export enum BroadcastTypes {
	success = "success",
	failed = "failed",
}

export enum BroadcastEvents {
	transcription = "transcription",
	document = "document",
	patientDeleted = "patient-deleted",
	patientEvent = "patient-event",
	documentDeleted = "document-deleted",
	documentCreated = "document-created",
	documentUpdated = "document-updated",
	documentSelection = "document-selection",
	emailConfirmationSent = "email-confirmation-sent",
	emailConfirmed = "email-confirmed",
}

export const BROADCAST_EVENT = "client-update";

export enum PatientRecordFetchErrors {
	NO_PATIENT_RECORD = "No Patient record found",
	FETCH_FAILED = "Failed to fetch patient record",
}

//enum for good bad and needs editing
export enum DocumentFeedbackEnum {
	Good = "Output Perfect",
	NeedsEditing = "Formatting Issues",
	Bad = "Information Issues",
}

export enum BuilderMagicStrings {
	PATIENT_ID = "patient_id",
	PATIENT_SPECIES = "patient_species",
	PATIENT_NAME = "patient_name",
	PATIENT_SEX = "patient_sex",
	PATIENT_BREED = "patient_breed",
	PATIENT_AGE = "patient_age",

	DOCTOR_NAME = "doctor_name",
	CURRENT_DATE = "current_date",
	CURRENT_TIME = "current_time",

	CONTACT_NAME = "contact_name",
	CONTACT_ID = "contact_id",
	CONTACT_PHONE = "contact_phone",
	CONTACT_EMAIL = "contact_email",
}


//ERRORS

export const STREAMING_COMPLETION_ERROR =
	"Network issue detected. Please try again or contact support if the problem continues.";

export const FETCH_ERROR_REFRESH =
	"Error retrieving data. Please refresh the page. If the issue persists, contact support.";

export const NO_PATIENT_RECORD =
	"Error retrieving data. The patient may have been deleted. Please refresh or contact support if you believe this is an error.";



/**
 * Represents the status of an active patient.
 */
export enum ActivePatientStatus {
	/**
	 * Indicates that the active patient is idle.
	 */
	Idle = "idle",

	/**
	 * Indicates that the active patient is currently loading.
	 */
	Loading = "loading",

	/**
	 * Indicates that the active patient has succeeded.
	 */
	Succeeded = "succeeded",

	/**
	 * Indicates that the active patient has failed.
	 */
	Failed = "failed",
};


/**
 * Represents the data for an active patient.
 */
export type ActivePatientData = {
	patientData: PatientRecord | null;
	transcriptSections: TranscriptSection[];
	multiplePatients: MultiPatientData[];
	status: ActivePatientStatus;
	error: PatientRecordFetchErrors | null;
};

/**
 * Represents multi-patient data.
 */
export type MultiPatientData = {
	name: string;
	isSelected: boolean;
	index: number;
};

/**
 * Represents the PIMS integration
 */
export enum PIMSType {
	AVIMARK = "avimark",
	CORNERSTONE = "cornerstone",
	IMPROMED = "impromed",
	PULSE = "pulse_evet_practice",
	EZYVET = "ezyvet",
	HIPPO = "hippo",
	SHEPHERD = "shepherd",
	VETSPIRE = "vetspire",
	PROVET = "provet_cloud",
	DAYSMART = "daysmart",
	INSTINCT = "instinct",
	NEO = "neo",
	NAVETOR = "navetor",
	INTRAVET = "intravet",
	VETFM = "vetfm",
	WOOFWARE = "woofware",
	PETWARE = "petware",
	DIGITAIL = "digitail",
	OTHER = "other",
}

/* 
 * Maps the PIMS type to a proper display type
*/

export const PIMSDisplay = {
	[PIMSType.AVIMARK]: "Avimark",
	[PIMSType.CORNERSTONE]: "Cornerstone",
	[PIMSType.IMPROMED]: "Impromed",
	[PIMSType.PULSE]: "Pulse (eVet Practice)",
	[PIMSType.EZYVET]: "ezyVet",
	[PIMSType.HIPPO]: "Hippo",
	[PIMSType.SHEPHERD]: "Shepherd",
	[PIMSType.VETSPIRE]: "Vetspire",
	[PIMSType.PROVET]: "Provet Cloud",
	[PIMSType.DAYSMART]: "DaySmart",
	[PIMSType.INSTINCT]: "Instinct",
	[PIMSType.NEO]: "Neo",
	[PIMSType.NAVETOR]: "NaVetor",
	[PIMSType.INTRAVET]: "IntraVet",
	[PIMSType.VETFM]: "VetFM",
	[PIMSType.WOOFWARE]: "Woofware",
	[PIMSType.PETWARE]: "Petware",
	[PIMSType.DIGITAIL]: "Digitail",
	[PIMSType.OTHER]: "Other",
};
