import { useRef, useEffect } from 'react';

// adding horizontal scroll functionality
function useScroll() {
	const sliderRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const slider = sliderRef.current;
		if (!slider) return;

		let isDown = false;
		let startX: number;
		let scrollLeft: number;

		const end = () => {
			isDown = false;
		};

		const start = (e: MouseEvent | TouchEvent) => {
			isDown = true;
			startX = (e instanceof MouseEvent ? e.pageX : e.touches[0].pageX) - slider.offsetLeft;
			scrollLeft = slider.scrollLeft;
		};

		const move = (e: MouseEvent | TouchEvent) => {
			if (!isDown) return;
			e.preventDefault();
			const x = (e instanceof MouseEvent ? e.pageX : e.touches[0].pageX) - slider.offsetLeft;
			const walk = x - startX;
			slider.scrollLeft = scrollLeft - walk;
		};

		const scroll = (e: WheelEvent) => {
			slider.scrollLeft += e.deltaY;
		};

		slider.addEventListener('wheel', scroll, { passive: true });
		slider.addEventListener('mousedown', start);
		slider.addEventListener('mouseleave', end);
		slider.addEventListener('mouseup', end);
		slider.addEventListener('mousemove', move);

		// Add touch event listeners for mobile devices
		slider.addEventListener('touchstart', start);
		slider.addEventListener('touchend', end);
		slider.addEventListener('touchmove', move);

		return () => {
			slider.removeEventListener('wheel', scroll);
			slider.removeEventListener('mousedown', start);
			slider.removeEventListener('mouseleave', end);
			slider.removeEventListener('mouseup', end);
			slider.removeEventListener('mousemove', move);

			// Remove touch event listeners
			slider.removeEventListener('touchstart', start);
			slider.removeEventListener('touchend', end);
			slider.removeEventListener('touchmove', move);
		};
	}, []);

	return sliderRef;
}

export default useScroll;
