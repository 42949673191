import { jsonToMarkdown } from '@/common/utils/formatter';
import { ChatCompletions } from '@/common/utils/types';
import { useAccountContext } from '@/state/providers/AccountProvider';
import classNames from 'classnames';
import PrintIcon from "@icons/printer.svg?react";
import { Tooltip } from 'react-tooltip';

interface PrintProps {
    content: ChatCompletions
}

function Print({ content }: PrintProps) {
    const { accountData } = useAccountContext();

    const markdownEnabled = (accountData?.meta_data as { markdown_copy_enabled?: boolean })?.markdown_copy_enabled;

    const handlePrint = (isJson: boolean) => {
        const json = isJson ? jsonToMarkdown(content?.chat_json?.chat_text, markdownEnabled) : "";

        const printWindow = window.open("", "PRINT", "");
        // style the body to whitespace: pre-line
        printWindow?.document.write('<html><head><title>Print</title></head><body style="white-space: pre-line;">');
        //Remove Asterisks
        const cleaned = isJson ? json.replace(/\*\*/g, "") : content?.chat_json?.chat_text.replace(/\*\*/g, "");
        printWindow?.document.write(cleaned);
        printWindow?.document.write("</body></html>");
        printWindow?.document.close(); // necessary for IE >= 10
        printWindow?.focus(); // necessary for IE >= 10*/
        printWindow?.print();
    };

    return (
        <>
            <Tooltip
                anchorSelect="#print-doc-button"
                content={
                    "Print Document"
                }
                style={{ maxWidth: "300px", zIndex: 9999, fontSize: "12px", fontFamily: "Plus Jakarta Sans" }}
            />
            <button
                id='print-doc-button'
                className={classNames(
                    "p-1 hover:bg-gray-100 hover:text-gray-700 hover:rounded-sm focused"
                )}
                onClick={() => {
                    handlePrint(content?.document_types?.is_json || false);
                }}
            >
                <PrintIcon className="h-4 w-4" />
            </button>
        </>
    )
}

export default Print