import React, { useEffect, useState, useRef } from "react";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { getProgress, setProgress } from "@/features/patient-view/state/redux/activeDocumentsSlice";

interface ProgressBarProps {
	seconds: number;
	tabId: string;
	currentProgress?: number;
	isJson?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ seconds, tabId, currentProgress = 0, isJson = false }) => {
	const dispatch = useAppDispatch();
	const progressFromRedux = useAppSelector((state: { activeDocuments: any }) => getProgress(state, tabId));
	const [progress, setProgressState] = useState(progressFromRedux);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);
	const progressRef = useRef<HTMLProgressElement | null>(null); // Ref for the progress bar

	useEffect(() => {
		if (progressFromRedux !== undefined) {
			setProgressState(progressFromRedux);
		}
	}, [progressFromRedux]);

	useEffect(() => {
		if (isJson && currentProgress !== undefined) {
			setProgressState(currentProgress);
			dispatch(setProgress({ tabId, progress: currentProgress }));
		}
	}, [currentProgress, isJson, tabId, dispatch]);

	useEffect(() => {
		if (isJson) {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
			return;
		}

		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		intervalRef.current = setInterval(() => {
			setProgressState((prevProgress) => {
				const newProgress = Math.min(prevProgress + 0.1, 100);
				dispatch(setProgress({ tabId, progress: newProgress }));
				return newProgress;
			});
		}, (seconds * 1000) / 1000);

		return () => {
			if (intervalRef.current) {
				clearInterval(intervalRef.current);
			}
		};
	}, [seconds, tabId, isJson, dispatch]);

	// Ensure the progress bar doesn't scroll into view when updated
	useEffect(() => {
		if (progressRef.current) {
			progressRef.current.focus({ preventScroll: true });
		}
	}, [progress]); // Trigger this only when progress updates

	return (
		<div className="flex items-center gap-[10px] overflow-hidden scroll-auto">
			<progress
				ref={progressRef} // Attach the ref here
				className="w-full progress progress-bar max-w-80"
				value={progress}
				max="100"
			></progress>
			<span className="text-sm text-center text-gray-500">
				{progress === 100
					? "Finalizing"
					: isJson && progress === 0
						? "Loading"
						: `${isJson ? `Generating (${progress.toFixed(0)}%)` : `${progress.toFixed(0)}%`}`}
			</span>
		</div>
	);
};

export default ProgressBar;
