import React, { ChangeEvent, useState } from 'react'
import StepComplete from '@assets/hd-icons/step-complete.svg?react'
import StepIncomplete from '@assets/hd-icons/step-incomplete.svg?react'
import StepCurrent from '@assets/hd-icons/step-current.svg?react'
import PlusIcon from '@assets/hd-icons/user-plus-01.svg?react'
import { MinusCircleIcon } from '@heroicons/react/16/solid'
import Divider from './Divider'
import { Address, Doctor, PIMSDisplay, PIMSType } from '../utils/types'
import { toast } from 'react-toastify'
import { useAccountContext } from '@/state/providers/AccountProvider'
import { updateAccountData, upsertAddress } from '../lib/supabaseClient'
import Spinner from './Spinner'
import * as Sentry from '@sentry/react'
import HDSingleSelect from './HDSingleSelect'
import { isValidEmail } from '../utils/helpers'
import { v4 as uuid } from 'uuid'
import { useAppDispatch, useAppSelector } from '@common/hooks/useRedux'
import { addNewDoctor, doctorSelected } from '@/state/redux/doctorSlice'
import classNames from 'classnames'
import { useRealtimeContextApi } from '@/state/providers/RealtimeProvider'
import { COUNTRIES, US_STATES } from '../utils/constants'

function CompleteProfileModal() {
    const { accountData, setAccountData, handlePimsTypeChange } = useAccountContext();
    const { doctors: globalDoctors } = useAppSelector((state) => state.doctors);
    const { selectedDoctorRef } = useRealtimeContextApi()
    const dispatch = useAppDispatch();
    const [step, setStep] = useState(1)
    const [loading, setLoading] = useState(false)
    const [formState, setFormState] = React.useState({
        practiceName: '',
        currentPims: '',
    })

    const [address, setAddress] = useState<Address>({
        id: uuid(),
        street_address: '',
        country: 'United States',
        city: '',
        state_region: '',
        postal_code: '',
        account_id: accountData?.id || ''
    });

    const [doctors, setDoctors] = useState([{ doctor_name: '', email: '', emailError: '' }]);

    // not complete if step 1 and missing required fields
    // or if step 2 and missing current pims or have doctors with missing fields
    const isComplete = step === 1 ? (
        formState.practiceName && address.street_address && address.country && address.state_region && address.city && address.postal_code
    ) : (
        formState.currentPims && doctors.every((doctor) => doctor.doctor_name ? doctor.email && !doctor.emailError : true)
    );

    const handleNext = () => {
        setStep(2)
    }

    const handleBack = () => {
        setStep(1)
    }

    const addDoctor = () => {
        setDoctors([...doctors, { doctor_name: '', email: '', emailError: '' }]);
    };

    const handleDoctorChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        setDoctors((doctors) =>
            doctors.map((doctor, i) => {
                if (i === index) {
                    return { ...doctor, [event.target.name]: event.target.value };
                }
                return doctor;
            })
        );
    };

    const handleDoctorEmailChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
        const email = event.target.value.trim();
        setDoctors((doctors) =>
            doctors.map((doctor, i) => {
                if (i === index) {
                    return {
                        ...doctor,
                        email,
                        emailError: !isValidEmail(email) && email !== '' ? 'Please enter a valid email address' : ''
                    };
                }
                return doctor;
            })
        );
    };

    const removeDoctor = (index: number) => {
        setDoctors(doctors.filter((_, i) => i !== index));
    };

    const onSubmit = async () => {
        if (!isComplete && accountData) {
            toast.error('Please fill in all required fields');
            return;
        }

        // set loading state
        setLoading(true);

        const clinicLocation = `${address.street_address}, ${address.city}, ${address.state_region} ${address.postal_code}`;

        try {
            // update acct
            await updateAccountData({
                id: accountData?.id,
                clinic_name: formState.practiceName,
                clinic_location: clinicLocation,
                has_completed_profile: true,
                current_pims: formState.currentPims as PIMSType
            });

            // upsert address
            await upsertAddress({ ...address, account_id: accountData?.id || "" });

            // handle pims change
            await handlePimsTypeChange(PIMSDisplay[formState.currentPims as PIMSType]);

            // make doctor create promises
            const doctorPromises = doctors.map(async (doctor) => {
                const { doctor_name, email } = doctor;
                if (!doctor_name || !email) return;

                const doctorData: Doctor = {
                    id: uuid(),
                    doctor_name: doctor_name,
                    email: email.trim(),
                    account_id: accountData?.id
                };

                return await dispatch(addNewDoctor(doctorData) as any);
            });

            // wait for all promises to resolve
            await Promise.all(doctorPromises);

            // check if there are any valid doctors before calling doctorSelected (such as from self serve), 
            // and if so select the first doctor to set as selected in state
            if (globalDoctors.length > 0) {
                dispatch(doctorSelected(globalDoctors[0]));
                selectedDoctorRef.current = globalDoctors[0]
            }

        } catch (error) {
            Sentry.captureException(error);
            toast.error('An error occurred. Please try again later.');
            setLoading(false);
            return
        }

        // update account data
        setAccountData({
            ...accountData,
            clinic_location: clinicLocation,
            clinic_name: formState.practiceName,
            current_pims: formState.currentPims,
            has_completed_profile: true
        });

        clearState();
        setLoading(false);

    }

    const clearState = () => {
        setFormState({
            practiceName: '',
            currentPims: '',
        });

        setAddress({
            ...address, // keep the id and account_id
            street_address: '',
            country: 'United States',
            city: '',
            state_region: '',
            postal_code: '',
        });

        setDoctors([{ doctor_name: '', email: '', emailError: '' }]);
    }

    if (accountData?.is_self_serve && !accountData?.has_completed_profile) {

        return (
            <dialog open={accountData?.is_self_serve && !accountData?.has_completed_profile} className="modal bg-gray-500 bg-opacity-75 px-4 md:px-10" id="completeProfile">
                <div className="relative p-4 border border-gray-200 w-full max-w-[1000px] shadow-sm rounded-md bg-white">
                    <h1 className="w-full md:w-[464px] text-gray-900 font-semibold leading-normal">
                        Complete Your Profile
                    </h1>
                    <div className="mt-3 text-zinc-500 font-normal font-['Plus Jakarta Sans'] leading-normal text-[14px]">
                        Help us better serve you.
                    </div>

                    {step === 1 ? (
                        <div className="mt-4 mb-4 space-y-2">
                            <div className='space-y-1'>
                                <label className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                    Practice Name <span style={{ color: '#2ACBD1' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    value={formState.practiceName}
                                    onChange={(e) => setFormState({ ...formState, practiceName: e.target.value })}
                                    placeholder="What is your practice name?"
                                    className="w-full p-2 border rounded-md border-gray-300 focus:border-max-700 focus:ring-2 focus:ring-max-700 focus:ring-inset"
                                />
                            </div>

                            <div className="mt-4 space-y-1">
                                <label className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                    Street Address <span style={{ color: '#2ACBD1' }}>*</span>
                                </label>
                                <input
                                    type="text"
                                    value={address.street_address}
                                    onChange={(e) => setAddress({ ...address, street_address: e.target.value })}
                                    placeholder="Street Address"
                                    className="w-full p-2 border rounded-md border-gray-300 focus:border-max-700 focus:ring-2 focus:ring-max-700 focus:ring-inset"
                                />
                            </div>

                            <div className="flex flex-col md:flex-row gap-4">
                                <div className="w-full md:w-1/2 space-y-1">
                                    <label className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                        Country <span style={{ color: '#2ACBD1' }}>*</span>
                                    </label>
                                    <HDSingleSelect
                                        options={COUNTRIES}
                                        value={address.country}
                                        useSearch
                                        onChange={(value) => setAddress({ ...address, country: value, state_region: '' })}
                                        placeholder="Select Country"
                                        id="country-select"
                                    />
                                </div>
                                <div className="w-full md:w-1/2 space-y-1">
                                    <label className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                        State/Province <span style={{ color: '#2ACBD1' }}>*</span>
                                    </label>
                                    {address.country === 'United States' ? (
                                        <HDSingleSelect
                                            options={US_STATES}
                                            value={address.state_region}
                                            onChange={(value) => setAddress({ ...address, state_region: value })}
                                            placeholder="Select State"
                                            id="state-select"
                                            useSearch
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            name='state_region'
                                            value={address.state_region}
                                            onChange={(e) => setAddress({ ...address, state_region: e.target.value })}
                                            placeholder="Enter State/Province"
                                            className="w-full p-2 border rounded-md border-gray-300 focus:border-max-700 focus:ring-2 focus:ring-max-700 focus:ring-inset"
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="flex flex-col md:flex-row gap-4">
                                <div className="w-full md:w-1/2 space-y-1">
                                    <label className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                        City <span style={{ color: '#2ACBD1' }}>*</span>
                                    </label>
                                    <input
                                        name='city'
                                        type="text"
                                        value={address.city}
                                        onChange={(e) => setAddress({ ...address, city: e.target.value })}
                                        placeholder="City"
                                        className="w-full p-2 border rounded-md border-gray-300 focus:border-max-700 focus:ring-2 focus:ring-max-700 focus:ring-inset"
                                    />
                                </div>
                                <div className="w-full md:w-1/2 space-y-1">
                                    <label className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                        Zip/Postal Code <span style={{ color: '#2ACBD1' }}>*</span>
                                    </label>
                                    <input
                                        name='postal_code'
                                        type="text"
                                        value={address.postal_code}
                                        onChange={(e) => setAddress({ ...address, postal_code: e.target.value })}
                                        placeholder="Zip/Postal Code"
                                        className="w-full p-2 border rounded-md border-gray-300 focus:border-max-700 focus:ring-2 focus:ring-max-700 focus:ring-inset"
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="mt-4 mb-4 space-y-1">
                            <label className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                Current PIMS <span style={{ color: '#2ACBD1' }}>*</span>
                            </label>
                            <HDSingleSelect
                                options={Object.values(PIMSType).map((key) => PIMSDisplay[key as PIMSType])}
                                value={PIMSDisplay[formState.currentPims as PIMSType] || ''}
                                onChange={(value) => {
                                    // find the selected pims type
                                    const selectedPimsType = Object.keys(PIMSDisplay).find(
                                        (key) => PIMSDisplay[key as PIMSType] === value
                                    ) as PIMSType

                                    setFormState({ ...formState, currentPims: selectedPimsType })
                                }}
                                placeholder="Select current PIMS"
                                id="pims-select"
                            />

                            <Divider customClassNames='py-3' />

                            <div className='mt-4 mb-4'>
                                <label htmlFor="doctors" className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                    Add Users <span className='text-xs font-light' ><span className='ml-2'>Optional</span></span>
                                </label>
                                {globalDoctors.map((doctor: Doctor, index: number) => (
                                    <div key={index} className="mt-2 flex flex-col md:flex-row items-start md:items-center gap-2">
                                        <div className="w-full md:w-1/2 space-y-1">
                                            {index === 0 && <label className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                                Name
                                            </label>}
                                            <div className='flex items-center gap-2'>
                                                <input
                                                    type="text"
                                                    readOnly
                                                    name='doctor_name'
                                                    value={doctor.doctor_name || ''}
                                                    placeholder="Name"
                                                    className={classNames("w-full p-2 border rounded-md border-gray-300 focus:border-gray-300 focus:ring-2 focus:ring-gray-300 focus:ring-inset text-gray-400 placeholder-gray-400 bg-gray-50")}
                                                />
                                            </div>
                                            <div className='h-2' />
                                        </div>
                                        <div className="w-full md:w-1/2 space-y-1">
                                            {index === 0 && <label className="text-gray-700 text-sm font-medium leading-5 font-['Plus Jakarta Sans']">
                                                Email Address
                                            </label>}
                                            <div className="space-y-1">
                                                <input
                                                    name='email'
                                                    type="email"
                                                    readOnly
                                                    value={doctor.email || ''}
                                                    placeholder="Email Address"
                                                    className={classNames("w-full p-2 border rounded-md border-gray-300 focus:border-gray-300 focus:ring-2 focus:ring-gray-300 focus:ring-inset text-gray-400 placeholder-gray-400 bg-gray-50")}
                                                />
                                            </div>
                                            <div className='h-2' />
                                        </div>
                                    </div>
                                ))}
                                {doctors.map((doctor, index) => (
                                    <div key={index} className="mt-2 flex flex-col md:flex-row items-start md:items-center gap-2">
                                        <div className="w-full md:w-1/2 space-y-1">
                                            <div className='flex items-center gap-2'>
                                                <input
                                                    type="text"
                                                    name='doctor_name'
                                                    value={doctors[index].doctor_name}
                                                    onChange={(e) => handleDoctorChange(index, e)}
                                                    placeholder="Name"
                                                    className={classNames("w-full p-2 border rounded-md border-gray-300 focus:border-max-700 focus:ring-2 focus:ring-max-700 focus:ring-inset text-gray-900")}
                                                />
                                            </div>
                                            <div className='h-2' />
                                        </div>
                                        <div className="w-full md:w-1/2 space-y-1 flex items-center  ">
                                            <div className='w-full'>
                                                <div className="space-y-1">
                                                    <input
                                                        name='email'
                                                        type="email"
                                                        value={doctors[index].email}
                                                        onChange={(e) => handleDoctorEmailChange(index, e)}
                                                        placeholder="Email Address"
                                                        className={classNames("w-full p-2 border rounded-md border-gray-300 focus:border-max-700 focus:ring-2 focus:ring-max-700 focus:ring-inset text-gray-900")}
                                                    />
                                                </div>
                                                <div className='h-3'>
                                                    {doctors[index].emailError && <p className='text-red-600 text-xs'>{doctors[index].emailError}</p>}
                                                </div>
                                            </div>

                                            {index > 0 && <button
                                                type="button"
                                                onClick={() => removeDoctor(index)}
                                                className={classNames("ml-2 text-sm text-max-700 hover:text-max-700", index === 0 ? "md:pt-4" : "md:pb-4")}
                                            >
                                                {doctors.length > 1 && (
                                                    <MinusCircleIcon className="h-5 w-5 flex-shrink-0 text-gray-900 focused" aria-hidden="true" />
                                                )}
                                            </button>}
                                        </div>


                                    </div>
                                ))}

                                <div className='w-full md:w-1/3 pt-4'>
                                    <button className="primary-button" type="button" onClick={addDoctor} >
                                        <PlusIcon className='h-4' /> Add User
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="flex items-center justify-between px-4 py-3">
                        <div className="flex items-center gap-2 text-sm text-gray-500">
                            <span>Step {step} of 2</span>
                            <div className="flex items-center gap-1">
                                {step === 1 ? (
                                    <div className="flex items-center gap-1">
                                        <StepCurrent />
                                        <StepIncomplete />
                                    </div>
                                ) : (
                                    <div className="flex items-center gap-1">
                                        <StepComplete />
                                        <StepCurrent />
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex gap-2">
                            {step === 2 && (
                                <button
                                    type="button"
                                    onClick={handleBack}
                                    className="px-3 py-2 bg-gray-200 text-gray-700 text-sm rounded-md hover:bg-gray-300 focused"
                                >
                                    Back
                                </button>
                            )}
                            <div className='flex justify-center items-center'>
                                {loading ? <Spinner size='small' /> : <button
                                    className='primary-button'
                                    type="button"
                                    onClick={step === 1 ? handleNext : onSubmit}
                                    disabled={!isComplete || loading}
                                >
                                    {step === 1 ? 'Next' : 'Complete'}
                                </button>}
                            </div>

                        </div>
                    </div>

                </div>
            </dialog>
        )
    } else {
        return null;
    }
}

export default CompleteProfileModal
