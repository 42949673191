import React, { useEffect, useRef, useCallback } from "react";
import { DisplayDocument } from "@/common/utils/types";
import { cloneDeep } from "lodash";

interface RenderInputProps {
    sectionKey: number;
    value: any;
    index: any;
    isArrayInput: boolean;
    isNormal: boolean;
    fieldIndex?: number | null;
    editedJson: DisplayDocument | null;
    handleArrayItemChange?: (sectionKey: number, fieldIndex: number | null, index: any, value: any) => void;
    handleFieldChange?: (sectionKey: number, fieldIndex: number | null, value: any) => void;
    setEditedJson: React.Dispatch<React.SetStateAction<DisplayDocument | null>>;
}

const JsonDocInput: React.FC<RenderInputProps> = ({
    sectionKey,
    value,
    index,
    isArrayInput,
    isNormal,
    fieldIndex = null,
    editedJson,
    handleArrayItemChange,
    handleFieldChange,
    setEditedJson,
}) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        if (isArrayInput && handleArrayItemChange) {
            handleArrayItemChange(sectionKey, fieldIndex, index, e.target.value);
        } else if (handleFieldChange) {
            handleFieldChange(sectionKey, fieldIndex, e.target.value);
        }
    };

    const changeFieldBold = useCallback(() => {
        if (editedJson) {
            setEditedJson((prev) => {
                if (prev && prev[sectionKey] && prev[sectionKey]?.fields) {
                    const section = prev[sectionKey];
                    let fields = section?.fields;

                    if (!Array.isArray(fields)) {
                        fields = Object.keys(fields).map(key => ({ key, ...fields[key as any] }));
                    }

                    if (fieldIndex !== null && fields && fields[fieldIndex]) {
                        const updatedJson = [
                            ...(Array.isArray(prev) ? prev.slice(0, sectionKey) : []),
                            {
                                ...section,
                                fields: [
                                    ...fields.slice(0, fieldIndex),
                                    {
                                        ...fields[fieldIndex],
                                        isNormal: !fields[fieldIndex].isNormal,
                                        isEdited: true
                                    },
                                    ...fields.slice(fieldIndex + 1),
                                ],
                            },
                            ...(Array.isArray(prev) ? prev.slice(sectionKey + 1) : []),
                        ];
                        return cloneDeep(updatedJson);
                    }
                }
                return prev;
            });
        }
    }, [editedJson, sectionKey, fieldIndex, setEditedJson]);

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        // check for control key or command key (for support on windows and mac)
        if ((e.ctrlKey || e.metaKey) && e.key === 'b') {
            e.preventDefault();
            changeFieldBold();
        }
    }, [changeFieldBold]);

    useEffect(() => {
        const input = inputRef.current;
        if (input) {
            input.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (input) {
                input.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [handleKeyDown]);

    return (
        <textarea
            ref={inputRef}
            rows={1}
            value={value}
            onChange={(e) => handleChange(e)}
            className={`text-[12px] leading-5 border border-gray-300 min-w-[300px] p-1 w-full m-1 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-max-700 focus:outline-none focus:border-max-700 ${!isNormal ? "font-bold" : "font-normal"}`}
        />
    );
};

export default JsonDocInput;
