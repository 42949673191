import { Transition, Popover, PopoverPanel, PopoverButton } from "@headlessui/react";
import classNames from "classnames";
import { useState, useCallback, Fragment, useMemo } from "react";
import { useDataContextApi } from "../../state/providers/DataProvider";
import ChevronRightIcon from "@icons/chevron-right.svg?react";
import ChevronLeftIcon from "@icons/chevron-left.svg?react";

type DisplayDirection = "up" | "down" | "right" | "left";

interface DatePickerProps {
	isDisabled?: boolean;
	displayPosition?: DisplayDirection;
}

/**
 * A date picker component that allows the user to select a date.
 * @param isDisabled - Whether the date picker is disabled.
 * @param displayPosition - The position of the date picker.
 * @returns {JSX.Element}
 */
const DatePicker: React.FC<DatePickerProps> = ({ isDisabled = false, displayPosition: displayPosition = "up" }) => {
	const { selectedDate, setSelectedDate } = useDataContextApi();
	const [currentMonth, setCurrentMonth] = useState(new Date());

	// Generate days for the calendar
	const getDaysArray = useMemo(() => {
		const startDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
		const endDay = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 0);
		const days = [];

		// Start from the beginning of the week
		startDay.setDate(startDay.getDate() - startDay.getDay());

		// Go until the end of the week of the last day of the month
		endDay.setDate(endDay.getDate() + (6 - endDay.getDay()));

		for (let day = new Date(startDay); day <= endDay; day.setDate(day.getDate() + 1)) {
			days.push(new Date(day));
		}

		return days;
	}, [currentMonth]);

	// Event handler for changing the month
	const handleChangeMonth = useCallback((increment: number) => {
		setCurrentMonth((prev) => {
			const newDate = new Date(prev.getFullYear(), prev.getMonth() + increment, 1);
			return newDate;
		});
	}, []);

	// Event handler for changing the day
	const handleChangeDay = useCallback(
		(increment: number) => {
			setSelectedDate((prev) => {
				const newDate = new Date(
					prev?.startDate?.getFullYear() as number,
					prev?.startDate?.getMonth() as number,
					(prev?.startDate?.getDate() as number) + increment,
				);
				return { startDate: newDate, endDate: newDate };
			});
		},
		[selectedDate],
	);

	// Event handler for selecting a day
	const handleSelectDay = useCallback(
		(day: Date) => {
			setSelectedDate({ startDate: day, endDate: day });
			if (selectedDate?.startDate) {
				const newCurrentMonth = new Date(day.getFullYear(), day.getMonth(), 1);
				setCurrentMonth(newCurrentMonth);
			}
		},
		[setSelectedDate],
	);

	const formatDate = (date: Date) => {
		if (!(date instanceof Date)) {
			return "";
		}
		return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
	};

	const isSameDay = useCallback((date1: Date, date2: Date) => {
		return (
			date1.getDate() === date2.getDate() &&
			date1.getMonth() === date2.getMonth() &&
			date1.getFullYear() === date2.getFullYear()
		);
	}, []);

	const isCurrentMonth = useCallback(
		(date: Date) => {
			return date.getMonth() === currentMonth.getMonth() && date.getFullYear() === currentMonth.getFullYear();
		},
		[currentMonth],
	);
	const isSelected = useCallback(
		(date: Date) => {
			return selectedDate?.startDate && isSameDay(date, selectedDate?.startDate);
		},
		[selectedDate],
	);
	const isToday = useCallback(
		(date: Date) => {
			return isSameDay(date, new Date());
		},
		[isSameDay],
	);

	function getDisplayPosition(displayPosition: string = "down") {
		switch (displayPosition) {
			case "up":
				return "bottom-10";
			case "down":
				return "top-10";
			case "right":
				return "left-10";
			case "left":
				return "right-10";
			default:
				return "bottom-10";
		}
	}

	// Event handler for going to today
	const goToToday = useCallback(() => {
		if (selectedDate?.startDate && isSameDay(selectedDate?.startDate, new Date())) {
			return;
		}

		setSelectedDate({ startDate: new Date(), endDate: new Date() });

	}, [selectedDate, setSelectedDate]);

	return (
		<div className=" w-full flex-grow text-sm font-semibold text-gray-700 flex  items-center gap-2">
			<Popover className="relative inline-block text-left flex-grow">
				{({ open }) => (
					<>
						<div className={classNames(isDisabled ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer", "group hover:bg-gray-100 bg-white relative flex flex-grow items-center rounded-md shadow-sm ring-1 ring-gray-300 ring-inset py-2 px-0.5 focus:ring-2 focus:ring-inset focus:ring-max-700 focus:outline-none ")}>
							<button
								id="date-picker-on-table"
								type="button"
								className={classNames(
									isDisabled
										? ""
										: "hover:cursor-pointer text-gray-700 font-semibold group-hover:bg-gray-100 bg-white",
									"flex px-2.5 items-center justify-center focus:ring-2 focus:ring-inset focus:ring-max-700 focus:outline-none",
								)}
								onClick={() => handleChangeDay(-1)}
								disabled={isDisabled}
							>
								<span className="sr-only">Previous day</span>
								<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
							</button>
							<PopoverButton
								as="div"
								className={classNames(
									isDisabled
										? ""
										: "hover:cursor-pointer text-gray-700 font-semibold group-hover:bg-gray-100 bg-white",
									"text-sm flex-1 w-full flex-grow font-semibold focus:ring-2 focus:ring-inset focus:ring-max-700 focus:outline-none flex items-center justify-center",
								)}
								disabled={isDisabled}
							>
								{formatDate(selectedDate?.startDate as Date)}
							</PopoverButton>

							<button
								id="date-picker-on-table"
								type="button"
								className={classNames(
									isDisabled
										? ""
										: "hover:cursor-pointer text-gray-700 font-semibold group-hover:bg-gray-100 bg-white",
									"flex px-2 items-center justify-center  focus:ring-2 focus:ring-inset focus:ring-max-700 focus:outline-none ",
								)}
								onClick={() => handleChangeDay(1)}
								disabled={isDisabled}
							>
								<span className="sr-only">Next day</span>
								<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
							</button>
						</div>

						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<PopoverPanel className={classNames(
								getDisplayPosition(displayPosition),
								"absolute right-0 z-50 mt-2 w-80 origin-top-right rounded-md bg-white shadow-sm ring-1 ring-gray-700 font-semibold ring-opacity-5 focus:ring-2 focus:ring-inset focus:ring-max-700 focus:outline-none")}>
								<div className="py-1">
									<div className="text-center my-2 mx-3">
										<div className="flex items-center text-gray-700 font-semibold">
											<button
												type="button"
												className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
												onClick={() => handleChangeMonth(-1)}
											>
												<span className="sr-only">Previous month</span>
												<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
											</button>
											<div className="flex-auto text-sm font-semibold">
												{currentMonth.toLocaleString("default", { month: "long" })} {currentMonth.getFullYear()}
											</div>

											<button
												type="button"
												className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
												onClick={() => handleChangeMonth(1)}
											>
												<span className="sr-only">Next month</span>
												<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
											</button>
										</div>
										<div className="mt-6 grid grid-cols-7 text-xs leading-6 text-gray-500">
											<div>S</div>
											<div>M</div>
											<div>T</div>
											<div>W</div>
											<div>T</div>
											<div>F</div>
											<div>S</div>
										</div>
										<div className="isolate mt-2 grid grid-cols-7 gap-px rounded-md bg-gray-200 text-sm shadow ring-1 ring-gray-200 ">
											{getDaysArray?.map((day, dayIdx) => (
												<PopoverButton
													key={day.toISOString()}
													type="button"
													className={classNames(
														"py-1.5 hover:bg-gray-100 focus:z-10",
														isCurrentMonth(day) ? "bg-white" : "bg-gray-50",
														(isSelected(day) || isToday(day)) && "font-semibold",
														isSelected(day) && "text-white",
														!isSelected(day) && isCurrentMonth(day) && !isToday(day) && "text-gray-700 font-semibold",
														!isSelected(day) && !isCurrentMonth(day) && !isToday(day) && "text-gray-400",
														isToday(day) && !isSelected(day) && "text-max-700  bg-white",
														dayIdx === 0 && "rounded-tl-lg",
														dayIdx === 6 && "rounded-tr-lg",
														dayIdx === getDaysArray?.length - 7 && "rounded-bl-lg",
														dayIdx === getDaysArray?.length - 1 && "rounded-br-lg",
													)}
													onClick={() => handleSelectDay(day)}
												>
													<time
														dateTime={day.toISOString()}
														className={classNames(
															"mx-auto flex h-7 w-7 items-center justify-center rounded-full",
															isSelected(day) && isToday(day) && "bg-max-700 ",
															isSelected(day) && !isToday(day) && "bg-gray-700 font-semibold",
														)}
													>
														{day.getDate()}
													</time>
												</PopoverButton>
											))}
										</div>
									</div>
								</div>
							</PopoverPanel>
						</Transition>
					</>
				)}
			</Popover>
			<div className="max-w-50">
				<button
					id="today"
					type="button"
					disabled={
						isDisabled
					}
					className={'secondary-button'}
					onClick={goToToday}
				>
					Today
				</button>
			</div>

		</div >

	);
};

export default DatePicker;
