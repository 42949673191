import React from "react";
import InfoCircleIcon from "@icons/info-circle.svg?react";
import { Tooltip } from "react-tooltip";

interface HelpDocumentationProps {
	id: string;
	link: string;
	displayText?: string;
	isDisabled?: boolean;
	position?: "top" | "bottom" | "left" | "right";
}

const HelpDocumentation: React.FC<HelpDocumentationProps> = ({
	id,
	link,
	displayText = "",
	isDisabled = false,
	position = "top",
}) => {
	// Function to handle click on the button
	const handleClick = () => {
		if (!isDisabled) {
			// Open the link in a new tab when the button is not disabled
			window.open(link, "_blank", "noopener,noreferrer");
		}
	};

	return (
		<div>
			<Tooltip
				content={displayText}
				anchorSelect={`#${id}`}
				style={{ maxWidth: "200px", zIndex: 9999, fontSize: "12px", fontFamily: "Plus Jakarta Sans" }}
				place={position}
			/>
			<button
				id={id}
				onClick={handleClick}
				disabled={isDisabled} // Use the native 'disabled' attribute of the button
				className={`flex flex-row text-[10px] items-center font-plus-jakarta-sans  ${isDisabled ? "text-gray-300 cursor-not-allowed" : "text-gray-500"
					}
					 p-1 focus:ring-2 focus:ring-inset focus:ring-max-700 focus:outline-none`}
				aria-label="Help Documentation"
			>
				<InfoCircleIcon className={`h-[13px] w-[13px] mr-1 mt-1.5 ${isDisabled ? "text-gray-50" : "text-gray-400"}`} />
			</button>
		</div>
	);
};

export default HelpDocumentation;
