import Spinner from "@common/components/Spinner";
import CheckIcon from "@icons/check-circle.svg?react";
import React from "react";
import { Tooltip } from "react-tooltip";
import { toolTipStyles } from '@/theme';

interface SaveButtonProps {
	isLoading: boolean;
	success: boolean;
	onClick: () => void;
	text?: string;
	tooltip?: string;
	disabled?: boolean;
	icon?: React.ReactElement;

}

function SaveButton({ isLoading, disabled = false, success, onClick, tooltip = "", text = "Save", icon }: SaveButtonProps) {
	const [showCheckIcon, setShowCheckIcon] = React.useState(false);

	React.useEffect(() => {
		let timeoutId: NodeJS.Timeout;
		if (success) {
			setShowCheckIcon(true);
			timeoutId = setTimeout(() => {
				setShowCheckIcon(false);
			}, 3000);
		}
		return () => {
			clearTimeout(timeoutId);
		};
	}, [success]);

	return (
		<div>
			<Tooltip
				content={disabled && tooltip ? tooltip : ""}
				anchorSelect="#save-button"
				style={toolTipStyles}
			/>
			<button
				id="save-button"
				className="primary-button"
				onClick={(e) => {
					e.preventDefault();
					onClick();
				}}
				disabled={disabled}
			>
				{icon ? icon : null}
				{text}
				{isLoading ? (
					<Spinner size="xsmall" color="white-white" />
				) : showCheckIcon ? (
					<CheckIcon className="h-4 w-4 inline-block color-white  transition-opacity duration-500" aria-hidden="true" />
				) : null}
			</button>
		</div>
	);
}

export default SaveButton;
