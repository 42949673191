import { RecordingState, PatientRecord } from "@common/utils/types";
import RecordingRow from "./table-row/RecordingRow";
import NotRecordingRow from "./table-row/NotRecordingRow";
import useFileUpload from "@/common/hooks/useFileUpload";

interface TableRowProps {
	patient: PatientRecord;
	index: number;
	handleEditButtonClick: (patient: any) => void;
	activeRecordingId: string | null;
	recordingState: RecordingState;
	isQuickStart: boolean;
}

function TableRow({
	patient,
	index,
	handleEditButtonClick,
	activeRecordingId,
	recordingState,
	isQuickStart,
}: TableRowProps) {
	const { uploadAudio, uploadProgress, isUploading } = useFileUpload();

	if (
		patient?.id === activeRecordingId &&
		recordingState !== RecordingState.error &&
		recordingState !== RecordingState.no_device &&
		recordingState !== RecordingState.no_permission &&
		!isQuickStart
	) {
		return <RecordingRow patient={patient} index={index} />;
	} else {
		return (
			<NotRecordingRow
				patient={patient}
				index={index}
				handleEditButtonClick={handleEditButtonClick}
				activeRecordingId={activeRecordingId}
				uploadAudio={uploadAudio}
				uploadProgress={uploadProgress}
				isUploading={isUploading}
			/>
		);
	}
}
export default TableRow;
