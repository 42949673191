import { FormattingOption } from "@/common/utils/types";
import FormatString from "./FormatString";

interface FormatListProps {
    list: string[];
    format: string;
}

/**
 * Render the value based on the format
 * @param props - the props containing list and format
 * @returns JSX.Element
 */
const FormatList: React.FC<FormatListProps> = ({ list, format }) => {
    if (list?.length === 1) {
        return (
            <ul>
                <li key={0} className="text-sm leading-4">{list[0]}</li>
            </ul>
        );
    }

    switch (format) {
        case FormattingOption.Bulleted:
            return (
                <ul>
                    {list.map((item, index) => (
                        <li key={index} className="text-sm font-normal text-gray-900 leading-5">• {item}</li>
                    ))}
                </ul>
            );
        case FormattingOption.Dashed:
            return (
                <ul>
                    {list.map((item, index) => (
                        <li key={index} className="text-sm font-normal text-gray-900 leading-5">- {item}</li>
                    ))}
                </ul>
            );
        case FormattingOption.Numbered:
            return (
                <ul>
                    {list.map((item, index) => (
                        <li key={index} className="text-sm font-normal text-gray-900 leading-5">{index + 1}. {item}</li>
                    ))}
                </ul>
            );
        case FormattingOption.FlatList:
            return (
                <ul>
                    {list.map((item, index) => (
                        <li key={index} className="text-sm font-normal text-gray-900 leading-5">{item}</li>
                    ))}
                </ul>
            );
        case FormattingOption.Paragraph:
            // join the list into a paragraph
            let paragraph = list?.join(" ");

            // render that bad boy
            return <FormatString value={paragraph} format={format} />;

        case FormattingOption.None:
            return <ul></ul>;
        default:
            return (
                <ul>
                    {list.map((item, index) => (<li key={index} className="text-sm font-normal text-gray-900 leading-5">{item}</li>))}
                </ul>
            );
    }
}

export default FormatList;

