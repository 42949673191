import LogoBlue from "../../assets/HD-Horizontal-Blue.png";
import { useSession } from "@supabase/auth-helpers-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const CheckEmailPage = () => {

	// get the user's session
	const session = useSession();

	const navigate = useNavigate();

	// if the user is already confirmed, redirect them to the home page
	useEffect(() => {
		// if the user is already confirmed, redirect them to the home page
		if (session?.user?.confirmed_at) {
			navigate("/");
		}
	}, [session, navigate]);

	// otherwise, show email verification page
	return (
		<div className="flex items-center justify-center h-screen bg-gray-100">
			<div className="">
				<img className="mx-auto h-24 w-auto" src={LogoBlue} alt="HappyDoc" />

				<h1 className="text-4xl mt-10 text-center mb-4">Verify Your Email</h1>
				<p className="text-gray-600 text-lg">
					We've sent a verification link to your email. Please click on the link to confirm your email address.
				</p>
				<p className="text-gray-600 text-lg mt-4">
					Once confirmed, you can continue your journey in the same email window. Feel free to close this tab.
				</p>
				<p className="text-gray-600 text-lg mt-4">
					<b>Can't find the email?</b> Take a peek in your spam or junk folder, just in case!
				</p>
			</div>
		</div>
	);
};

export default CheckEmailPage;
