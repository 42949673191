import { Disclosure, DisclosurePanel } from "@headlessui/react";
import classNames from "classnames";
import { useState, useEffect } from "react";
import moment from "moment";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import Spinner from "@common/components/Spinner";
import {
	getPatientData,
	updatePatientData,
	getPatientRecordFetchStatus,
	getPatientRecordFetchError,
} from "@features/patient-view/state/redux/activePatientDataSlice";
import { updatePatientRecord } from "@redux/patientTableRecordsSlice";
import { isSameDay } from "@common/utils/helpers";
import { useDataContextApi } from "@providers/DataProvider";
import PatientViewMenu from "./PatientViewMenu";
import { BroadcastEvents } from "@/common/utils/types";
import { useRealtimeContextApi } from "@/state/providers/RealtimeProvider";

const AppointmentInfo = () => {
	const dispatch = useAppDispatch();
	const { emitEvent } = useRealtimeContextApi()

	const patientData = useAppSelector(getPatientData);
	const [editPatientData, setEditPatientData] = useState(patientData);
	const [isEditing, setIsEditing] = useState(false);
	const { selectedDate } = useDataContextApi();
	const fetchStatus = useAppSelector(getPatientRecordFetchStatus);
	const errorStatus = useAppSelector(getPatientRecordFetchError);

	useEffect(() => {
		setEditPatientData(patientData); // Update local state when Redux state changes
	}, [patientData]);

	const handleSave = async () => {
		if (!editPatientData || !editPatientData.id) {
			return;
		}
		// Determine if the patient table record needs to be updated based on certain conditions
		const updateTable =
			isSameDay(new Date(editPatientData?.scheduled_at || ""), selectedDate.startDate)

		// Dispatch the action to update the patient data in the Redux store, will update in supabase as well.
		// Also update the patient table record if necessary and send emit a realtime broadcast
		await dispatch(
			updatePatientRecord({
				updatedTableRecord: { ...editPatientData },
				updateTable: updateTable,
			}),
		);

		// emit the patient event
		await emitEvent(BroadcastEvents.patientEvent, { patientRecordId: editPatientData.id });

		dispatch(updatePatientData({ ...editPatientData }));

		setIsEditing(false);
	};

	const handleCancel = () => {
		setIsEditing(false);
		setEditPatientData(patientData); // Revert changes on cancel
	};

	const handleEdit = () => {
		setIsEditing(!isEditing);
	};

	const formatDateForInput = (d: string) => {
		const date = moment(d).local();
		return date.format("YYYY-MM-DDTHH:mm");
	};

	const formatDateAndTime = (d: string) => {
		return moment(d).local().format("M/D/YYYY, h:mm A");
	};

	if (!patientData && fetchStatus === "loading")
		return (
			<div className="border-b border-gray-200 bg-white px-4 py-4 sm:px-6 shadow-sm ring-1 ring-gray-200 rounded-md flex justify-center">
				<Spinner size="small" />
			</div>
		);

	if (errorStatus) return <></>;

	if (!patientData) return <></>;

	return (
		<Disclosure defaultOpen={true}>
			{({ open }) => (

				<div className="border-b border-gray-200 bg-white p-4 shadow-sm ring-1 ring-gray-200  rounded-md">
					<DisclosurePanel>
						{isEditing ? (
							<div className="flex justify-between items-center flex-wrap ">
								<div className="flex gap-7 items-center flex-wrap" >
									<div className="flex flex-col justify-center ">
										<label htmlFor="scheduled-time" className="pl-1 text-sm font-normal text-gray-900">
											APPOINTMENT TIME
										</label>
										<input
											type="datetime-local"
											id="scheduled-time"
											name="scheduled-time"
											value={formatDateForInput(editPatientData?.scheduled_at || new Date().toISOString())}
											onChange={(e) => setEditPatientData({ ...editPatientData, scheduled_at: moment(e.target.value).utc().toISOString(), id: patientData?.id })}
											onClick={(e) => {
												(e.target as any).showPicker();
											}}
											className="mt-1 max-w-[250px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
										/>
									</div>
									<div className="flex flex-col justify-center ">
										<label htmlFor="patient-id" className=" pl-1  text-sm font-normal  text-gray-900">
											PATIENT ID
										</label>
										{/* if you're coming from an integration you can't edit the patient ID - HDD-352 */}
										{/* if the integration is ezyvet, show the secondary_id (the code) instead of what the API gives us as the ID */}
										{patientData?.account_integrations != null ?
											<input
												type="text"
												readOnly={true}
												value={(patientData?.account_integrations?.integration_name == "ezyvet" && patientData.patients?.secondary_id
													? patientData?.patients?.secondary_id
													: patientData?.patient_id || "")}
												className="mt-1  max-w-[250px] text-gray-400 placeholder:text-gray-400 w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700  text-xs leading-tight sm:text-sm sm:leading-6"
											/>
											: editPatientData ?
												<input
													type="text"
													name="patient-id"
													id="patient-id"
													value={editPatientData?.patient_id || ""}
													onChange={(e) => setEditPatientData({ ...editPatientData, patient_id: e.target.value, id: patientData?.id })}
													className=" mt-1 max-w-[250px] w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
												/>
												: <></>
										}
									</div>
								</div>
								<div className="flex  w-auto justify-end items-end h-full mt-7 gap-2  ">
									<button
										type="button"
										className="secondary-button"
										onClick={handleCancel}
									>
										Cancel
									</button>
									<button
										type="button"
										className="primary-button"
										onClick={() => {
											handleSave();
										}}
									>
										Save
									</button>
								</div>
							</div>
						) : (
							<div className="flex justify-between items-center ">
								<div className="flex gap-10 items-center" >
									<div className="">
										<label htmlFor="scheduled-time" className=" text-xs font-normal  text-gray-900">
											APPOINTMENT TIME
										</label>
										<div className="text-sm text-gray-900 font-semibold">{formatDateAndTime(patientData?.scheduled_at || "")}</div>
									</div>
									<div className=" ">
										<label htmlFor="patient-id" className=" text-xs font-normal  text-gray-900">
											PATIENT ID
										</label>
										<div className={classNames(patientData?.patient_id ? "text-sm text-gray-900 font-semibold" : "text-gray-400 text-sm")}>
											{
												(patientData?.account_integrations?.integration_name == "ezyvet" && patientData?.patients?.secondary_id
													? patientData?.patients?.secondary_id
													: patientData?.patient_id) || "(blank)"
											}
										</div>
									</div>
								</div>
								<div className="flex justify-end">
									{!isEditing && (
										<PatientViewMenu patientRecordId={patientData?.id} handleEdit={handleEdit} />
									)}

								</div>

							</div>
						)}
					</DisclosurePanel>
				</div>
			)}
		</Disclosure>
	);
};

export default AppointmentInfo;
