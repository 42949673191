import classNames from "classnames";
import { toast } from "react-toastify";
import { useAccountContext } from "@providers/AccountProvider";
import { newIntegrationRequestSlack } from "@common/lib/api";
import { useSession } from "@supabase/auth-helpers-react";
import PackagePlusIcon from "@icons/package-plus.svg?react";
import * as Sentry from "@sentry/react";
import { IntegrationProvider, IntegrationThirdParty } from "./Integrations";

const statuses = {
	Connected: "text-green-700 bg-green-50 ring-green-600/20",
	Pending: "text-gray-600 bg-gray-50 ring-gray-500/10",
	Error: "text-red-700 bg-red-50 ring-red-600/10",
};

/**
 * Cards component for integrations
 * @param integrations 
 * @returns JSX.Element
 */
const Cards = ({ integration }: any) => {
	const { accountData } = useAccountContext();
	const session = useSession();

	/**
	 * Handle request install
	 * @param integrationName
	 */
	const handleRequestInstall = async (integrationName: string) => {
		if (!session) {
			Sentry.captureMessage("Session not found in integrations/Card.tsx", {
				level: "error",
			});
			return;
		}

		// slack notification
		await newIntegrationRequestSlack(session, session?.user?.email, session?.user?.id || accountData?.id, integrationName);

		toast.success("Thanks for requesting. Reach out to us over Intercom or email so we can get you set up.");
	};

	return (
		<div key={integration.id}>
			<div key={integration.id} className="overflow-hidden rounded-xl border border-gray-200 shadow-sm ">
				<div className="flex items-center gap-x-4 bg-white p-6 flex-wrap gap-5 ">
					<img
						src={integration.imageUrl}
						alt={integration.name}
						className={classNames(
							integration?.disabled && "opacity-50",
							"h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-300",
						)}
					/>
					<div
						className={classNames(
							integration?.disabled ? "text-gray-400" : "text-gray-900",
							"text-md font-semibold leading-6 ",
						)}
					>
						{integration.name}
					</div>
					{integration?.disabled ? (
						<div className="ml-auto">
							<span className="inline-flex items-center rounded-md bg-white rounded-md px-4 py-2 text-sm font-medium text-gray-500 ring-1 ring-inset ring-gray-300 whitespace-nowrap">
								Coming Soon
							</span>
						</div>
					) : integration?.connected ? (
						<div
							className={classNames(
								statuses.Connected,
								"ml-auto rounded-md px-4 py-2 text-sm font-medium ring-1 ring-inset",
							)}
						>
							Connected
						</div>
					) : (
						<div className="ml-auto">
							{!integration?.requested && (
								<button
									type="button"
									className="secondary-button"
									onClick={() => handleRequestInstall(integration.name)}
								>
									<PackagePlusIcon className="h-5 w-5 text-gray-700" />
									Request
								</button>
							)}
						</div>
					)}
				</div>
				{!integration?.disabled && integration?.connected && (
					<>
						{integration?.provider === IntegrationThirdParty.AVImark ||
							integration?.provider === IntegrationThirdParty.Cornerstone ||
							integration?.provider === IntegrationThirdParty.ImproMed ? (
							<dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6 bg-white">
								<div className="flex justify-between gap-x-4 py-3">
									<dt className="text-gray-500">Last Request</dt>
									<dd className="text-gray-700 text-[10px]">
										<time dateTime={integration?.lastRequest}>
											{integration?.lastRequest ? new Date(integration?.lastRequest).toLocaleString() : "-"}
										</time>
									</dd>
								</div>
								<div className="flex justify-between gap-x-4 py-3 items-center">
									<dt className="text-gray-500">Practice ID</dt>
									<dd className="text-gray-700 text-[10px]">
										<div className="">{integration?.practiceId ? integration?.practiceId : "-"}</div>
									</dd>
								</div>
							</dl>
						) : (
							integration?.provider === IntegrationProvider.VetSpire && (
								<dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6 bg-white">
									<div className="flex justify-between gap-x-4 py-3">
										<dt className="text-gray-500">Last Request</dt>
										<dd className="text-gray-700 text-[10px]">
											<time dateTime={integration?.lastRequest}>
												{integration?.lastRequest ? new Date(integration?.lastRequest).toLocaleString() : "-"}
											</time>
										</dd>
									</div>
									<div className="flex justify-between gap-x-4 py-3 items-center">
										<dt className="text-gray-500">API URL</dt>
										<dd className="text-gray-700 text-[10px]">
											<div className="">{integration?.apiUrl ? integration?.apiUrl : "-"}</div>
										</dd>
									</div>
								</dl>
							)
						)}
					</>
				)}
			</div>
		</div>
	);
};


export default Cards;
