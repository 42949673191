import { useRef, useState, useEffect, useCallback, Fragment } from "react";
import { deleteTranscriptSection } from "@common/lib/supabaseClient";
import { TranscriptSection } from "@common/utils/types";
import ConfirmModal from "@common/components/ConfirmModal";
import TranscriptionControlPanel from "./TranscriptionControlPanel";
import TranscriptLine from "./TranscriptionLine";
import { Transition, Dialog, DialogPanel, TransitionChild } from "@headlessui/react";
import AudioDb, { INDEX_DB_VERSION } from "@common/classes/audioDb";
import { useAppDispatch } from "@/common/hooks/useRedux";
import { removeTranscriptSections } from "../../state/redux/activePatientDataSlice";
import { decrementRecordingCount } from "@redux/patientTableRecordsSlice";
import { toast } from "react-toastify";
import XIcon from "@icons/x-close.svg?react";
import MoveRecordingModal from "../sidebar/MoveRecordingModal";

const TranscriptionModal = ({
	patientRecordId,
	transcriptionId,
	transcriptSections,
	selectedTranscriptSectionId,
	setSelectedTranscriptSectionId,
	isOpen,
	setIsOpen,
	documentCreationTime,
}: {
	patientRecordId: string;
	transcriptionId: string;
	selectedTranscriptSectionId: string | null;
	transcriptSections: TranscriptSection[];
	setSelectedTranscriptSectionId: React.Dispatch<React.SetStateAction<string | null>>;
	isOpen: boolean;
	setIsOpen: (x: boolean) => void;
	documentCreationTime: Date | null;
}) => {
	const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);
	const dispatch = useAppDispatch();

	const bottomListRef = useRef<HTMLDivElement | null>(null);
	const [prevSectionsLength, setPrevSectionsLength] = useState(0);
	const [prevSectionLastItemLoading, setPrevSectionLastItemLoading] = useState(false);

	const [transcriptToDelete, setTranscriptToDelete] = useState("");
	const [transcriptSectionToMove, setTranscriptSectionToMove] = useState<TranscriptSection>();

	const [isDeleteOneModalOpen, setIsDeleteOneModalOpen] = useState(false);
	const [moveRecordingModalOpen, setMoveRecordingModalOpen] = useState(false);

	//Disable for recording.
	const [isRecording, setIsRecording] = useState(false);

	// 1. HOOKS
	useEffect(() => {
		// if there are new sections, scroll to bottom
		if (transcriptSections?.length > prevSectionsLength || prevSectionLastItemLoading) {
			bottomListRef.current?.scrollIntoView({ behavior: "smooth" });
		}
		if (transcriptSections.slice(-1)[0]?.data === "loading" && !prevSectionLastItemLoading) {
			bottomListRef.current?.scrollIntoView({ behavior: "smooth" });
		}

		setPrevSectionLastItemLoading(transcriptSections.slice(-1)[0]?.data === "loading" || false);
		setPrevSectionsLength(transcriptSections?.length || 0);
	}, [transcriptSections, transcriptSections.slice(-1)[0]?.data === "loading"]);

	// 2. FUNCTIONS
	//Removes and deletes a all a transcript section
	const removeAndDeleteTranscriptSection = useCallback(async () => {
		dispatch(removeTranscriptSections([transcriptToDelete]));
		dispatch(decrementRecordingCount({ transcriptionId: transcriptionId, sectionIds: [transcriptToDelete] }));
		await localAudioDb?.deleteTranscriptSectionAudio(transcriptionId, transcriptToDelete);
		await deleteTranscriptSection(transcriptToDelete);
	}, [transcriptToDelete]);

	const handleClose = () => {
		if (isDeleteOneModalOpen) return;
		if (isRecording) {
			toast.info("Please stop recording before closing.");
			return;
		}
		setIsOpen(false);
		setSelectedTranscriptSectionId(null);
	}

	return (
		<>
			<Transition show={isOpen} as={Fragment}>
				<Dialog
					as="div"
					className="relative z-40"
					onClose={handleClose}
				>
					<TransitionChild
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
					</TransitionChild>

					<div className="fixed inset-0 z-40 overflow-y-auto">
						<div className="flex min-h-full items-center justify-center text-center p-6 ">
							<TransitionChild
								as={Fragment}
								enter="ease-out duration-300"
								enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enterTo="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leaveFrom="opacity-100 translate-y-0 sm:scale-100"
								leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<DialogPanel className="relative  transform overflow-hidden rounded-md bg-white text-left shadow-sm transition-all  w-full max-w-2xl md:max-w-5xl">
									<div className="w-full p-6 bg-white flex items-center justify-between border-b border-gray-200 ">
										<p className="text-lg text-gray-900 lg:text-xl font-semibold  whitespace-nowrap">
											Transcripts
										</p>
										<button
											type="button"
											className="focused inline-flex justify-center rounded-md border border-transparent bg-transparent p-2 text-sm font-medium text-neutral-900  focus:outline-none focus-visible:ring-2 focus-visible:ring-neutral-500 focus-visible:ring-offset-2"
											onClick={handleClose}
										>
											<XIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
										</button>

									</div>

									<div className="flex flex-col bg-white ">
										<div className="w-full max-h-[calc(100vh-15rem)]  overflow-auto  ">
											{transcriptSections?.length === 0 ? (
												<div className="text-center">
													<span>No transcripts</span>
												</div>
											) : (
												transcriptSections.map((item: TranscriptSection, index: number) => (
													<TranscriptLine
														key={item?.id}
														transcriptSection={item}
														isSelected={item?.id === selectedTranscriptSectionId}
														documentCreationTime={documentCreationTime}
														onDelete={() => {
															setTranscriptToDelete(item?.id as string);
															setIsDeleteOneModalOpen(true);
														}}
														onMove={() => {
															setTranscriptSectionToMove(item);
															setMoveRecordingModalOpen(true);
														}}
													/>
												))
											)}
											<div ref={bottomListRef} />
										</div>
									</div>

									<div className="pb-6 px-6 py-4">
										<TranscriptionControlPanel
											transcriptionId={transcriptionId}
											setIsRecording={setIsRecording}
										/>
									</div>
								</DialogPanel>
							</TransitionChild>
						</div>
					</div>
				</Dialog>
			</Transition>
			<MoveRecordingModal
				patientId={patientRecordId}
				transcriptionId={transcriptionId}
				transcriptSection={transcriptSectionToMove!}
				isOpen={moveRecordingModalOpen}
				onCancel={() => setMoveRecordingModalOpen(false)}
				onConfirm={() => {
					setMoveRecordingModalOpen(false);
				}}
			/>
			<ConfirmModal
				id="DeleteOneTranscript"
				headerText="Are you sure you want to delete this clip?"
				cancelButtonText="Cancel"
				confirmButtonText="Delete"
				confirmButtonColor="bg-red-600"
				confirmHoverButtonColor="bg-red-500"
				onCancel={() => {
					setIsDeleteOneModalOpen(false);
				}}
				onConfirm={() => {
					removeAndDeleteTranscriptSection();
					setTranscriptToDelete("");
					setIsDeleteOneModalOpen(false);
				}}
				isOpen={isDeleteOneModalOpen}
			/>
		</>
	);
};
export default TranscriptionModal;
