import { addDoctorToSupabase, addSelectedDocumentForDoctor, deleteDoctorInSupabase, getSupabaseDoctorById, getSupabaseDoctors } from "@common/lib/supabaseClient";
import { Doctor } from "@common/utils/types";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { HAPPY_SOAP } from "@/common/utils/constants";
import { documentSelected } from "./documentSlice";
import { RootState } from "@/app/store";
import { sendValidateDoctorEmail } from "@/common/lib/api";

type status = "idle" | "loading" | "succeeded" | "failed";

/**
 * Get the selected doctor from local storage
 * @returns {Doctor | null}
 * */
const getLocalDoctor = () => {
	const doctor = localStorage.getItem("selectedDoctor");
	if (doctor) {
		return JSON.parse(doctor);
	}
	return null;
};

const initialState: {
	doctors: Doctor[];
	selectedDoctor: Doctor | null;
	status: status;
	error: string | null;
} = {
	doctors: [],
	selectedDoctor: getLocalDoctor(),
	status: "idle",
	error: null,
};

/**
 * Fetch all the doctors from the db and set the selected doctor and selected documents
 * */
export const fetchDoctors = createAsyncThunk(
	"doctors/fetchDoctors",
	async (abortSignal: AbortSignal, { rejectWithValue, getState, dispatch }) => {
		try {
			// get the doctors from the db
			const doctors = await getSupabaseDoctors(abortSignal);

			if (doctors.length === 0) {
				return [];
			}

			// get the selected doctor from the state
			let selectedDoctor = (getState() as { doctors: typeof initialState }).doctors.selectedDoctor;

			// if there is only one doctor, default selected documents to this doctor's selected
			if (doctors.length === 1 && !selectedDoctor) {
				selectedDoctor = doctors[0];
			}

			// get the selected doctor from the fetched doctors 
			const doctor = doctors.find((doc) => doc.id === selectedDoctor?.id);

			// set the selected doctor's documents in state
			const documentIds = doctor?.doctor_document_types?.map((doc) => doc.document_type_id);
			if (documentIds && documentIds.length > 0) {
				dispatch(documentSelected(documentIds));
			}

			return doctors;
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

/**
 * Add a new doctor to the state, db. Also add the default document for the doctor
 * @param doctor - Doctor object
 * */
export const addNewDoctor = createAsyncThunk(
	"doctors/addNewDoctor",
	async (doctor: Doctor, { dispatch, rejectWithValue }) => {
		try {
			// add the doctor to the db
			const doctorId = await addDoctorToSupabase(doctor);

			if (doctorId) {
				// Add the default document for the doctor. Default document is the Happy SOAP for now
				await addSelectedDocumentForDoctor(doctorId, HAPPY_SOAP);

				// send validate email
				if (doctor.email) {
					await sendValidateDoctorEmail(doctorId, doctor.email);
					doctor.confirmation_sent_at = new Date().toISOString();
				}

			}
			// add the doctor to the state
			dispatch(doctorAdded(doctor));


		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const deleteDoctor = createAsyncThunk(
	"doctors/deleteDoctor",
	async (doctorId: string, { dispatch, rejectWithValue }) => {
		try {
			await deleteDoctorInSupabase(doctorId);
			dispatch(doctorRemoved(doctorId));
		} catch (error: any) {
			return rejectWithValue(error.response.data);
		}
	},
);

/**
 * Switch the selected doctor and update the selected documents that are saved for the doctor
 * @param doctorId - The doctor id
 * */
export const switchDoctor = createAsyncThunk(
	"doctors/switchDoctor",
	async (doctorId: string | null, { dispatch, getState }) => {

		// get the selected documents
		const state = getState() as RootState;
		let selectedDocuments = state.documents.selectedDocuments;

		// null doctorId means all doctors. Set the selected documents to whatever's in the state
		if (!doctorId) {
			dispatch(doctorSelected(null));
			dispatch(documentSelected(selectedDocuments));
			return;
		}

		// get the doctor from supa
		const doctor = await getSupabaseDoctorById(doctorId);

		// set the selected doctor state
		dispatch(doctorSelected(doctor));

		// get the selected documents for the doctor and set the state
		const documentIds = doctor?.doctor_document_types?.map((doc) => doc.document_type_id);
		if (documentIds && documentIds.length > 0) {
			dispatch(documentSelected(documentIds));
		} else {
			// if there are no documents for the doctor, set the selected documents to whatever's in state
			dispatch(documentSelected(selectedDocuments));
		}
	},
);

const doctorsSlice = createSlice({
	name: "doctors",
	initialState,
	reducers: {
		doctorAdded(state, action) {
			const doctor = state.doctors.find((doc) => doc.id === action.payload.id);
			if (!doctor) state.doctors.push(action.payload);
		},
		doctorRemoved(state, action) {
			state.doctors = state.doctors.filter((doc) => doc.id !== action.payload);
			// remove from local storage if the selected doctor is deleted
			if (state.selectedDoctor?.id === action.payload) {
				state.selectedDoctor = null;
				localStorage.removeItem("selectedDoctor");
			}
		},
		doctorSelected(state, action) {
			state.selectedDoctor = action.payload;
			localStorage.setItem("selectedDoctor", JSON.stringify(action.payload));
		},
		setDoctors(state, action) {
			state.doctors = action.payload;
		},
		doctorUpdated(state, action) {
			const doctor = state.doctors.find((doc) => doc.id === action.payload.id);
			if (doctor) {
				const index = state.doctors.indexOf(doctor);
				state.doctors[index] = {
					...doctor,
					...action.payload,
				};
			}
		},
		clearDoctorState(state) {
			state.doctors = [];
			state.selectedDoctor = null;
			localStorage.removeItem("selectedDoctor");
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchDoctors.pending, (state) => {
				state.status = "loading";
			})
			.addCase(fetchDoctors.fulfilled, (state, action) => {
				state.status = "succeeded";
				state.doctors = action.payload;
			})
			.addCase(fetchDoctors.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error?.message || null;
			})
	},
});

export const { doctorAdded, doctorRemoved, setDoctors, doctorUpdated, doctorSelected, clearDoctorState } = doctorsSlice.actions;

export default doctorsSlice.reducer;
