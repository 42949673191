import React from "react";
import ReactDOM from "react-dom/client";
import App from "./app/app.tsx";
import "./app/index.css";
import "react-toastify/dist/ReactToastify.css";
import 'react-tooltip/dist/react-tooltip.css'
import { SessionContextProvider } from "@supabase/auth-helpers-react";
import { supabase } from "./common/lib/supabaseClient.ts";
import { DataContextProvider } from "./state/providers/DataProvider.tsx";
import { IntercomManager } from "./common/lib/Intercom.tsx";
import { RealTimeContextProvider } from "./state/providers/RealtimeProvider.tsx";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from "react-router-dom";
import store from "./app/store.ts";
import { Provider } from "react-redux";
import { AccountProvider } from "@providers/AccountProvider.tsx";
import { VITE_PUBLIC_ENV } from "@common/utils/constants";

const isLiveEnv = VITE_PUBLIC_ENV && (VITE_PUBLIC_ENV === "production" || VITE_PUBLIC_ENV === "preview")

try {
	isLiveEnv &&
		Sentry.init({
			dsn: "https://6a322cf643e49d5bd1d053b710a8aae5@o4506666677239808.ingest.sentry.io/4506667695931392",
			replaysSessionSampleRate: isLiveEnv ? 0.1 : 0, // 10% of sessions
			replaysOnErrorSampleRate: isLiveEnv ? 1.0 : 0, // 100% of sessions
			//debug: true,
			integrations: [
				Sentry.reactRouterV6BrowserTracingIntegration({
					useEffect: React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes,
				}),
				Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false }),
				Sentry.captureConsoleIntegration(),
			],
			environment: VITE_PUBLIC_ENV,

			// Set tracesSampleRate to 1.0 to capture 100%
			// of transactions for performance monitoring.
			tracesSampleRate: 1.0,

			// Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
			tracePropagationTargets:
				isLiveEnv
					? [
						/^https:\/\/service.gohappydoc.com\/api-server/,
						/^https:\/\/service.gohappydoc.com\/service-transcribe/,
						/^https:\/\/kezqvoudwepwufpjcjkk.supabase.co/,
						/^https:\/\/supa.happydoc.ai/,
					]
					: [
						"localhost",
						/^https:\/\/test.gohappydoc.com\/api-server/,
						/^https:\/\/test.gohappydoc.com\/service-transcribe/,
						/^https:\/\/ntelvrlfzbgggnlgnzup.supabase.co/,
					],
		});
} catch (e) {
	console.error("Error initializing Sentry", e);
}

ReactDOM.createRoot(document.getElementById("root")!).render(
	<React.StrictMode>
		<Provider store={store}>
			<SessionContextProvider supabaseClient={supabase}>
				<IntercomManager />
				<AccountProvider>
					<DataContextProvider>
						<RealTimeContextProvider>
							<div className="min-h-screen bg-gray-100 text-gray-900 overflow-hidden">
								<App />
							</div>
						</RealTimeContextProvider>
					</DataContextProvider>
				</AccountProvider>
				<ToastContainer />
			</SessionContextProvider>
		</Provider>
	</React.StrictMode>,
);
