import QuickStart from "./components/quickstart/QuickStart";
import Table from "./components/patient-table/PatientTable";
import { AudioRecorderProvider } from "./state/recordingProvider";
import Controls from "./components/Controls";
import { switchDoctor } from "@/state/redux/doctorSlice";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { useRealtimeContextApi } from "@/state/providers/RealtimeProvider";

function PatientListView() {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { doctors } = useAppSelector((state) => state.doctors)
	const { selectedDoctorRef } = useRealtimeContextApi()

	// look for ?doctorId in the URL, and switch the doctor if it exists
	useEffect(() => {
		const params = new URLSearchParams(location.search);
		const doctorId = params.get('doctorId');

		if (doctorId && doctorId !== '') {
			// switch doctors
			dispatch(switchDoctor(doctorId));

			// find doctor 
			const doctor = doctors.find((doc) => doc?.id === doctorId)
			// set ref
			if (doctor) selectedDoctorRef.current = doctor

			// remove the doctorId from the URL
			params.delete('doctorId');
			navigate({ search: params.toString() }, { replace: true });
		}
	}, [location.search, dispatch, navigate]);

	return (
		<div className="pt-14 pb-14">
			<AudioRecorderProvider>
				<div className="mt-2 flex flex-col-reverse md:flex-row gap-4 items-start justify-evenly ">
					<div className="w-full md:w-1/3">
						<Controls />
					</div>

					<QuickStart />


				</div>

				<div className="mt-4">
					<Table />
				</div>
			</AudioRecorderProvider>
		</div>
	);
}

export default PatientListView;
