import { Integration } from './Integrations'
import classNames from 'classnames'
import CheckMarkIcon from "@icons/check-verified-02.svg?react";
import Divider from '@/common/components/Divider';

const ConnectedIntegration = ({ integration }: { integration: Integration }) => {
    return (
        <div className="w-full h-full border border-gray-200 rounded-xl flex flex-col gap-6 p-6 items-between ">
            <div className="flex justify-between items-center flex-wrap md:flex-nowrap gap-5">
                <div className="flex items-center gap-x-4 bg-white  ">
                    <img
                        src={integration.imageUrl}
                        alt={integration.name}
                        className={classNames(
                            integration?.disabled && "opacity-50",
                            "h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-300",
                        )}
                    />
                    <div
                        className={classNames(
                            integration?.disabled ? "text-gray-400" : "text-gray-900",
                            "text-md font-semibold leading-6 ",
                        )}
                    >
                        {integration.name}
                    </div>
                </div>
                <div className="flex px-3 py-2 justify-center items-center bg-white gap-2 border border-max-300 shadow-sm rounded-md text-max-800 ">
                    <CheckMarkIcon className="h-5 w-5 " />
                    <span className="text-sm text-max-800 font-semibold">Connected</span>
                </div>
            </div>
            <div className="flex items-center justify-between bg-white  ">
                <span className="text-sm font-normal leading-6 text-gray-600">
                    {"Last Request"}
                </span>
                <div className="text-sm font-normal leading-6 text-gray-600">
                    {integration?.lastRequest ? new Date(integration.lastRequest).toLocaleString() : "-"}
                </div>
            </div>
            <div className="w-full border border-b-gray-200" />

            <div className="flex items-center justify-between bg-white flex-wrap  ">
                <span className="text-sm font-normal leading-6 text-gray-600">
                    {"Practice ID"}
                </span>
                <div className="text-sm font-normal leading-6 text-max-800">
                    {integration.practiceId}
                </div>
            </div>
        </div>
    )
}

export default ConnectedIntegration