import ConfirmModal from '@/common/components/ConfirmModal';
import Spinner from '@/common/components/Spinner';
import { exportCornerstoneRecord } from '@/common/lib/api';
import { ChatCompletions } from '@/common/utils/types';
import { getPatientData } from '@/features/patient-view/state/redux/activePatientDataSlice';
import { IntegrationProvider, IntegrationThirdParty } from '@/features/settings/components/integrations/Integrations';
import { useAccountContext } from '@/state/providers/AccountProvider';
import ShareIcon from "@icons/share-05.svg?react";
import { useSession } from '@supabase/auth-helpers-react';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react'
import { useAppSelector } from "@/common/hooks/useRedux";
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

interface ExportProps {
    content: ChatCompletions
}

function Export({ content }: ExportProps) {
    const session = useSession();
    const { accountData, containsFeatureFlag } = useAccountContext();

    const patientData = useAppSelector(getPatientData);

    const [isExporting, setIsExporting] = useState(false);
    const [exportConfirmModalOpen, setExportConfirmModalOpen] = useState(false);

    const exportVisible = useMemo(() => containsFeatureFlag("EXPORT_CORNERSTONE") &&
        patientData?.linked_by_integration &&
        content?.document_types?.is_json &&
        content?.document_types?.document_exports?.is_active
        , [containsFeatureFlag, patientData, content]);

    /**
     * Determines if the export functionality is enabled based on the patient's account integrations and doctors' information.
     *
     * @returns {boolean} - Returns `true` if the export is enabled, otherwise `false`.
     *
     * The export is enabled if:
     * - The patient has account integrations and doctors information.
     * - The integration name is "bitwerx" (case-insensitive).
     * - The connected third party is "cornerstone" (case-insensitive).
     * - Either the doctor's PMS export ID or PMS employee ID ends with "|provider" (case-insensitive).
     */
    const exportEnabled = useCallback(() => {
        if (!patientData?.account_integrations ||
            !patientData?.account_integrations?.integrations ||
            !patientData?.doctors) {
            return false;
        }

        if (patientData.account_integrations.is_active == true &&
            patientData.account_integrations.integrations.integration_name == IntegrationProvider.Bitwerx &&
            patientData.account_integrations.connected_third_party == IntegrationThirdParty.Cornerstone &&
            ((patientData.doctors.pms_export_id && patientData.doctors.pms_export_id.toLowerCase().endsWith("|provider")) ||
                patientData.doctors.pms_employee_id?.toLowerCase().endsWith("|provider"))) {
            return true;
        }
        else {
            if (!patientData.account_integrations.is_active) {
                console.log("Export Disabled: Account integration is not active")
            }
            if (patientData.account_integrations.integrations.integration_name != IntegrationProvider.Bitwerx) {
                console.log("Export Disabled: Integration name is not bitwerx")
            }
            if (patientData.account_integrations.connected_third_party != IntegrationThirdParty.Cornerstone) {
                console.log("Export Disabled: Connected third party is not cornerstone")
            }
            if (!patientData.doctors.pms_export_id) {
                console.log("Export Disabled: Doctor's PMS export ID is not available")
            }
            if (!patientData.doctors.pms_employee_id) {
                console.log("Export Disabled: Doctor's PMS employee ID is not available")
            }
            if (!patientData.doctors.pms_export_id?.toLowerCase().endsWith("|provider") &&
                !patientData.doctors.pms_employee_id?.toLowerCase().endsWith("|provider")) {
                console.log("Export Disabled: Doctor's PMS export ID or PMS employee ID does not end with '|provider'")
            }
        }

        return false;
    }, [patientData]);

    const handleExport = async () => {
        if (!session) {

            return;
        }

        setIsExporting(true);

        const resp = await exportCornerstoneRecord(session, accountData?.id!, content?.id!);
        if (!resp || resp.error) {
            setIsExporting(false);
            toast.error("Failed to export document!");
            return;
        }

        setIsExporting(false);
        toast.success("Document exported successfully!");
    };

    if (exportVisible) {
        return (
            <>
                <Tooltip
                    anchorSelect="#export-doc-button"
                    content={exportEnabled() ? "Export Document" : "Please validate Doctor settings"}
                    style={{ maxWidth: "300px", zIndex: 9999, fontSize: "12px", fontFamily: "Plus Jakarta Sans" }}
                />
                <button
                    id="export-doc-button"
                    type="button"
                    disabled={!exportEnabled()}
                    className={classNames(!exportEnabled() ? "text-gray-400 " : " text-gray-700 hover:bg-gray-100 hover:rounded-sm", "p-1 focused")}
                    onClick={() => setExportConfirmModalOpen(true)}
                >
                    {isExporting ? (
                        <Spinner size="xsmall" color="text-gray-700" />
                    ) : (
                        <ShareIcon className="h-4 w-4" />
                    )}
                </button>
                {exportConfirmModalOpen && (
                    <ConfirmModal
                        isOpen={exportConfirmModalOpen}
                        id="confirm-export-modal"
                        headerText="Export Document"
                        cancelButtonText="Cancel"
                        confirmButtonText="Export"
                        confirmButtonColor="bg-max-700"
                        confirmHoverButtonColor="bg-max-800"
                        contentText="Are you sure you want to export this document?"
                        onConfirm={() => {
                            handleExport();
                            setExportConfirmModalOpen(false);
                        }}
                        onCancel={() => setExportConfirmModalOpen(false)}
                    />
                )}
            </>
        )
    }

    return (
        null
    )
}

export default Export