import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AppointmentInfo from "./components/header/PatientInfo";
import GenerateDocuments from "./components/sidebar/GenerateDocuments";
import Recordings from "./components/sidebar/Recordings";
import DocumentTabs from "./components/documents/DocumentTabs";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import Spinner from "@common/components/Spinner";
import useGenerateDocuments from "./state/useGenerateDocuments";
import { getPatientStage, getTranscriptSections, resetActivePatientData } from "./state/redux/activePatientDataSlice";
import { resetActiveDocuments } from "./state/redux/activeDocumentsSlice";
import { FETCH_ERROR_REFRESH, NO_PATIENT_RECORD, PatientStage, TableTab, TranscriptSection } from "@common/utils/types";
import {
	getPatientRecordFetchStatus,
	getPatientRecordFetchError,
	fetchPatientData,
} from "./state/redux/activePatientDataSlice";
import { isUUID } from "validator";
import { ActivePatientStatus } from "@/common/utils/types";
import HDBackButton from "@/common/components/HDBackButton";
import StageSelect from "@/common/components/StageSelect";
import TableTabs from "@/common/components/HDTabs";
import Visit from "./components/header/Visit";
import { determineErrorState } from "@/common/utils/helpers";

/**
 * Component for the patient view page
 * @returns JSX.Element
 */
function PatientView() {
	const params = useParams();
	const { state } = useLocation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { completing } = useAppSelector((state) => state.globalState);
	const appointmentId = params?.appointment_id || state?.appointmentId;
	const fetchStatus = useAppSelector(getPatientRecordFetchStatus);
	const fetchError = useAppSelector(getPatientRecordFetchError);
	const currentStage = useAppSelector(getPatientStage);
	const transcriptSections: TranscriptSection[] = useAppSelector(getTranscriptSections);
	const allDocuments = useAppSelector((state) => state.activeDocuments.allDocuments);

	const [activeTab, setActiveTab] = useState('documents');

	const [tabs, setTabs] = useState<TableTab[]>([
		{ id: 'documents', header: 'Documents', isActive: activeTab === 'documents' },
		{ id: 'clips', header: 'Clips', isActive: activeTab === 'clips', error: false },
	]);


	useEffect(() => {
		setTabs(prevTabs => prevTabs.map(tab => {
			if (tab.id === activeTab) {
				return { ...tab, isActive: true };
			}
			return { ...tab, isActive: false };

		}));
	}, [activeTab]);

	// Clear redux states
	function clearStates() {
		dispatch(resetActiveDocuments());
		dispatch(resetActivePatientData());
	}

	// Fetch patient data on mount and clear states on unmount 
	// if the appointmentId is a valid UUID navigate back to list view
	useEffect(() => {
		if (appointmentId && isUUID(appointmentId)) {
			dispatch<any>(fetchPatientData(appointmentId as string));
		} else if (appointmentId && !isUUID(appointmentId)) {
			// navigate back home ;)
			navigate("/");
		}

		return () => {
			clearStates();
		};
	}, [appointmentId]);

	const [completionId, setCompletionId] = useState<string | null>(null);

	const { generateLegacyDocument, generateDocuments, completion, isLoadingCompletion, stop, stopGenerate } = useGenerateDocuments({
		setCompletionId,
	});

	// Moved the below logic from recordings to patient view so we can show a tiny red dot on the clips tab

	// get newest document created at time
	const newestDocument = useMemo(() => allDocuments?.reduce((prev: any, current: any) =>
		prev?.created_at > current?.created_at ? prev : current,
		allDocuments[0] || {}
	), [allDocuments]);

	// Get the creation time of the oldest document
	const documentCreationTime = useMemo(() => newestDocument?.created_at ? new Date(newestDocument?.created_at) : null, [newestDocument?.created_at]);
	// do we need to filter on 
	const sortedSections = useMemo(() => {
		// make a copy of the transcriptSections
		const sortedTrans = [...transcriptSections];

		return sortedTrans?.sort((a: TranscriptSection, b: TranscriptSection) => new Date(b?.created_at || "").getTime() - new Date(a?.created_at || "").getTime());
	}, [transcriptSections]);

	// useEffect to handle errored clips and update tabs state
	useEffect(() => {
		const erroredClips = sortedSections.some(section =>
			determineErrorState(section, documentCreationTime) === 'error'
			|| determineErrorState(section, documentCreationTime) === 'warning'
		);

		if (erroredClips) {
			setTabs(prevTabs => prevTabs.map(tab => {
				if (tab.id === 'clips') {
					return { ...tab, error: erroredClips };
				}
				return tab;
			}));
		}

		return () => {
			setTabs(prevTabs => prevTabs.map(tab => {
				if (tab.id === 'clips') {
					return { ...tab, error: false };
				}
				return tab;
			}));
		}
	}, [sortedSections, activeTab, documentCreationTime]);

	return (
		<div className={"pt-16"}>
			<div className="w-full flex justify-between items-center ">
				<HDBackButton backTo="" />
				<div className="flex items-center">
					{fetchStatus === ActivePatientStatus.Succeeded && !completing && !fetchError ? (
						<div className="flex items-center ">
							<StageSelect currentStage={currentStage || PatientStage.Ongoing} patientId={appointmentId} showConfetti={true} />
						</div>
					) : null}
				</div>
			</div>

			<div className="mt-4 lg:hidden">
				<Visit />
			</div>

			<div className="mt-4 lg:hidden">
				<AppointmentInfo />
			</div>

			{fetchStatus === ActivePatientStatus.Loading ? (
				<div className=" w-full h-full mt-4 flex justify-center items-center">
					<Spinner size="small" />
				</div>
			) : (fetchStatus === ActivePatientStatus.Succeeded || fetchStatus === ActivePatientStatus.Idle) && !fetchError ? (
				<div className="w-full">
					{/* Tabs for documents and clips for md and smaller screens */}
					<div className="block lg:hidden">
						<div className="w-full mb-4">
							<TableTabs tabs={tabs} changeTab={setActiveTab} fullWidth={true} />
						</div>
						{activeTab === 'documents' && (
							<div className="">
								<div className="mb-4">
									<GenerateDocuments
										setCompletionId={setCompletionId}
										completionId={completionId}
										generateDocuments={generateDocuments}
										generateLegacyDocument={generateLegacyDocument}
										isLoadingCompletion={isLoadingCompletion}
									/>
								</div>
								<DocumentTabs
									setCompletionId={setCompletionId}
									completionId={completionId}
									generateDocuments={generateDocuments}
									completion={completion}
									isLoadingCompletion={isLoadingCompletion}
									stop={stop}
									stopGenerate={stopGenerate}
								/>
							</div>
						)}
						{activeTab === 'clips' && <Recordings sortedSections={sortedSections} documentCreationTime={documentCreationTime} />}
					</div>
					{/* Flex row for lg screens and above  */}
					<div className="hidden lg:flex lg:flex-row w-full gap-4 mt-4">
						<div className=" w-full hidden xl:block xl:max-w-[275px]">
							<Visit />
						</div>
						<div className="w-full xl:min-w-[380px] lg:w-2/3">
							<div className="mb-4 xl:hidden ">
								<Visit />
							</div>
							<div className="mb-4">
								<AppointmentInfo />
							</div>
							<DocumentTabs
								setCompletionId={setCompletionId}
								completionId={completionId}
								generateDocuments={generateDocuments}
								completion={completion}
								isLoadingCompletion={isLoadingCompletion}
								stop={stop}
								stopGenerate={stopGenerate}
							/>
						</div>
						<div className="w-full xl:min-w-[380px] lg:max-w-[380px] flex flex-col gap-4">
							<div className="">
								<GenerateDocuments
									setCompletionId={setCompletionId}
									completionId={completionId}
									generateDocuments={generateDocuments}
									generateLegacyDocument={generateLegacyDocument}
									isLoadingCompletion={isLoadingCompletion}
								/>
							</div>
							<Recordings sortedSections={sortedSections} documentCreationTime={documentCreationTime} />
						</div>
					</div>
				</div>
			) : fetchStatus === ActivePatientStatus.Failed && fetchError ? (
				<div className="flex justify-center">
					<div className="text-red-300 text-sm font-bold mt-20">{FETCH_ERROR_REFRESH}</div>
				</div>
			) : fetchError ? (
				<div className="flex justify-center">
					<div className="text-red-300 text-sm font-bold mt-20">{NO_PATIENT_RECORD}</div>
				</div>
			) : null}
		</div>
	);
}

export default PatientView;