import { PatientRecord, TranscriptSection, TranscriptSectionStatus } from '@/common/utils/types';
import classNames from 'classnames';
import React from 'react'
import ThreeDotRecordingMenu from './ThreeDotRecordingMenu';
import AudioPlayer from './AudioPlayer';
import { determineErrorState, determineErrorMessage } from '@/common/utils/helpers';
import RetryTranscriptSection from '@/common/components/RetryTranscriptSection';
import { useAppSelector } from "@/common/hooks/useRedux";
import { getPatientData } from '../../state/redux/activePatientDataSlice';
import Spinner from '@/common/components/Spinner';

interface Props {
    transcriptSection: TranscriptSection;
    documentCreationTime: Date | null;
    isLastClip: boolean
    setOpenTranscriptModal: React.Dispatch<React.SetStateAction<boolean>>
    setSelectedTranscriptSectionId: React.Dispatch<React.SetStateAction<string | null>>
}

const Clip: React.FC<Props> = ({
    transcriptSection,
    documentCreationTime,
    isLastClip,
    setOpenTranscriptModal,
    setSelectedTranscriptSectionId
}) => {
    const patientData: PatientRecord = useAppSelector(getPatientData);

    const transcriptId = patientData?.transcriptions?.id

    const creationTime = new Date(transcriptSection?.created_at || '');
    const formattedTime = creationTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    const formattedDate = creationTime.toLocaleDateString();

    const errorState = determineErrorState(transcriptSection, documentCreationTime);
    const warningMessage = "The clip is recorded but not used in a document";

    let borderLeftColor = 'border-l-white';
    let backgroundColor = 'bg-white';
    let borderTopColor = 'border-t-white';
    let textColor = 'text-gray-900';
    let borderWidth = 'border-l-1';

    if (errorState === 'error') {
        borderLeftColor = 'border-l-red-600';
        backgroundColor = 'bg-red-50';
        borderTopColor = 'border border-red-50 border-t-red-600';
        textColor = 'text-red-900';
        borderWidth = 'border-l-4'
    } else if (errorState === 'warning') {
        borderLeftColor = 'border-l-yellow-600';
        borderTopColor = 'border border-yellow-50 border-t-yellow-600';
        backgroundColor = 'bg-yellow-50';
        textColor = 'text-yellow-900';
        borderWidth = 'border-l-4'
    }

    return (
        <div className={classNames("flex flex-col gap-2 pt-2 pb-3 w-full h-full border border-t-0  border-b-gray-200 border-b-1", isLastClip && "rounded-b-md", borderLeftColor, backgroundColor, borderWidth)}>
            <div className="w-full h-full flex items-center justify-between px-6">
                <span className="text-md font-medium">{formattedTime}</span>
                <span className="text-xs front-normal">{formattedDate}</span>
            </div>
            {transcriptSection?.status === TranscriptSectionStatus.InProgress ?
                <div className='w-full h-full flex items-center justify-center px-6'>
                    <div className='w-full  flex items-center py-2 px-3 gap-2 bg-gray-100 border rounded-md  border-gray-100'>
                        <Spinner size='xsmall' color={'text-gray-600'} />
                        <span className={classNames("text-gray-600", "text-sm font-medium")}>Processing...</span>
                    </div>
                </div>
                : <div className="w-full h-full flex items-center justify-between gap-5 px-6">
                    <AudioPlayer key={transcriptSection?.id} transcriptSection={{ ...transcriptSection, transcription_id: transcriptId }} documentCreationTime={documentCreationTime} />
                    <ThreeDotRecordingMenu
                        transcriptSection={{ ...transcriptSection, transcription_id: transcriptId }}
                        setOpenTranscriptModal={setOpenTranscriptModal}
                        setSelectedTranscriptSectionId={setSelectedTranscriptSectionId}
                    />
                </div>}
            {
                (errorState === 'error' || errorState === 'warning') && (
                    <div className={classNames("flex w-full pt-3 mt-2 px-2 items-center justify-center gap-4", borderTopColor)}>
                        <span className={classNames("text-xs font-medium ", textColor)}>
                            {errorState === 'error' ? determineErrorMessage(transcriptSection) : warningMessage}
                        </span>
                        {errorState === 'error' && <RetryTranscriptSection transcriptSection={transcriptSection} patientRecord={patientData}>
                            <span className={classNames("text-xs font-semibold underline", textColor)}>Retry</span>
                        </RetryTranscriptSection>}
                    </div>
                )
            }
        </div >
    );
};

export default Clip;