import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { upsertChatCompletions } from "@common/lib/supabaseClient";
import Content from "./Content";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import {
	getActiveDocumentIndex,
	getActiveDocuments,
	updateDocumentInTab,
	addOrUpdateTab,
	resetProgress,
	updateDocumentThunk,
	setIsGenerating,
} from "../../state/redux/activeDocumentsSlice";
import { DocumentFeedbackEnum, DocumentStatus, DocumentTab, DocumentType } from "@/common/utils/types";
import JsonContent from "./JsonContent";
import EditIcon from "@icons/edit-05.svg?react";
import RefreshIcon from "@icons/refresh-cw-03.svg?react";
import InformationCircleIcon from "@icons/info-circle.svg?react";
import SaveIcon from "@icons/save-01.svg?react";
import StopIcon from "@icons/stop-circle.svg?react";
import { Tooltip } from 'react-tooltip'
import { cloneDocument } from "@/common/utils/helpers";
import Feedback from "./controls/Feedback";
import TerminologyFlagger from "./controls/TerminologyFlagger";
import GenericControl from "./controls/GenericControl";
import Copy from "./controls/Copy";
import Export from "./controls/Export";
import Print from "./controls/Print";
import Delete from "./controls/Delete";
import DocHeader from "./DocHeader";
import { toolTipStyles } from '@/theme';

const Document = ({
	completionId,
	setCompletionId,
	isLoadingCompletion,
	completion,
	handleRetry,
	activeTabId,
	tab,
	stop,
	stopGenerate,
}: {
	completionId: string | null;
	isLoadingCompletion: boolean;
	completion: string | null; //Streaming completion
	handleRetry: (document: DocumentType, header: string) => void;
	activeTabId: string;
	tab: DocumentTab;
	stop: () => void;
	setCompletionId: (id: string | null) => void;
	stopGenerate: (patientRecordId: string, documentTypeId: string) => void;
}) => {
	const [isEditing, setIsEditing] = useState(false);
	const dispatch = useAppDispatch();
	const currentDocumentIndex = useAppSelector(getActiveDocumentIndex);
	const content = useAppSelector(getActiveDocuments)[currentDocumentIndex];
	const activePatientId = useAppSelector((state) => state.activePatientData.patientData?.id);
	const [newHeader, setNewHeader] = useState(content?.chat_json?.header);
	const [newContent, setNewContent] = useState(content?.chat_json?.chat_text);

	useEffect(() => {
		if (content?.chat_json?.header) {
			setNewHeader(content?.chat_json?.header);
		}
		if (content?.chat_json?.chat_text) {
			setNewContent(content?.chat_json?.chat_text);
		}
	}, [content]);

	const handleSave = async () => {
		if (!newHeader) {
			toast.warn("Header cannot be empty");
			return;
		}

		// Save in supabase
		dispatch(
			updateDocumentInTab({
				tabId: activeTabId,
				patientRecordId: activePatientId || "",
				document: { id: content?.id!, chat_json: { header: newHeader, chat_text: newContent } },
			}),
		);

		await upsertChatCompletions({ id: content?.id, chat_json: { header: newHeader, chat_text: newContent } });
		setIsEditing(false);
	};

	const handleCancel = () => {
		// reset the header and content to the original values
		setNewHeader(content?.chat_json?.header);
		setNewContent(content?.chat_json?.chat_text);
		setIsEditing(false);
	};

	/**
	 * Stops the document generation and updates the document in the tab and supabase
	 */
	const handleStop = () => {
		stop();

		stopGenerate(content?.patient_record_id || "", content?.document_type_id || "");

		setCompletionId(null);

		if (content && content.chat_json && completion !== null) {
			let newChatMessageClone = cloneDocument(content, completion, DocumentStatus.Cancelled);

			if (newChatMessageClone.chat_json) {
				newChatMessageClone.chat_json.chat_text = completion;
			}

			toast.warning("Document generation cancelled.");

			dispatch(updateDocumentInTab({ tabId: activeTabId, document: newChatMessageClone, patientRecordId: activePatientId || "" }));
			dispatch(updateDocumentThunk({ tabId: activeTabId, document: newChatMessageClone }) as any);
			dispatch(addOrUpdateTab({ tabId: activeTabId, isLoading: false }));
			dispatch(resetProgress({ tabId: activeTabId, patientRecordId: activePatientId || "" }));
			dispatch(setIsGenerating({ isGenerating: false, patientRecordId: activePatientId || "" }));
		}
	}

	const isActive = content?.id === completionId;

	return (

		<div className="border-b border-gray-200 bg-white    shadow-sm ring-1 ring-gray-200 rounded-b-md">
			<div className="flex-col justify-between w-full ">
				{/* Header name and document index toggle */}
				<DocHeader isLoadingCompletion={isLoadingCompletion} content={content} isActive={isActive} currentDocumentIndex={currentDocumentIndex} />
				<div className="flex  gap-4 items-center w-full p-4 border-b border-gray-300">
					{isLoadingCompletion ? (
						// Shows the stop control if the document is being generated
						<div>
							<Tooltip
								anchorSelect="#stop-doc-button"
								content={"Stop Generating Document"}
								positionStrategy="fixed"
								place="bottom"
								style={toolTipStyles}
							/>
							<button
								id="stop-doc-button"
								type="button"
								className="secondary-button"
								onClick={handleStop}
							>
								<StopIcon className="h-4 w-4 " />
								Stop
							</button>
						</div>
					) : isEditing ? (
						// Show the editing controls if the document is being edited
						<div className="w-full flex items-center justify-between " >
							<input
								type="text"
								className="block w-1/2 border-0 py-1.5 text-gray-900 shadow-sm  placeholder:text-gray-400 rounded-md ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700  sm:text-sm sm:leading-6"
								value={newHeader}
								onChange={(e) => setNewHeader(e.target.value)}
							/>

							<div className="flex items-center gap-4">

								<Tooltip
									content={"Cancel Editing"}
									anchorSelect="#cancel-doc-button"
									style={toolTipStyles}
								/>
								<button
									type="button"
									className="secondary-button"
									onClick={() => {
										handleCancel();
									}}
								>
									Cancel
								</button>
								<Tooltip
									anchorSelect="#save-doc-button"
									content={"Save Edits"}
									style={toolTipStyles}
								/>
								<button
									id="save-doc-button"
									type="button"
									className="primary-button"
									onClick={() => {
										handleSave();
									}}
								>
									<SaveIcon className="h-4 w-4" />
									Save
								</button>
							</div>
						</div>
					) : (
						// Show the document controls if the document is not being generated
						<>
							<TerminologyFlagger isIcon={true} />
							<Feedback feedbackType={DocumentFeedbackEnum.Good} completionId={content?.id || ""} currentFeedback={content?.feedback || ""} documentName={content?.chat_json?.header || ""} />
							<Feedback feedbackType={DocumentFeedbackEnum.Bad} completionId={content?.id || ""} currentFeedback={content?.feedback || ""} documentName={content?.chat_json?.header || ""} />
							<GenericControl
								toolTipContent="Retry Generation"
								toolTipId="retry-doc-button"
								onClick={() => {
									if (content?.document_types && content?.header) {
										handleRetry(content?.document_types, content?.header);
									} else {
										toast.error("Unable to retry document, Refresh the page and try again.");
									}
								}}
							>
								<RefreshIcon className="h-4 w-4" />
							</GenericControl>
							<GenericControl
								toolTipContent="Edit Document"
								toolTipId="edit-doc-button"
								onClick={() => {
									setIsEditing(true);
								}}
							>
								<EditIcon className="h-4 w-4" />
							</GenericControl>
							<Copy content={content} />
							<Export content={content} />
							<Print content={content} />
							<Delete content={content} activeTabId={activeTabId} />
						</>
					)}
				</div>
			</div>
			{/* CONTENT */}
			<div className="h-full">
				{content?.status === DocumentStatus.Failed && isActive ? (
					// Show an error message if there was an error loading the document
					<div className="flex flex-row gap-x-1 p-4">
						<InformationCircleIcon className="h-4 w-4 text-gray-900 opacity-30" />
						<p>Unable to generate document. Please Retry.</p>
					</div>

				) : content?.status === DocumentStatus.Cancelled && isActive ? (
					<div className="p-4">
						<p>
							Document generation was cancelled. Please retry to generate the document.
						</p>
					</div>

				) : isLoadingCompletion && content?.status === DocumentStatus.Processing && isActive && !content?.document_types?.is_json ? (
					// Show content while it is being loaded and it is not JSON
					<Content
						key={content?.id}
						content={completion}
						isEditing={isEditing}
						newHeader={newHeader}
						setNewHeader={setNewHeader}
						newContent={newContent}
						setNewContent={setNewContent}
						copyAsMarkdown={false}
						activeDocument={content}
						tabId={tab?.tabId}
					/>
				) : isLoadingCompletion && (content?.status === DocumentStatus.Processing || content?.status === DocumentStatus.Cancelled) && isActive && content?.document_types?.is_json ? (
					// Show JSON content if the document type is JSON and it matches the completion ID
					<JsonContent
						key={content?.id}
						isStreaming={isLoadingCompletion}
						content={completion || ""}
						isEditing={isEditing}
						newContent={newContent}
						setNewContent={setNewContent}
						activeDocument={content}
						tabId={tab?.tabId}
					/>
				) : content?.document_types?.is_json ? (
					// Show JSON content if the document type is JSON
					<JsonContent
						key={content?.id}
						isStreaming={isLoadingCompletion}
						content={content?.chat_json?.chat_text}
						isEditing={isEditing}
						newContent={newContent}
						setNewContent={setNewContent}
						activeDocument={content}
						tabId={tab?.tabId}
					/>
				) : (
					// Show regular content otherwise
					<Content
						key={content?.id}
						content={content?.chat_json?.chat_text}
						isEditing={isEditing}
						newHeader={newHeader}
						setNewHeader={setNewHeader}
						newContent={newContent}
						setNewContent={setNewContent}
						copyAsMarkdown={false}
						activeDocument={content}
						tabId={tab?.tabId}
					/>
				)}
			</div>
		</div>
	);
};

export default Document;
