import { switchDoctor } from '@/state/redux/doctorSlice';
import { Menu, MenuButton, Transition, MenuItem, MenuItems } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/16/solid';
import classNames from 'classnames';
import { Fragment } from 'react';
import { useAppDispatch, useAppSelector } from '@common/hooks/useRedux';
import { Doctor } from '../utils/types';
import { useRealtimeContextApi } from '@/state/providers/RealtimeProvider';

/**
 * DoctorSelect component
 * @param disabled - If the component is disabled
 */
const DoctorSelect = ({ disabled = false }: { disabled?: boolean }) => {
    const dispatch = useAppDispatch();
    const { doctors, selectedDoctor } = useAppSelector((state) => state.doctors);
    const { selectedDoctorRef } = useRealtimeContextApi()

    const handleChange = async (doctor: Doctor | null) => {
        await dispatch(switchDoctor(doctor?.id || null))
        selectedDoctorRef.current = doctor
    }

    return (
        <Menu as="div" className="relative flex-grow w-full">
            <MenuButton
                disabled={disabled}
                className={classNames(disabled
                    ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer", "flex  hover:bg-gray-100 inset-y-0 right-0 bg-white w-full rounded-md shadow-sm items-center justify-between px-3 py-2 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700 focus:outline-none leading-5 text-sm ")}
            >
                <span className="sr-only">Select Doctor</span>

                <span className="text-sm font-semibold leading-5 text-gray-700" aria-hidden="true">
                    {selectedDoctor?.doctor_name || "All Doctors"}
                </span>
                <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-700 " aria-hidden="true" />
            </MenuButton>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <MenuItems className={classNames("absolute z-10 my-1 w-full origin-top-right rounded-md bg-white py-2 shadow-sm ring-1 ring-gray-300 focus:outline-none hover:cursor-pointer ")}>
                    {doctors?.map((doctor: Doctor) => (
                        <MenuItem key={doctor?.id}>
                            <button
                                disabled={disabled}
                                onClick={() => handleChange(doctor)}
                                className={classNames(
                                    doctor?.id === selectedDoctor?.id ? "bg-max-700    text-white" : "bg-white",
                                    "text-left block px-3 py-1 text-sm font-semibold leading-6 text-gray-700 w-full",
                                )}
                            >
                                {doctor?.doctor_name}
                            </button>
                        </MenuItem>
                    ))}
                    <MenuItem>
                        {() => (
                            <button
                                disabled={disabled}
                                onClick={() => handleChange(null)}
                                className={classNames(
                                    !selectedDoctor ? "bg-max-700    text-white" : "bg-white",
                                    "text-left block px-3 py-1 text-sm font-semibold leading-6 text-gray-700 w-full",
                                )}
                            >
                                All Doctors
                            </button>
                        )}
                    </MenuItem>
                </MenuItems>
            </Transition>
        </Menu>
    );
};

export default DoctorSelect;