import AudioLevels from "@common/components/AudioLevels";
import useAudioRecorder, { isRecordingError } from "@common/hooks/useAudioRecorder";
import { PatientRecord, RecordingState, TranscriptSection } from "@common/utils/types";
import useFileUpload from "@common/hooks/useFileUpload";
import UploadProgressBar from "@common/components/UploadProgressBar";
import classNames from "classnames";
import Timer from "@common/components/Timer";
import Spinner from "@common/components/Spinner";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import {
	getPatientData,
	updateOrAddTranscriptSection,
} from "@features/patient-view/state/redux/activePatientDataSlice";
import { useEffect } from "react";
import MicrophoneIcon from "@icons/microphone-01.svg?react";
import StopIcon from '@icons/stop-circle.svg?react';
import * as Sentry from "@sentry/react";

export default function TranscriptionControlPanel({
	transcriptionId,
	setIsRecording,
}: {
	transcriptionId: string;
	setIsRecording: (x: boolean) => void;
}) {
	const { startRecording, stopRecording, recordingState, audioStream, resetRecorder, recordingTime } =
		useAudioRecorder();
	const dispatch = useAppDispatch();
	//NOTE: Must be full record of patientData, w/all ChatCompletions and Transcriptions.
	const patientRecord = useAppSelector(getPatientData);

	const updateTranscriptSection = (transcriptSection: TranscriptSection) => {
		dispatch(updateOrAddTranscriptSection({ transcriptId: transcriptionId, transcriptSection }));
	};

	useEffect(() => {
		if (recordingState === RecordingState.recording) {
			setIsRecording(true);
		} else {
			setIsRecording(false);
		}
	}, [recordingState]);

	const { uploadAudio, uploadProgress, isUploading } = useFileUpload(updateTranscriptSection);

	const onAudioDataAvailable = async (
		audioFile: File,
		patientRecord: PatientRecord,
		transcriptSectionId: string,
	) => {
		try {
			if (!patientRecord.transcriptions?.id || !patientRecord.id) {
				throw new Error("patientRecord is undefined");
			}
			// upload audio
			const uploadResponse = await uploadAudio(
				audioFile,
				audioFile.name,
				patientRecord.transcriptions.id,
				transcriptSectionId,
				patientRecord
			);

			if (uploadResponse?.err) {
				throw new Error(uploadResponse?.err);
			}
		} catch (error) {
			Sentry.captureException(error);
		}
	};

	return (
		<div className="bg-white grid grid-cols-3 ">
			<div className="h-full flex flex-col items-center justify-center">
				<div className="flex flex-col h-full justify-end w-full">
					<div className="h-1/2 flex items-end justify-center">
						<div className="w-full h-full pl-0 pr-4 pt-0">
							<AudioLevels
								darkColor="#26B5BB"
								brightColor="#26B5BB"
								barSpacing={1}
								numBars={20}
								recordingState={recordingState}
								audioStream={audioStream}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className={classNames("w-full flex items-center justify-center", recordingState === RecordingState.inactive || isRecordingError(recordingState) ? "p-6" : "p-0")}>
				{recordingState === RecordingState.loading ? (
					<Spinner size="medium" />
				) : isUploading ? (
					<UploadProgressBar percentage={uploadProgress} />
				) : recordingState === RecordingState.inactive || isRecordingError(recordingState) ? (
					<button
						type="button"
						className={classNames(
							isRecordingError(recordingState)
								? `bg-red-600 hover:bg-red-500 focus-visible:outline-red-600`
								: `bg-max-700  hover:bg-max-800  focus-visible:outline-bg-max-700 `,
							`focused px-4 py-2 absolute right-6 rounded-md gap-2 flex items-center justify-center text-md font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 z-10 cursor-pointer`,
						)}
						onClick={(e) => {
							e.preventDefault();
							// @ts-ignore
							if (recordingState === RecordingState.recording) {
								stopRecording();
							} else if (isRecordingError(recordingState)) {
								resetRecorder();
							} else {
								if (!patientRecord) {
									Sentry.captureMessage("Patient Record not found in TranscriptionControlPanel", {
										level: "error",
									});
									return;
								}

								startRecording(
									patientRecord,
									(audioFile: File, patientRecord: PatientRecord, transcriptSectionId: string) =>
										onAudioDataAvailable(audioFile, patientRecord, transcriptSectionId),
								);
							}
						}}
					>

						<MicrophoneIcon className="h-5 w-5 text-white " />
						<span className="text-md">
							{recordingState === RecordingState.no_permission
								? "Microphone Error! Enable Permissions"
								: recordingState === RecordingState.no_device
									? "Microphone Error! No Device Found"
									: recordingState === RecordingState.error
										? "Microphone Error!"
										: "Add Clip"}
						</span>
					</button>
				) : (
					(recordingState === "recording" || recordingState === "paused") && (
						<div className="w-full flex flex-row items-center justify-evenly gap-x-6">
							<button
								type="button"
								className={`focused bg-red-600 hover:bg-red-500 focus-visible:outline-red-600 w-20 h-20 sm:h-24 sm:w-24 md:h-32 md:w-32 sm:h-35 rounded-full flex flex-col items-center justify-center text-xs font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 hover:animate-pulse z-10 cursor-pointer`}
								onClick={(e) => {
									e.preventDefault();
									stopRecording();
								}}
							>
								<StopIcon className="h-10 w-10 sm:h-10 sm:w-10 text-white mb-1" />
								<span className="hidden md:inline-block sm:text-xs md:text-sm">
									{(recordingState === "recording" || recordingState === "paused") && "Stop"}
								</span>
							</button>
						</div>
					)
				)}
			</div>
			<div className="flex flex-col justify-between items-center">
				<div className="mt-2">
					{recordingState === RecordingState.recording && (
						<Timer time={recordingTime} recordingState={recordingState} />
					)}
				</div>
			</div>
		</div>
	);
}
