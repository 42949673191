import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import { deletePatientRecord } from "@common/lib/supabaseClient";
import ConfirmModal from "@common/components/ConfirmModal";
import { useRealtimeContextApi } from "@/state/providers/RealtimeProvider";
import EditIcon from "@icons/edit-05.svg?react";
import TrashIcon from "@icons/trash-03.svg?react";
import Divider from "@/common/components/Divider";
import DotsVerticalIcon from "@icons/dots-vertical.svg?react";
import { BroadcastEvents } from "@/common/utils/types";

interface Props {
	patientRecordId: string;
	handleEdit: () => void;
}

const PatientViewMenu = ({ patientRecordId, handleEdit }: Props) => {
	const navigate = useNavigate();
	const { emitEvent } = useRealtimeContextApi();

	const handleDelete = async () => {
		await deletePatientRecord(patientRecordId);
		await emitEvent(BroadcastEvents.patientDeleted, { patientRecordId });
		navigate("/");
	};

	const [open, setOpen] = useState(false);

	return (
		<>
			<Menu as="div" className="relative flex-none select-none">
				<MenuButton className="focused border border-gray-300 rounded-md p-2 bg-white text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus">
					<span className="sr-only">Open options</span>
					<DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
				</MenuButton>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<MenuItems className=" absolute right-0 flex-col items-start justify-start z-20 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
						<MenuItem>
							{({ focus }) => (
								<button
									className={classNames(
										focus ? "bg-gray-50" : "",
										" flex justify-start items-center block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 font-medium",
									)}
									onClick={handleEdit}
								>
									<EditIcon className="h-4 w-4 mr-2" />
									Edit
								</button>
							)}
						</MenuItem>
						<Divider customClassNames="text-gray-200" />
						<MenuItem>
							{({ focus }) => (
								<button
									className={classNames(
										focus ? "bg-gray-50" : "",
										"flex  justify-start items-center block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 font-medium",

									)}
									onClick={() => {
										setOpen(true);
									}}
								>
									<TrashIcon className="h-4 w-4 mr-2" />
									Delete
								</button>
							)}
						</MenuItem>
					</MenuItems>
				</Transition>
			</Menu>
			<ConfirmModal
				id="DeleteAppointment"
				headerText="Are you sure you want to delete the entire appointment?"
				cancelButtonText="Cancel"
				confirmButtonText="Delete"
				confirmButtonColor="bg-red-600"
				confirmHoverButtonColor="bg-red-500"
				onCancel={() => {
					setOpen(false);
				}}
				onConfirm={() => {
					handleDelete();
					setOpen(false);
				}}
				isOpen={open}
			/>
		</>
	);
};
export default PatientViewMenu;
