import TrashIcon from "@icons/trash-03.svg?react";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { deleteChatCompletions } from '@/common/lib/supabaseClient';
import { removeDocumentFromTab, removeFromAllDocuments } from '@/features/patient-view/state/redux/activeDocumentsSlice';
import { decrementDocumentCount } from '@/state/redux/patientTableRecordsSlice';
import classNames from 'classnames';
import { Tooltip } from 'react-tooltip';
import { BroadcastEvents, ChatCompletions } from '@/common/utils/types';
import { useState } from "react";
import ConfirmModal from "@/common/components/ConfirmModal";
import { useRealtimeContextApi } from "@/state/providers/RealtimeProvider";

interface DeleteProps {
    content: ChatCompletions
    activeTabId: string
}

function Delete({ content, activeTabId }: DeleteProps) {
    const { emitEvent } = useRealtimeContextApi();

    const dispatch = useAppDispatch();
    const activePatientId = useAppSelector((state) => state.activePatientData.patientData?.id);
    const [open, setOpen] = useState(false);

    const handleDelete = async () => {
        dispatch(removeDocumentFromTab({ tabId: activeTabId, documentId: content?.id! }));
        // delete from supabase
        if (content?.id) {
            await deleteChatCompletions(content.id);

            const payload = {
                chatMessageId: content.id,
                patientRecordId: activePatientId,
                documentName: content?.header || content?.chat_json.header || ""
            }
            // emit delete event
            await emitEvent(BroadcastEvents.documentDeleted, payload);
        }

        // remove from state
        dispatch(decrementDocumentCount({ id: content?.patient_record_id }));
        dispatch(removeFromAllDocuments({ documentId: content?.id || "", patientRecordId: activePatientId || "" }));

        setOpen(false);
    };

    return (
        <>
            <Tooltip
                anchorSelect="#delete-doc-button"
                content={
                    "Delete Document"
                }
                style={{ maxWidth: "300px", zIndex: 9999, fontSize: "12px", fontFamily: "Plus Jakarta Sans" }}
            />
            <button
                id='delete-doc-button'
                className={classNames(
                    "p-1 hover:bg-gray-100 hover:text-gray-700 hover:rounded-sm focused"
                )}
                onClick={() => {
                    setOpen(true);
                }}
            >
                <TrashIcon className="h-4 w-4" />
            </button>
            <ConfirmModal
                id="delete-document"
                headerText="Delete this document?"
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                confirmButtonColor="bg-red-600"
                confirmHoverButtonColor="bg-red-500"
                onCancel={() => {
                    setOpen(false);
                }}
                onConfirm={handleDelete}
                isOpen={open}
            />
        </>
    )
}

export default Delete