import { useEffect, useState } from "react";
import Cards from "./Card";
import Cornerstone from "@assets/integrations/cornerstone.png";
import Avimark from "@assets/integrations/avimark.png";
import Impromed from "@assets/integrations/impromed.png";
import Vetspire from "@assets/integrations/vetspire.png";
import { useAccountContext } from "@providers/AccountProvider";
import ConnectedIntegration from "./ConnectedIntegration";

/**
 * 3rd party integrations available through bitwerx
 */
export enum IntegrationThirdParty {
	AVImark = "AVImark",
	ImproMed = "ImproMed",
	Cornerstone = "Cornerstone",
}

/**
 * Represents the type of integrations that we support
 */
export enum IntegrationProvider {
	VetSpire = "vetspire",
	Bitwerx = "bitwerx",
	EzyVet = "ezyvet",
}

export type Integration = {
	id: number;
	name: string;
	provider: IntegrationThirdParty | IntegrationProvider;
	imageUrl: string;
	disabled: boolean;
	connected: boolean;
	requested: boolean;
	lastRequest: string;
	practiceId: string;
	integrationType: string;
	apiUrl?: string;
};

const integrations: Integration[] = [
	{
		id: 1,
		name: "Cornerstone",
		provider: IntegrationThirdParty.Cornerstone,
		imageUrl: Cornerstone,
		disabled: false,
		connected: false,
		requested: false,
		lastRequest: "2023-11-08 16:42:10.399+00",
		practiceId: "D01B0E90-A02A-4C36-8B3B-5B83BF1506E2",
		integrationType: "pims",
	},
	{
		id: 2,
		name: "AVImark",
		provider: IntegrationThirdParty.AVImark,
		imageUrl: Avimark,
		disabled: false,
		connected: false,
		requested: false,
		lastRequest: "",
		practiceId: "",
		integrationType: "pims",
	},
	{
		id: 3,
		name: "ImproMed",
		provider: IntegrationThirdParty.ImproMed,
		imageUrl: Impromed,
		disabled: false,
		connected: false,
		requested: false,
		lastRequest: "",
		practiceId: "",
		integrationType: "pims",
	},
	{
		id: 4,
		name: "VetSpire",
		provider: IntegrationProvider.VetSpire,
		imageUrl: Vetspire,
		disabled: false,
		connected: false,
		requested: false,
		lastRequest: "",
		practiceId: "",
		integrationType: "pims",
	},
];

/**
 * Integrations page component
 */
const IntegrationsPage = () => {
	const { pmsIntegrationData } = useAccountContext();
	const [connectedIntegrations, setConnectedIntegrations] = useState<Integration[]>([]);
	const [unconnectedIntegrations, setUnconnectedIntegrations] = useState<Integration[]>([]);

	// This function will be used to determine the connected status and practiceId
	useEffect(() => {
		if (pmsIntegrationData && pmsIntegrationData?.length > 0) {
			const connected: Integration[] = [];
			const updated = integrations?.map((integration) => {
				// Check if this integration is the one that's connected.
				const pmsIntegrationDataItem = pmsIntegrationData.find(
					(item) =>
						item.connected_third_party == integration.provider ||
						item.integrations?.integration_name == integration.provider
				);

				const isConnected = !!pmsIntegrationDataItem;
				const updatedIntegration = {
					...integration,
					// Update the connected and practiceId properties if this integration is connected.
					connected: isConnected || integration.connected || false,
					practiceId: isConnected ? pmsIntegrationDataItem?.practice_id || "" : integration.practiceId || "" || "",
					lastRequest: isConnected ? pmsIntegrationDataItem?.last_request || "" : "",
					apiUrl: isConnected ? pmsIntegrationDataItem?.api_url || "" : "",
				};

				if (isConnected) {
					connected.push(updatedIntegration);
				}

				return updatedIntegration;
			});

			setConnectedIntegrations(connected);
			setUnconnectedIntegrations(updated.filter(integration => !integration.connected));
		} else {
			// If pmsIntegrationData is null, set the original integrations array.
			setUnconnectedIntegrations(integrations);
		}
	}, [pmsIntegrationData]); // Dependencies array for useEffect.

	return (
		<div className="flex-grow flex flex-col gap-5" >
			<div className="flex flex-row items-center justify-between w-full">
				<div className="flex flex-col justify-between gap-1">
					<h3 className="text-lg text-gray-900 font-semibold ">Integrations</h3>
					<p className="text-sm text-gray-600 ">Supercharge your workflow and connect the tool(s) you use every day.</p>
				</div>
			</div>


			{connectedIntegrations?.length > 0 && <div className="w-full border border-b-gray-200 my-5" />}

			<div className="">
				{connectedIntegrations && connectedIntegrations?.length > 0 && (
					connectedIntegrations[0] && <ConnectedIntegration integration={connectedIntegrations[0]} />
				)}
			</div>
			<div className="">
				<h4 className="text-sm font-semibold text-gray-900 mb-5 ">
					{"Practice Information Management Softwares"}
				</h4>
				<div role="list" className="grid grid-cols-1 gap-x-5 gap-y-5 lg:grid-cols-2 ">
					{unconnectedIntegrations && unconnectedIntegrations?.map((integration: any, index) => (
						<Cards key={index} integration={integration} />
					))}
				</div>
			</div>
		</div >
	);
};

export default IntegrationsPage;
