import ChevronRightIcon from "@icons/chevron-right.svg?react";
import ChevronLeftIcon from "@icons/chevron-left.svg?react";
import classNames from "classnames";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { getActiveDocumentsLength, setActiveDocumentIndex } from "../../state/redux/activeDocumentsSlice";
import { ChatCompletions } from "@/common/utils/types";

interface Props {
    isLoadingCompletion: boolean;
    content: ChatCompletions;
    isActive: boolean;
    currentDocumentIndex: number;
}

const DocHeader = ({
    isLoadingCompletion,
    content,
    isActive,
    currentDocumentIndex,
}: Props) => {
    const dispatch = useAppDispatch();
    const activeDocumentsLength = useAppSelector(getActiveDocumentsLength);

    return (
        <div className="flex items-center justify-between w-full w-full">
            {isLoadingCompletion && isActive ? (
                <div className="flex justify-between items-center w-full p-4 border-b border-gray-300">
                    <p className="font-bold text-lg text-gray-900 truncate">{content?.chat_json?.header}</p>
                </div>
            ) : (
                <div className="flex justify-between items-center w-full p-4 border-b border-gray-300">
                    <p className="font-bold text-lg text-gray-900 truncate">{content?.chat_json?.header}</p>
                    {activeDocumentsLength > 1 && (
                        <div className="flex items-center border border-gray-300  rounded-md  cursor-pointer">
                            <button
                                type="button"
                                className={classNames(
                                    currentDocumentIndex < 1
                                        ? "bg-gray-50 text-gray-400 cursor-disabled"
                                        : "text-gray-700 cursor-pointer",
                                    " px-2  rounded-l-md py-2 border-r border-gray-300 hover:bg-gray-50 focused   ",
                                )}
                                onClick={() => {
                                    dispatch(setActiveDocumentIndex(currentDocumentIndex - 1));
                                }}
                                disabled={currentDocumentIndex === 0}
                            >
                                <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                            </button>

                            <div className="text-sm px-4 py-2 text-gray-700 font-semibold whitespace-nowrap">
                                {currentDocumentIndex + 1} of {activeDocumentsLength}
                            </div>

                            <button
                                type="button"
                                className={classNames(
                                    currentDocumentIndex === activeDocumentsLength - 1
                                        ? "bg-gray-50 text-gray-400 cursor-disabled"
                                        : "text-gray-700",
                                    " px-2  border-l  rounded-r-md py-2 border-gray-300 hover:bg-gray-50 focused ",
                                )}
                                onClick={() => {
                                    dispatch(setActiveDocumentIndex(currentDocumentIndex + 1));
                                }}
                                disabled={currentDocumentIndex === activeDocumentsLength - 1}
                            >
                                {currentDocumentIndex === activeDocumentsLength - 1 ? (
                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                ) : (
                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                                )}
                            </button>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}

export default DocHeader