import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import { useSupabaseClient } from "@supabase/auth-helpers-react";
import classNames from "classnames";
import moment from "moment";
import React, { useState, useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import type { PatientRecord } from "../utils/types";
import { debounce } from "lodash";
import { useAppSelector } from "@/common/hooks/useRedux";
import SearchIcon from "@icons/search-lg.svg?react";
import * as Sentry from "@sentry/react";
import XIcon from "@icons/x-close.svg?react";

type Props = {
	verticalLayout?: boolean;
	onSelect?: (appointment: PatientRecord) => void;
	lastNDays?: number;
	notId?: string;
	isSearchBarVisible: boolean;
	toggleSearchBar: () => void;
	disabled?: boolean;
};


const SearchBar = ({ verticalLayout = false, onSelect, lastNDays, notId, isSearchBarVisible, toggleSearchBar, disabled = false }: Props) => {
	const supabase = useSupabaseClient();
	const [searchTerm, setSearchTerm] = useState("");
	const [fetchedData, setFetchedData] = useState<PatientRecord[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedAppointment, setSelectedAppointment] = useState<PatientRecord | null>(null);
	const navigate = useNavigate();
	const selectedTableRecords = useAppSelector((state) => state.patientTableRecords.selectedTableRecords);

	const fetchResults = useCallback(
		debounce(async (input: string) => {
			if (disabled || !input) {
				setFetchedData([]);
				return;
			}
			setIsLoading(true);
			try {
				const query = supabase
					.from('patient_record')
					.select(`
						patient_name,
						id, 
						patient_id,
						scheduled_at,
						is_archived
					`)
					.or(`patient_name.ilike.${input}%,patient_id.ilike.${input}%`)
					.eq('is_archived', false)
					.order('scheduled_at', { ascending: false })
					.limit(10);

				if (notId) {
					query.neq('id', notId);
				}

				if (lastNDays) {
					query.gte('scheduled_at', moment().subtract(lastNDays, 'days').toISOString());
				}

				const { data, error } = await query;

				if (error) {
					throw new Error(`Error fetching search results: ${error.message}`);
				}

				if (data && data.length > 0) {
					setFetchedData(data as PatientRecord[]);
					return;
				}

				// supabase can only join TO the table with the foreign key, not FROM the table with the foreign key
				const externalIdQuery = supabase
					.from('patients')
					.select(`
						external_id,
						patient_record (
							patient_name,
							id,
							patient_id,
							scheduled_at,
							is_archived,
							patient_id_new
						)
					`)
					.eq('patient_record.is_archived', false)
					.ilike('external_id', `${input}%`)
					.order('created_at', { ascending: false })
					.limit(10);

				const { data: externalData, error: externalError } = await externalIdQuery;
				if (externalError) {
					throw new Error(`Error fetching search results: ${externalError.message}`);
				}

				if (externalData && externalData?.length > 0) {
					const records = externalData
						.filter(d => d.patient_record.length > 0 && d.external_id)
						.flatMap(d =>
							d.patient_record.map(record => ({
								...record,
								patient_id: d.external_id
							}))
						) as PatientRecord[];
					setFetchedData(records);
					return;
				}

				//Nothing found:				
				setFetchedData([]);
			} catch (error) {
				console.error('Error fetching data:', error);
				Sentry.captureException(error);
				setFetchedData([]);
			} finally {
				setIsLoading(false);
			}
		}, 500), [supabase, disabled, notId, lastNDays]
	);

	useEffect(() => {
		if (searchTerm && !disabled) {
			fetchResults(searchTerm);
		}
	}, [searchTerm, fetchResults, disabled]);

	const updateSearchTerm = (input: string) => {
		setSearchTerm(input);
	};

	const handleClick = (appointment: PatientRecord) => {
		if (disabled || !appointment) return

		if (onSelect) {
			onSelect(appointment);
		} else {
			navigate(`/appointments/${appointment.id}`, { state: { appointmentId: appointment.id } });
		}
		setSearchTerm("");
		setFetchedData([]);
		setSelectedAppointment(null);
	}

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			if (selectedAppointment) {
				handleClick(selectedAppointment);
				return;
			}

			if (fetchedData.length > 0) {
				handleClick(fetchedData[0]);
				return;
			}
		}
	};

	const renderLoading = useMemo(() => isLoading && <div className="px-4 py-[6px] text-gray-900">Loading...</div>, [isLoading]);

	const renderNoMatches = useMemo(() =>
		searchTerm && fetchedData.length === 0 && <div className="px-4 py-[6px] text-gray-900">No matches</div>, [searchTerm, fetchedData]);

	const renderOptions = useMemo(() =>
		searchTerm &&
		fetchedData.length > 0 &&
		fetchedData.map((appointment, idx) => (
			<ComboboxOption key={idx} value={appointment} as={React.Fragment}>
				{({ focus }) => {
					const formattedDate = moment(appointment?.scheduled_at).format("MM/DD/YY");
					const formattedTime = moment(appointment?.scheduled_at).format("h:mm A");
					return (
						<div
							className={`w-full cursor-default select-none px-4 py-[6px] ${focus ? "text-white bg-max-700" : "text-gray-900"}`}
							onMouseEnter={() => setSelectedAppointment(appointment)}
						>
							<div className="font-bold">{`${appointment?.patient_name ? appointment?.patient_name : '-'}`}</div>
							<div className={`flex gap-1 text-xs ${focus ? "text-white bg-max-700" : "text-gray-700"}`}>
								{appointment?.patient_id && <span>{`ID: ${appointment?.patient_id}`}</span>}
								{appointment?.patient_id && <span>-</span>}
								<span>{`${formattedDate}, ${formattedTime}`}</span>
							</div>
						</div>
					);
				}}
			</ComboboxOption >
		)), [searchTerm, fetchedData]);

	const renderComboboxOptions = () => (
		<>
			{renderLoading}
			{renderOptions}
			{renderNoMatches}
		</>
	);

	return (
		<div
			className={classNames(
				selectedTableRecords.length > 0 ? "hidden" : "grid w-full gap-2",
				verticalLayout ? "grid-cols-1" : "grid-cols-1",
				disabled ? "opacity-50 cursor-not-allowed" : "hover:cursor-pointer",
				"z-50 w-full",
			)}
		>
			{/* Search Icon Button for Small Screens */}
			{!isSearchBarVisible && (
				<div className="block md:hidden">
					<button
						onClick={toggleSearchBar}
						className="p-2 bg-white hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-max-700 ring-1 ring-inset rounded-md ring-gray-300"
					>
						<SearchIcon className="h-5 w-5 text-gray-600" aria-hidden="true" />
					</button>
				</div>
			)}

			{/* Search Bar for Small Screens */}
			{isSearchBarVisible && (
				<div className="relative flex items-center w-full md:hidden">
					<Combobox as="div" value={selectedAppointment} onChange={handleClick}>
						<form className="relative flex flex-1" onSubmit={(e) => e.preventDefault()}>
							<button
								type="button"
								onClick={toggleSearchBar}
								className="absolute inset-y-0 right-2 flex items-center rounded-r-md focus:outline-none"
							>
								<XIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
							</button>
							<ComboboxInput
								type="text"
								name="search"
								id="patient-search-input"
								value={searchTerm}
								placeholder="Search by Patient"
								onChange={(e) => updateSearchTerm(e.target.value)}
								onKeyDown={handleKeyDown}
								className="block w-48 w-full rounded-md border-0 py-[6px] pl-8 pr-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-max-700 sm:text-sm sm:leading-6"
								autoComplete="off"
							/>
							<SearchIcon className="absolute inset-y-0 left-2 top-2 h-5 w-5 text-gray-500" aria-hidden="true" />
							{searchTerm && (
								<ComboboxOptions className="absolute z-10 mt-1 top-full w-full overflow-auto rounded-md bg-white py-[6px] text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
									{renderComboboxOptions()}
								</ComboboxOptions>
							)}
						</form>
					</Combobox>
				</div>
			)}

			{/* Search Bar for Large Screens */}
			<div className="relative flex items-center w-full hidden md:flex">
				<SearchIcon className="absolute inset-y-0 left-2 h-5 w-5 text-gray-500" aria-hidden="true" />
				<Combobox as="div" value={selectedAppointment} onChange={handleClick}>
					<form className="relative flex flex-1" onSubmit={(e) => e.preventDefault()}>
						<ComboboxInput
							type="text"
							name="search"
							id="patient-search-input"
							value={searchTerm}
							placeholder="Search by Patient"
							onChange={(e) => updateSearchTerm(e.target.value)}
							onKeyDown={handleKeyDown}
							className="block w-48 w-full rounded-md border-0 py-[6px] pl-8 pr-4 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-max-700 sm:text-sm sm:leading-6"
							autoComplete="off"
						/>
						<SearchIcon className="absolute inset-y-0 left-2 top-2 h-5 w-5 text-gray-500" aria-hidden="true" />
						{searchTerm && (
							<ComboboxOptions className="absolute z-10 mt-1 top-full w-full overflow-auto rounded-md bg-white py-[6px] text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
								{renderComboboxOptions()}
							</ComboboxOptions>
						)}
					</form>
				</Combobox>
			</div>
		</div>
	);
};


export default SearchBar;
