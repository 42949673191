import AnnouncementIcon from "@icons/announcement-03.svg?react";


interface NotificationBannerProps {
    message: string;
    messageJsx?: React.ReactNode;
}

const NotificationBanner: React.FC<NotificationBannerProps> = ({ message, messageJsx }) => {
    return (
        <div className="flex items-center text-sm leading-5 font-semibold  bg-max-50 border-b border-gray-300 text-gray-700">
            <div className="flex items-center px-8 py-3 w-full max-w-screen-xl max-md:px-5 max-md:max-w-full">
                <AnnouncementIcon className="text-max-700 object-contain shrink-0 w-5 rounded-full aspect-square mr-4" />
                {messageJsx ?
                    messageJsx
                    : <p className="flex-1 min-w-[240px]">{message}</p>}
            </div>
        </div>
    );
};

export default NotificationBanner;