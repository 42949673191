import { FormattingOption } from "@/common/utils/types";

interface FormatStringProps {
    value: string;
    format: string;
}

/**
 * Render the value based on the format
 * @param props - the props containing value and format
 * @returns JSX.Element
 */
const FormatString: React.FC<FormatStringProps> = ({ value, format }) => {
    if (!value)
        return <></>;

    switch (format) {
        case FormattingOption.Paragraph:
            return <p className="text-sm text-gray-900 leading-5">{value}</p>;
        case FormattingOption.FlatList:
            return <p className="text-sm text-gray-900 leading-5">{value}</p>;
        case FormattingOption.Numbered:
            return <p className="text-sm text-gray-900 leading-5">1. {value}</p>;
        case FormattingOption.Dashed:
            return <p className="text-sm text-gray-900 leading-5">- {value}</p>;
        case FormattingOption.Bulleted:
            return <p className="text-sm text-gray-900 leading-5">• {value}</p>;
        case FormattingOption.None:
            return <></>;
        default:
            return <p className="text-sm text-gray-900 leading-5">{value}</p>;
    }
}

export default FormatString;
