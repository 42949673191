import { useCompletion } from "ai/react";
import { useEffect, useState } from "react";
import { useAccountContext } from "@providers/AccountProvider";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { addOrUpdateTab, resetProgress, setIsGenerating } from "../state/redux/activeDocumentsSlice";
import { VITE_HAPPYDOC_API_SERVER_URL } from "@common/utils/constants";
import * as Sentry from "@sentry/react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

/*
To generate a document the server needs to know 2 things:
- What information / transcription to use (patientRecordId)
- What type of document to generate (documentTypeId)

A client is responsible for the following:
- Sending the request to the server
- Handling events: 
	`document-created` 
	`document-updated` 
	`document` + isSuccess: true
	`document` + isSuccess: false 
*/

interface UseGenerateProps {
	setCompletionId: (value: string | null) => void;
}

const useGenerateDocuments = ({ setCompletionId }: UseGenerateProps) => {
	const { accountData } = useAccountContext();
	const dispatch = useAppDispatch();
	const [currentDocumentParams, setCurrentDocumentParams] = useState<any | null>(null);
	const isGenerating = useAppSelector((state) => state.activeDocuments.isGenerating);

	const navigate = useNavigate();
	const supabase = useSupabaseClient();

	// Completion hook, used in legacy generate api for streaming the completion over socket
	const {
		completion,
		complete,
		isLoading: isLoadingCompletion,
		stop,
		error,
	} = useCompletion({
		api: `${VITE_HAPPYDOC_API_SERVER_URL}/generate/push`,
	});

	// Handle error when the completion is not successful
	useEffect(() => {
		if (!currentDocumentParams || !error) return;

		const { tabId, body } = currentDocumentParams;

		// Handle 403 error
		if (error.message.includes("403") || error.message.includes("401")) {
			handle403Error(error, body, tabId);
			return;
		}

		handleError(error, tabId);

		return;
	}, [error]);

	/**
	 * Handles error
	 * @param error - error object
	 * @param tabId - tab id
	 * @param newChatMessage - new chat message
	 * @returns void
	 */
	const handleError = (error: any, tabId?: string) => {
		toast.error("Error generating document - First retry then refresh the page or login again");

		setCompletionId(null);
		setCurrentDocumentParams(null);

		if (tabId) {
			dispatch(addOrUpdateTab({ tabId: tabId, isLoading: false }));
			dispatch(resetProgress({ tabId: tabId }));
		}

		Sentry.captureException(error);

		return;
	}

	/**
	 * Handles success
	 * @param tabId - tab id
	 * @param newChatMessage - new chat message
	 * @returns void
	 */
	const handleSuccess = (tabId?: string) => {
		setCompletionId(null);
		setCurrentDocumentParams(null);

		dispatch(setIsGenerating(false));

		if (tabId) {
			dispatch(
				addOrUpdateTab({
					tabId: tabId,
					isLoading: false,
				}),
			);
			dispatch(resetProgress({ tabId: tabId }));
		}

		return;
	}

	/**
	 * Handles 403 error
	 * @param error - error object
	 * @param tabId - tab id
	 * @param newChatMessage - new chat message
	 * @param body - body of the request
	 * @returns void
	 */
	const handle403Error = async (error: any, body: any, tabId?: string) => {
		// refresh session and try again
		const { data: sessionData, error: refreshSessionError } = await supabase.auth.refreshSession()
		const { session } = sessionData

		Sentry.captureMessage("403 error", {
			level: "warning",
			extra: {
				message: error.message,
				session: session,
			}
		})

		if (refreshSessionError) {
			handleError(refreshSessionError, tabId);
			supabase.auth.signOut();
			navigate("/");
		}

		let retryData = await complete("", {
			body: body,
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${session?.access_token}`,
			},
		})

		//Update Data
		if (retryData) {
			handleSuccess(tabId);
		}

		if (!retryData && error) {
			handleError(error, tabId);
		}

		return;
	}

	/**
	 * Generate a document using the legacy API
	 * Streams the document to the client over socket
	 * @param chatId 
	 * @param patientRecordId 
	 * @param transcriptionId 
	 * @param documentId 
	 * @param multiplePatientCase 
	 * @param patientNameToParse 
	 * @param fastGenerate 
	 */
	const generateLegacyDocument =
		async (
			tabId: string,
			chatId: string,
			patientRecordId: string,
			transcriptionId: string,
			documentId: string,
			multiplePatientCase: boolean = false,
			patientNameToParse: string = "",
			fastGenerate: boolean = false
		) => {
			try {
				// get session
				const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
				const { session } = sessionData

				if (sessionError) {
					handleError(sessionError, tabId);
					return;
				}

				const body = {
					chatId: chatId,
					documentTypeIds: [documentId],
					transcriptionId: transcriptionId,
					userId: accountData?.id ? accountData?.id : sessionData?.session?.user?.id,
					patientRecordId: patientRecordId,
					respondAsStream: true,
					multiplePatientCase: multiplePatientCase,
					patientNameToParse: patientNameToParse,
					fast: fastGenerate,
				};

				// call generate Documents API
				let data = await complete("", {
					body: body,
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${session?.access_token}`,
					},
				})

				return;

			} catch (e: any) {
				handleError(e, tabId);
				return;
			}
		};

	/**
	 * Enqueues generation of the provided document types for the provided patient record
	 * @param patientRecordId 
	 * @param transcriptionId 
	 * @param documentIds 
	 * @param multiplePatientCase 
	 * @param patientNameToParse 
	 * @param fastGenerate 
	 */
	const generateDocuments =
		async (
			patientRecordId: string,
			transcriptionId: string,
			documentIds: string[],
			multiplePatientCase: boolean = false,
			patientNameToParse: string = "",
			fastGenerate: boolean = false
		) => {
			try {
				// get session
				const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
				const { session } = sessionData

				if (sessionError) {
					throw sessionError;
				}

				const body = {
					documentTypeIds: documentIds,
					transcriptionId: transcriptionId,
					userId: accountData?.id ? accountData?.id : sessionData?.session?.user?.id,
					patientRecordId: patientRecordId,
					multiplePatientCase: multiplePatientCase,
					patientNameToParse: patientNameToParse,
					fast: fastGenerate,
				};

				// call generate Documents API
				await fetch(`${VITE_HAPPYDOC_API_SERVER_URL}/generate/push`, {
					method: "POST",
					body: JSON.stringify(body),
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${session?.access_token}`,
					},
				})

				return;

			} catch (e: any) {
				handleError(e);
				return;
			}
		};

	/**
	 * Cancels generation of a document, given the patient record and document type
	 * @param patientRecordId 
	 * @param documentTypeId 
	 */
	const stopGenerate = async (patientRecordId: string, documentTypeId: string) => {
		if (!patientRecordId || !documentTypeId) return;

		try {
			const { data: sessionData, error: sessionError } = await supabase.auth.getSession();
			const { session } = sessionData

			if (sessionError) {
				throw sessionError;
			}

			const body = {
				patientRecordId,
				documentTypeId,
			};

			// call generate Documents API
			await fetch(`${VITE_HAPPYDOC_API_SERVER_URL}/generate/cancel`, {
				method: "POST",
				body: JSON.stringify(body),
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${session?.access_token}`,
				},
			})

			return;

		} catch (e: any) {
			Sentry.captureException(e);
			return;
		}
	}


	return {
		generateLegacyDocument,
		generateDocuments,
		stopGenerate,
		stop,
		completion,
		isLoadingCompletion: isGenerating || isLoadingCompletion,
	};
};
export default useGenerateDocuments;
