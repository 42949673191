import classNames from "classnames";
import { TableTab } from "@common/utils/types";

/**
 * Reusable Component for tabs in the app
 * @param tabs - Array of tabs 
 * @param changeTab - Function to change the tab
 * @param disabled - Whether the tabs are disabled
 * @returns JSX.Element
 */
function TableTabs({
	tabs,
	changeTab,
	disabled,
	paddingVertical = "py-3",
	fullWidth = false,
	tabRefs,
}: {
	tabs: TableTab[];
	changeTab: (tab: string) => void;
	disabled?: boolean;
	paddingVertical?: string;
	fullWidth?: boolean;
	tabRefs?: React.RefObject<HTMLButtonElement>[];
}) {

	return (
		<div className="mt-1 sm:mt-2 flex flex-grow  w-full xl:w-auto justify-between">
			<div className="sm:block grow flex-shrink-0">
				<div className={classNames("w-full border-b border-gray-200 ")}>
					<nav className="-mb-px  flex justify-start space-x-2 sm:space-x-4" aria-label="Tabs">
						{tabs.map((tab, index) => (
							<button
								id={`tab-${tab.id}`}
								disabled={disabled}
								key={tab.id}
								onClick={() => changeTab(tab.id)}
								className={classNames(
									tab.isActive
										? "border-max-700 text-max-700 cursor-default"
										: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 cursor-pointer",
									paddingVertical,
									" group inline-flex items-center border-b-2 px-2 sm:px-4 text-sm gap-x-1 font-semibold",
									fullWidth ? "w-full justify-center" : "w-auto",
									"focus:outline-none"
								)}
								aria-current={tab.isActive ? "page" : undefined}
								// optional tabRefs prop to store refs for each tab for auto scrolling
								ref={tabRefs ? tabRefs[index] : null}
							>
								{tab?.icon ? <tab.icon /> : null}
								<span className="relative text-sm font-semibold">{tab.header}

									{tab?.error && (
										<div className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 h-2 w-2 bg-red-600 rounded-full" />
									)}
								</span>

								{tab?.count !== undefined ? <span
									className={classNames(
										tab.isActive ? "bg-max-50 text-max-700 border border-max-200" : "bg-gray-50 text-gray-700 border border-gray-200",

										"flex text-center items-center p-2 justify-center rounded-full h-4 w-4 sm:h-6 sm:w-6 text-xs font-semibold",
									)}
								>
									{tab.count}
								</span> : null}

							</button>
						))}
					</nav>
				</div>
			</div>
		</div>
	);
}

export default TableTabs;
