import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { useDataContextApi } from "@providers/DataProvider";
import { PatientRecord, RecordingState, PatientStage, TableTab } from "@common/utils/types";
import EditAppointmentModal from "./components/modals/EditAppointmentModal";
import HelpDocumentation from "@common/components/HelpDocumentation";
import TableRow from "./components/TableRow";
import TableTabs from "@common/components/HDTabs";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import {
	selectTableRecordStatus,
	selectSortedColumn,
	selectCurrentStage,
	selectSortedTableRecordsByStage,
	setSortedColumn,
	changeCurrentStage,
	addSelectedTableRecords,
	resetSelectedTableRecords,
	selectFinalizedRecordCount,
	selectOngoingRecordCount,
	selectReviewRecordCount,
} from "@redux/patientTableRecordsSlice";
import { useAudioRecordingProvider } from "@features/list-view/state/recordingProvider";
import { useLocation } from "react-router-dom";
import Spinner from "@/common/components/Spinner";
import TableColumns from "./components/TableColumns";

const PatientTable = () => {
	const { refreshPatientRecords, isLoadingData, selectedDate } = useDataContextApi();
	const location = useLocation();
	const { selectedDoctor } = useAppSelector((state) => state.doctors);
	const { selectedTableRecords } = useAppSelector((state) => state.patientTableRecords);
	const {
		recorderControls: { recordingState, activeRecordingId, isQuickStart },
	} = useAudioRecordingProvider();
	const dispatch = useAppDispatch();

	//Redux selectors
	const recordsToDisplay = useAppSelector(selectSortedTableRecordsByStage);
	const recordStatus = useAppSelector(selectTableRecordStatus);
	const sortedColumn = useAppSelector(selectSortedColumn);
	const currentStage = useAppSelector(selectCurrentStage);
	const ongoingCount = useAppSelector(selectOngoingRecordCount);
	const reviewCount = useAppSelector(selectReviewRecordCount);
	const finalizedCount = useAppSelector(selectFinalizedRecordCount);

	useEffect(() => {
		refreshPatientRecords();
	}, [location, selectedDate]);

	//Local State
	const [isEditAppointmentModalOpen, setIsEditAppointmentModalOpen] = useState(false);
	const [selectedTableRecord, setSelectedTableRecord] = useState<any>(null);
	const [tabs, setTabs] = useState<TableTab[]>([
		{
			header: "Ongoing",
			isActive: currentStage === PatientStage.Ongoing,
			id: PatientStage.Ongoing,
			count: ongoingCount
		},
		{
			header: "Review",
			isActive: currentStage === PatientStage.Review,
			id: PatientStage.Review,
			count: reviewCount,
		},
		{
			header: "Finalized",
			isActive: currentStage === PatientStage.Finalized,
			id: PatientStage.Finalized,
			count: finalizedCount,
		},
	]);

	// seems excessive to have this useEffect, but it's necessary to update the tabs when the counts change
	// but need a better way, this will come with the data grid refactor
	useEffect(() => {
		setTabs([
			{
				header: "Ongoing",
				isActive: currentStage === PatientStage.Ongoing,
				id: PatientStage.Ongoing,
				count: ongoingCount
			},
			{
				header: "Review",
				isActive: currentStage === PatientStage.Review,
				id: PatientStage.Review,
				count: reviewCount,
			},
			{
				header: "Finalized",
				isActive: currentStage === PatientStage.Finalized,
				id: PatientStage.Finalized,
				count: finalizedCount,
			},
		]);
	}, [ongoingCount, reviewCount, finalizedCount, currentStage, recordsToDisplay]);

	/**
	 * Sort the appointments by the column name
	 * @param columnName - The column name to sort by
	 */
	const handleSortAppointments = (columnName: string) => {
		if (sortedColumn.column === columnName) {
			dispatch(setSortedColumn({ column: columnName, direction: sortedColumn.direction === "asc" ? "desc" : "asc" }));
		} else {
			dispatch(setSortedColumn({ column: columnName, direction: "asc" }));
		}
	};

	/**
	 * Select all the records in the table
	 * @param isChecked - Whether the checkbox is checked
	 */
	const handleSelectAll = (isChecked: boolean) => {
		if (isChecked) {
			dispatch(addSelectedTableRecords(recordsToDisplay));
		} else {
			dispatch(resetSelectedTableRecords());
		}
	};

	/**
	 * Handle the edit button click
	 * @param patientTableRecord - The patient table record
	 */
	const handleEditButtonClick = (patientTableRecord: PatientRecord) => {
		setSelectedTableRecord(patientTableRecord);
		setIsEditAppointmentModalOpen(true);
	};

	/**
	 * Change the tab and update the current stage in redux
	 * @param id - The id of the tab
	 */
	const changeTab = (id: string) => {

		setTabs((prev) =>
			prev.map((t) => {
				if (t.id === id) {
					return { ...t, isActive: true, };
				} else {
					return { ...t, isActive: false, };
				}
			}),
		);
		dispatch(changeCurrentStage(id as PatientStage));
	};

	const isRecording = useMemo(() => {
		return recordingState === RecordingState.recording || recordingState === RecordingState.paused;
	}, [recordingState]);

	return (
		<div className="w-full shadow-sm  bg-gray-50 shadow-sm ring-1 ring-gray-200   rounded-md ">
			<div className="w-full bg-gray-50 ring-gray-200 rounded-md pt-1">
				<div className="flex-col justify-evenly w-full bg-gray-50">
					<div
						className={classNames(
							selectedDoctor?.doctor_name ? "top-0" : "top-0",
							"absolute hidden  top-2 left-[70px] z-30",
						)}
					>
						<HelpDocumentation
							id="patient-list-help-documentation"
							link="https://help.gohappydoc.com/en/articles/8858669-success-with-list-view"
							isDisabled={isRecording}
							displayText="Patient List Help"
						/>
					</div>

					<div className="px-4 py-3 bg-gray-50">
						<h1>Visits</h1>
					</div>
				</div>
			</div>
			<div className="w-full bg-white ring-gray-200 ring-1 rounded-b-md shadow-sm ">
				<div className="w-full px-4 ring-gray-200 ring-1  ">
					<TableTabs
						tabs={tabs}
						changeTab={changeTab}
						disabled={isRecording}

					/>
				</div>
				<div className="bg-gray-50  ring-gray-200 ring-1 rounded-b-md pb-1">
					<table className="w-full divide-y divide-gray-200 rounded-b-md ">
						<TableColumns
							recordsToDisplay={recordsToDisplay}
							selectedTableRecords={selectedTableRecords}
							isRecording={isRecording}
							handleSelectAll={handleSelectAll}
							sortedColumn={sortedColumn}
							handleSortAppointments={handleSortAppointments}
						/>
						<tbody className="divide-y divide-gray-200 bg-white rounded-b-md ">
							{recordStatus === "loading" || isLoadingData ? (
								<tr>
									<td colSpan={100} className="text-center py-5">
										<div className="h-5 w-5 m-auto">
											<Spinner />
										</div>
									</td>
								</tr>
							) : recordStatus === "succeeded" && recordsToDisplay?.length > 0 ? (
								recordsToDisplay?.map((patient: PatientRecord, index) => (
									<TableRow
										key={patient.id}
										patient={patient}
										handleEditButtonClick={handleEditButtonClick}
										recordingState={recordingState}
										activeRecordingId={activeRecordingId}
										isQuickStart={isQuickStart}
										index={index}
									/>
								))
							) : recordStatus === "succeeded" && recordsToDisplay?.length === 0 ? (
								<tr>
									<td colSpan={100} className="text-sm text-center py-5">
										<p>No Records</p>
									</td>
								</tr>
							) : (
								<tr>
									<td colSpan={100} className="text-center text-sm font-semibold py-5 text-red-400">
										<p>Something went wrong. Please try refreshing!</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			</div>
			<EditAppointmentModal
				isOpen={isEditAppointmentModalOpen}
				onClose={() => {
					setIsEditAppointmentModalOpen(false);
					setSelectedTableRecord(null);
				}}
				patientTableRecord={selectedTableRecord}
			/>
		</div>
	);
};

export default PatientTable;
