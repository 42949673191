import { PatientRecord } from '@/common/utils/types';
import TableHeaderBtn from './buttons/TableHeaderBtn';
import { SortedColumn } from '@/state/redux/patientTableRecordsSlice';

interface TableColumnsProps {
    recordsToDisplay: PatientRecord[];
    selectedTableRecords: PatientRecord[];
    isRecording: boolean;
    handleSelectAll: (checked: boolean) => void;
    sortedColumn: SortedColumn;
    handleSortAppointments: (columnName: string) => void;
}

const TableColumns: React.FC<TableColumnsProps> = ({
    recordsToDisplay,
    selectedTableRecords,
    isRecording,
    handleSelectAll,
    sortedColumn,
    handleSortAppointments,
}) => {
    return (
        <thead className="bg-gray-50 select-none ">
            <tr>
                <th className=" pl-4 py-3 text-sm font-medium text-gray-600 ">
                    {recordsToDisplay && recordsToDisplay?.length > 0 && (
                        <input
                            type="checkbox"
                            disabled={isRecording}
                            checked={selectedTableRecords.length === recordsToDisplay.length}
                            onChange={(e) => handleSelectAll(e.target.checked)}
                            className="form-checkbox h-5 w-5 text-max-700 border-gray-200 rounded focus:ring-max-700"
                        />
                    )}
                </th>
                <th
                    scope="col"
                    className="truncate text-wrap py-3 pl-2 pr-5 text-left text-sm font-medium text-gray-600  w-auto"
                >
                    <div className="hidden sm:table-cell">
                        <TableHeaderBtn
                            text="Patient Name"
                            columnName="patient_name"
                            sortedColumn={sortedColumn}
                            handleClick={handleSortAppointments}
                        />
                    </div>
                    <div className="table-cell sm:hidden">
                        <TableHeaderBtn
                            text="Details"
                            columnName="scheduled_at"
                            sortedColumn={sortedColumn}
                            handleClick={handleSortAppointments}
                        />
                    </div>
                </th>

                <th
                    scope="col"
                    className="hidden px-3 md:px-5 py-3 text-left text-sm font-medium text-gray-600  sm:table-cell w-auto max-w-sm"
                >
                    <TableHeaderBtn
                        text="Scheduled"
                        columnName="scheduled_at"
                        sortedColumn={sortedColumn}
                        handleClick={handleSortAppointments}
                    />
                </th>
                <th
                    scope="col"
                    className="hidden lg:table-cell px-3 md:px-5 py-3 text-left text-sm font-medium text-gray-600 "
                >
                    Doctor
                </th>
                <th
                    scope="col"
                    className="hidden px-3 md:px-5 py-3 text-left text-sm sm:text-sm font-medium text-gray-600  lg:table-cell w-auto"
                >
                    State
                </th>

                <th
                    scope="col"
                    className="hidden sm:table-cell  px-3 md:px-5 py-3 text-left text-sm sm:text-sm  font-medium text-gray-600  w-auto "
                >
                    Stage
                </th>
            </tr>
        </thead>
    );
};

export default TableColumns;
