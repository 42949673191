import { createSlice, createAsyncThunk, PayloadAction, createSelector } from "@reduxjs/toolkit";
import { fetchPatientRecord } from "@common/lib/supabaseClient";
import { setAllDocuments, computeTabs, setActivePatientId } from "./activeDocumentsSlice";
import { PatientRecord, TranscriptSection, PatientRecordFetchErrors } from "@common/utils/types";
import { ActivePatientStatus, ActivePatientData, MultiPatientData } from "@common/utils/types";
import * as Sentry from "@sentry/react";
import { RootState } from "@/app/store";

const initialState: ActivePatientData = {
	patientData: null,
	transcriptSections: [],
	multiplePatients: [],
	status: ActivePatientStatus.Idle,
	error: null,
};

const activePatientDataSlice = createSlice({
	name: "activePatientData",
	initialState,
	reducers: {
		setPatientData(state, action) {
			state.patientData = action.payload;
		},
		setTranscriptSections(state, action) {
			state.transcriptSections = action.payload;
		},
		setErrorState(state, action) {
			state.status = ActivePatientStatus.Failed;
			state.error = action.payload;
		},
		setMultiplePatients(state, action) {
			state.multiplePatients = action.payload;
		},
		setSelectedMultiPatient: (state, action: PayloadAction<MultiPatientData>) => {
			state.multiplePatients[action.payload.index].isSelected = !action.payload.isSelected;
		},
		updatePatientData: (state, action: PayloadAction<PatientRecord>) => {
			if (!state.patientData || action?.payload?.id !== state?.patientData?.id) return;
			let newPatientName = action?.payload?.patient_name || "";
			let isMultiplePatients = checkForMultiplePatientNames(newPatientName);
			if (isMultiplePatients.length > 0) {
				state.multiplePatients = isMultiplePatients;
			}

			state.patientData = { ...state.patientData, ...action.payload };
		},
		updateOrAddTranscriptSection: (
			state,
			action: PayloadAction<{ transcriptId: string; transcriptSection: TranscriptSection }>,
		) => {
			//Don't update if there is no patient data (i.e we're not in the patient View)
			if (!state.patientData) return;
			//Requirement for realtime. Don't add transcripts not in the current patient record
			if (state?.patientData?.transcriptions?.id !== action.payload.transcriptId) return;
			//Else update the transcript section
			const index = state.transcriptSections.findIndex(
				(section) => section.id === action.payload.transcriptSection?.id,
			);
			if (index !== -1) {
				state.transcriptSections[index] = { ...state.transcriptSections[index], ...action.payload.transcriptSection };
			} else {
				state.transcriptSections.push(action.payload.transcriptSection);
			}
		},
		removeTranscriptSections: (state, action: PayloadAction<string[]>) => {
			// Removes transcript sections whose ids are in the action.payload array
			state.transcriptSections = state.transcriptSections.filter((section) => !action.payload.includes(section.id));
		},
		resetActivePatientData: () => initialState,
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchPatientData.pending, (state) => {
				state.status = ActivePatientStatus.Loading;
			})
			.addCase(fetchPatientData.fulfilled, (state, action) => {
				state.status = ActivePatientStatus.Succeeded;
			})
			.addCase(fetchPatientData.rejected, (state) => {
				state.status = ActivePatientStatus.Failed;
				state.error = PatientRecordFetchErrors.FETCH_FAILED;
			});
	},
});

//Actions
export const {
	setTranscriptSections,
	setErrorState,
	setPatientData,
	setMultiplePatients,
	updatePatientData,
	setSelectedMultiPatient,
	resetActivePatientData,
	updateOrAddTranscriptSection,
	removeTranscriptSections,
} = activePatientDataSlice.actions;
//Selectors

export const getPatientData = (state: RootState) => state.activePatientData.patientData;
export const getPatientRecordFetchStatus = (state: RootState) => state.activePatientData.status;
export const getPatientRecordFetchError = (state: RootState) => state.activePatientData.error;
export const getPatientStage = (state: RootState) => state.activePatientData.patientData?.stage;
export const getPatientId = (state: RootState) => state.activePatientData.patientData?.id;
export const getTranscriptSections = (state: RootState) => state.activePatientData.transcriptSections;
export const getMultiplePatients = (state: RootState) => state.activePatientData.multiplePatients;

export const isPatientRecordActive = createSelector(
	[getPatientData, (state: RootState, patientRecordId: string) => patientRecordId],
	(patientData, patientRecordId) => patientData?.id === patientRecordId,
);

//Async Thunks
export const fetchPatientData = createAsyncThunk(
	"activePatientData/fetchPatientData",
	async (patientRecordId: string, { dispatch }) => {
		try {
			const { data, error } = await fetchPatientRecord(patientRecordId);
			if (!data && error === PatientRecordFetchErrors.NO_PATIENT_RECORD) {
				dispatch(setErrorState(PatientRecordFetchErrors.NO_PATIENT_RECORD));
			} else if (!data && error === PatientRecordFetchErrors.FETCH_FAILED) {
				dispatch(setErrorState(PatientRecordFetchErrors.FETCH_FAILED));
			} else {
				dispatch(setAllDocuments(data?.chat_completions || []));
				dispatch(computeTabs());
				dispatch(setTranscriptSections(data?.transcriptions?.transcript_sections || []));
				//Remove unnecessary data.
				let multiplePatientNames = checkForMultiplePatientNames(data?.patient_name || "");
				if (multiplePatientNames.length > 0) {
					dispatch(setMultiplePatients(multiplePatientNames));
				}
				dispatch(setPatientData(data));

				// set active patient id in activeDocumentsSlice
				dispatch(setActivePatientId(data?.id || ""));
			}
		} catch (error) {
			Sentry.captureException("Patient record fetch failed.");
			dispatch(setErrorState(PatientRecordFetchErrors.FETCH_FAILED));
		}
	},
);

// This thunk is used to handle the patient event broadcasted from realtime
export const handleActivePatientBroadcast = createAsyncThunk(
	"activePatientData/handleActivePatientBroadcast",
	async ({ patientRecordId }: { patientRecordId: string }, { dispatch, getState }) => {
		const state = getState() as RootState;
		const { patientData } = state.activePatientData;

		// if there is no patient data or the patient data is not the same as the patient record id, return null
		if (!patientData || patientData.id !== patientRecordId) {
			return null;
		}

		// get the record from the db
		const { data: patientRecord, error } = await fetchPatientRecord(patientRecordId);

		// if there is an error or no patient record, return null
		if (error || !patientRecord) {
			return null;
		}

		// update the patient data in the state
		dispatch(updatePatientData(patientRecord));

		return patientRecord;
	},
);

export function checkForMultiplePatientNames(patientName: string): MultiPatientData[] {
	// Splitting by "and" using a case-insensitive regular expression
	let splitNames = patientName.split(/\s(?:&|and)\s/i);

	let output: MultiPatientData[] = [];

	if (splitNames.length >= 2) {
		// Further split the first part (if it contains a comma)
		if (splitNames[0].includes(',')) {
			const firstPartNames = splitNames[0].split(',').map(name => name?.trim() || '');
			// Combine the split names
			splitNames = [...firstPartNames, splitNames[1]?.trim() || ''];
		}

		let i = 0;

		splitNames.forEach((name) => {
			let newPatient: MultiPatientData = { name: name?.trim() || "", isSelected: true, index: i++ };
			output.push(newPatient);
		})

		if (splitNames.length >= 2) {
			return output;
		}
	}

	return []; // Return an empty array if no multiple patient names are found
}

export default activePatientDataSlice.reducer;
