import { useLocation, useNavigate } from "react-router-dom";
import Navbar from "../features/navigation/Navbar";
import { useEffect, useState } from "react";
import { ENDPOINTS } from "../common/utils/url-registry";
import { Subscription } from "../common/utils/types";
import { useSession } from "@supabase/auth-helpers-react";
import { useAccountContext } from "@providers/AccountProvider";
import CacheBuster from "@providers/CacheBuster";
import classNames from "classnames";
import { supabase } from "@/common/lib/supabaseClient";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import NotificationBanner from "@/common/components/NotificationBanner";
import CompleteProfileModal from "@/common/components/CompleteProfileModal";

type Props = {
	children: React.ReactElement | React.ReactElement[];
	allowedPermissions?: string[];
};

/**
 * Check if the user has access to the self-serve features
 * @param {Subscription} subscription - The subscription data
 * @returns {boolean}
 */
function checkAccess(subscription: Subscription) {
	const now = new Date();
	const currentPeriodEnd = new Date(subscription.current_period_end);

	// user should have access if the current date is before the end of the current period
	// and if the subscription is not marked as ended
	return now < currentPeriodEnd && !subscription.ended_at;
}

function getDaysLeftInTrial(endDate: string) {
	const trialEndDate = new Date(endDate);
	const now = new Date();

	// Get the difference in milliseconds
	const diffInMilliseconds = trialEndDate.getTime() - now.getTime();

	// Convert the difference from milliseconds to days
	return Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
}


/**
 * Wrapper component to handle permissions
 * @param {Props} props - The props
 * @returns {JSX.Element}
 */
function PermissionWrapper({ children, allowedPermissions }: Props) {
	const session = useSession();
	const { accountData, subscription, dataLoaded } = useAccountContext();
	const [user, setUser] = useState<any>(null);
	const [daysLeftInTrial, setDaysLeftInTrial] = useState<number | null>(null);
	const navigate = useNavigate();
	const location = useLocation();

	// fetch the user data specifically for the banned_until field
	useEffect(() => {
		const fetchUser = async () => {
			const { data: { user }, error } = await supabase.auth.getUser()

			if (error) {
				Sentry.captureException(error);
				return null
			}

			if (!user) {
				return null
			}

			setUser(user);
		};

		fetchUser();
	}, [location]);

	// Get the notification banner message from the environment variables, and append the days left in the trial
	// if there is a trial days left we gotta pass JSX for the link to contact us
	let notificationBannerMessage = import.meta.env.VITE_NOTIFICATIONS_BANNER_MESSAGE || ""
	let notificationBannerJsx = null;
	if (daysLeftInTrial) {
		notificationBannerJsx =
			<div className="flex-1 min-w-[240px]">
				<p>Your free trial ends in {daysLeftInTrial} {daysLeftInTrial !== 1 ? "days" : "day"}! {" "}
					<button
						className="font-semibold text-sm text-max-700  hover:text-max-700"
						onClick={() => window.open("https://calendly.com/d/cppv-jqg-33j/happydoc-account-upgrade", "_blank")}>
						Contact us
					</button> to keep your account active.</p>
				{notificationBannerMessage && <p>{notificationBannerMessage}</p>}
			</div>
	}

	// Check if the user has access to the self-serve features and redirect if not
	useEffect(() => {
		// If the user is not logged in, do not proceed
		if (!accountData || !session) {
			return;
		}

		// If the user is banned, log them out and redirect to the login page
		if (user && user?.banned_until) {
			const bannedAtDate = new Date(user?.banned_until);
			const currentDate = new Date();

			if (bannedAtDate.getTime() > currentDate.getTime()) {
				toast.warning('Account is deactivated. Contact support@happydoc.ai.');
				supabase.auth.signOut();
				navigate(ENDPOINTS.Login);
				return;
			}
		}

		// check for trial ended
		// they will get banned via a cron job when their trial ends
		if (accountData.is_self_serve && accountData.trial_end_date) {
			const daysLeft = getDaysLeftInTrial(accountData.trial_end_date);
			setDaysLeftInTrial(daysLeft);
		}

		// If the account and subscription data is not loaded, OR the user is not self-serve, do not proceed
		if (!dataLoaded || !accountData?.is_self_serve) return;

		// TODO: uncomment for payed self-serve
		// // If the user doesn't have a subscription, redirect to the payment page
		// if (!subscription) {
		// 	navigate(ENDPOINTS.Payment);
		// 	return;
		// }

		// // If the user is not self-serve, OR the subscription is active and >0 days remaining in trial, do not redirect
		// if (!subscription.is_self_serve || subscription.status === "active") {
		// 	return;
		// }

		// // If the user doesn't have access, redirect to the payment page
		// if (!checkAccess(subscription)) {
		// 	navigate(ENDPOINTS.Payment);
		// 	return;
		// }
	}, [dataLoaded, subscription, accountData, navigate, session, user]);

	return (
		<>
			<CacheBuster>
				<div>
					<div className="fixed left-0 right-0 top-0 z-40">
						{/* Notification Banner */}
						{(notificationBannerMessage || notificationBannerJsx) &&
							<NotificationBanner message={notificationBannerMessage} messageJsx={notificationBannerJsx} />
						}
						{/* Top Nav Bar */}
						<Navbar />
					</div>
					{/* Main content */}
					<main className={classNames((notificationBannerMessage || notificationBannerJsx) ? "pt-32 sm:pt-24 lg:pt-20 h-full mb-5 p-4" : "p-4 h-full")}>
						<div className=" h-full">{children}</div>
						<div className="hidden happydoc-account-id">{accountData?.id}</div>
					</main>
				</div>
				<CompleteProfileModal />
			</CacheBuster>
		</>
	);
}

export default PermissionWrapper;
