import { Fragment } from "react";
import SyncConfirmation from "../sidebar/SyncConfirmation";
import { useState } from "react";
import { ChatCompletions, DocumentStatus, STREAMING_COMPLETION_ERROR } from "@common/utils/types";
import ContentIsEditing from "./ContentIsEditing";
import { ErrorBoundary } from "@sentry/react";
import ProgressBar from "@/common/components/ProgressBar";
import SendIcon from "@icons/send-01.svg?react";
import HDDCopyButton from "@/common/components/HDCopyButton";
import { useDataContextApi } from "@/state/providers/DataProvider";

interface ContentProps {
	content: string | null;
	isEditing: boolean;
	newHeader: string;
	setNewHeader: any;
	newContent: string;
	setNewContent: any;
	copyAsMarkdown: boolean;
	activeDocument: ChatCompletions | null;
	tabId: string;
}

const Content = ({
	content,
	isEditing,
	newHeader,
	setNewHeader,
	newContent,
	setNewContent,
	copyAsMarkdown,
	activeDocument,
	tabId,
}: ContentProps) => {

	const [open, setOpen] = useState(false);
	//Formatting for bold
	const { sectionsAndFieldsToBold } = useDataContextApi();
	// if no content, show a progress bar if the document is processing
	if (!content || typeof content !== "string") {
		return (
			<div className="p-4">
				{activeDocument?.status === DocumentStatus.Processing ? <ProgressBar seconds={60} tabId={tabId} isJson={false} /> : null}
			</div>
		)
	}

	//Handles copying individual section, removes the header.
	const copySectionToClipboard = (text: string) => {
		const lines = text.split("\n");
		let processedLines;
		// Process each line
		if (copyAsMarkdown) {
			processedLines = lines.map((line) => {
				if (!line) {
					return null;
				}
				// Prepare the line for comparison by converting it to lowercase and removing colons
				const lineForComparison = line.toLowerCase().replace(/:/g, "")?.trim();
				const lineToRemove = line.toLowerCase().replace(/:/g, "").replace(/\*/g, "")?.trim();
				if (sectionsAndFieldsToBold?.has(lineForComparison)) {
					// Skip this line by returning null
					return null;
				}
				// Check if the processed line is in the creatorSectionNames set and not already wrapped in asterisks
				if (sectionsAndFieldsToBold.has(lineForComparison) && !line.startsWith("**") && !line.endsWith("**")) {
					// Wrap the line in asterisks for Markdown
					return `**${line}**`;
				}
				return line;
			});
		} else {
			processedLines = lines.map((line) => {
				// Remove asterisks from the line, whether or not it's in creatorSectionNames
				const cleanedLine = line?.replace(/\*\*/g, "");
				const lineToRemove = line?.toLowerCase()?.replace(/:/g, "")?.replace(/\*/g, "")?.trim();

				//If the line is a header and we're copying a section. Skip the line
				if (sectionsAndFieldsToBold?.has(lineToRemove)) {
					// Skip this line by returning null
					return null;
				}
				return cleanedLine;
			});
		}
		processedLines = processedLines.filter((line) => line !== null);
		// Join the processed lines back into a single string
		const processedText = processedLines?.join("\n") || "";
		// Use navigator.clipboard.writeText to copy the processed text to the clipboard
		navigator.clipboard.writeText(processedText);
	};

	//Remove Markdown formatting if exists.
	const sections = content?.trim()?.split("\n\n");

	const checkAndBold = (line: string) => {
		// Initialize an array to hold JSX elements
		let elements: any = [];
		if (line === STREAMING_COMPLETION_ERROR) {
			elements.push(
				<strong className="text-sm leading-5" key={STREAMING_COMPLETION_ERROR} style={{ color: "#f08080" }}>
					{line}
				</strong>,
			);
			return <>{elements}</>;
		}
		// Split the line by asterisks to find bold parts
		let parts = line.split("**");

		// Iterate over the parts and construct JSX elements
		parts.forEach((part, index) => {
			// Every odd part is a bold part according to Markdown syntax
			if (index % 2 === 1) {
				elements.push(<strong className="text-sm  text-gray-900 leading-5" key={index}>{part}</strong>);
			} else {
				elements.push(part);
			}
		});

		// Check if the whole line (without asterisks) is in updatedCreatorSectionNames
		const lineWithoutAsterisks = parts.join("");

		if (
			sectionsAndFieldsToBold.has(lineWithoutAsterisks?.toLowerCase()?.replace(/:/g, "")?.trim()) &&
			elements.length === 1
		) {
			// If the whole line is a match and it wasn't split by asterisks, wrap it in <strong>
			elements = [<strong className="text-sm  text-gray-900 leading-5" key="0">{lineWithoutAsterisks}</strong>];
		}

		// Return the elements wrapped in a fragment
		return <>{elements}</>;
	};

	// Process each section when there are two or more sections
	const processedSections = sections?.map((section: any, index: number) => {
		if (!section) {
			return null;
		}
		const lines = section?.split("\n");
		//Going to include header now as well.
		const textToCopy = lines.slice(0).join("\n");

		return (
			<Fragment key={index}>
				<div key={index} className="flex gap-1 flex-row w-full px-4 py-3">
					<div className="w-full flex-1">
						<div className="text-sm  text-gray-900 leading-5">{checkAndBold(lines[0])}</div>
						<div className="text-sm  text-gray-900 leading-5">
							{lines.slice(1).map((line: string, lineIndex: number) => (
								<div key={lineIndex}>{checkAndBold(line)}</div>
							))}
						</div>
					</div>
					<HDDCopyButton
						id={`copy-section-${section?.id}`}
						onCopy={() => copySectionToClipboard(textToCopy)}
					/>
				</div>
				<div className="my-2 flex-row justify-end gap-2 px-4 pb-3 hidden happydoc-ext-section-btns">
					<button
						type="button"
						className="inline-flex items-center gap-x-1.5 rounded bg-white px-2 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 happydoc-exportbtn"
						data-happydoc-id="happydoc-exportbtn"
						data-happydoc-content={lines.slice(1).join("\n")}
						data-happydoc-section={lines[0]}
					>
						<SendIcon className="w-4 h-4" />
						Approve & Sync
					</button>
				</div>
				{index < sections.length - 1 && <hr className="" />} {/* Divider only between sections */}
			</Fragment>
		);
	});

	return (
        (<ErrorBoundary fallback={<h3>Something went wrong. Please Retry!</h3>}>
            {activeDocument?.status === DocumentStatus.Processing ? <div className="px-4 py-3"><ProgressBar seconds={60} tabId={tabId} isJson={false} /></div> : null}
            {isEditing ? ( // If the content is being edited, render the ContentIsEditing component
				(<ContentIsEditing
					newHeader={newHeader}
					setNewHeader={setNewHeader}
					newContent={newContent}
					setNewContent={setNewContent}
				/>)
			) : (
				<div className="">
					{processedSections}
					<div className="mb-2 mt-4 w-full px-4 pb-3 hidden happydoc-approveAllSection">
						<button
							type="button"
							className="w-full inline-flex items-center justify-center gap-x-1.5 rounded bg-max-700 px-2 py-2 text-xs font-semibold text-white shadow-sm hover:bg-max-800  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bg-max-700"
							onClick={() => {
								setOpen(true);
							}}
						>
							<SendIcon className="w-5 h-5" />
							Approve & Sync ALL
						</button>
					</div>
					<SyncConfirmation open={open} setOpen={setOpen} content={content} />
				</div>
			)}
        </ErrorBoundary>)
    );
};

export default Content;
