import { Switch } from '@headlessui/react'
import { Tooltip } from 'react-tooltip'
import InfoCircleIcon from "@icons/info-circle.svg?react";
import { toolTipStyles } from '@/theme';

interface Props {
    id: string;
    title: string;
    checked: boolean;
    onCheck: (value: boolean) => void;
    tooltip: string;
}

/**
 * A switch component with a title and a tooltip to the left of it
 */
function TitleTooltipSwitch({ id, title, checked, onCheck, tooltip }: Props) {
    return (
        <div className='flex justify-start items-center gap-2'>
            <span className="text-[#135A5D] lining-nums tabular-nums font-plus-jakarta-sans text-xs font-semibold leading-[12px]">{title}</span>
            <div>
                <Tooltip
                    place="right"
                    style={toolTipStyles}
                    anchorSelect={`#${id}`}
                    content={tooltip}
                />
                <InfoCircleIcon
                    id={id}
                    className={`focused h-[16px] w-[16px] text-max-950 ml-auto cursor-pointer`}
                    aria-hidden="true"
                />
            </div>
            <Switch
                checked={checked}
                onChange={onCheck}
                className={`${checked ? "bg-max-700 " : "bg-gray-200"
                    } relative inline-flex h-[20px] w-6 sm:h-5 sm:w-9 items-center rounded-full border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-max-700  focus:ring-offset-2`}
            >
                <div className="items-center justify-center py-4">
                </div>
                <span
                    className={`transform transition ease-in-out duration-200 ${checked ? "translate-x-4 sm:translate-x-5" : "translate-x-1"
                        } inline-block h-2 w-2 sm:h-3 sm:w-3 transform rounded-full bg-white`}
                />
            </Switch>
        </div>
    )
}

export default TitleTooltipSwitch