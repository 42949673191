import { Combobox, ComboboxButton, ComboboxInput, ComboboxOptions, ComboboxOption } from "@headlessui/react";
import classNames from "classnames";
import { useState, useEffect, useCallback } from "react";
import { BroadcastEvents, DocumentType } from "@common/utils/types";
import { useAppSelector, useAppDispatch } from "@/common/hooks/useRedux";
import { addDocumentForDoctor, removeDocumentForDoctor } from "@redux/documentSlice";
import { useRealtimeContextApi } from "@providers/RealtimeProvider";
import CheckIcon from "@icons/check-circle.svg?react";
import ChevronUpDownIcon from "@icons/chevron-selector-vertical.svg?react";
import XIcon from "@icons/x-close.svg?react";

interface DocumentSelectProps {
	disabled?: boolean;
	verticalLayout?: boolean;
	from?: string;
	removeChildren?: boolean;
}

const DocumentSelect = ({ disabled = false, verticalLayout = false, from = "", removeChildren = false }: DocumentSelectProps) => {
	const { selectedDocuments: selectedDocumentIds, documentTypes, hiddenDocuments } = useAppSelector((state) => state.documents);
	const { selectedDoctor } = useAppSelector((state) => state.doctors)
	const dispatch = useAppDispatch();
	const { selectedDocumentsRef, emitEvent } = useRealtimeContextApi();
	const [query, setQuery] = useState("");
	const [filteredDocuments, setFilteredDocuments] = useState(documentTypes);

	const [selectedDocumentObjects, setSelectedDocumentObjects] = useState<DocumentType[]>(documentTypes.filter((doc: DocumentType) => selectedDocumentIds.includes(doc?.id || "")));
	// filter and sort documents
	const filterAndSortDocuments = (query: string, documentTypes: DocumentType[]) => {
		const filteredDocumentTypes = documentTypes
			// filter out inactive documents
			.filter((document: DocumentType) => document?.is_active === true)
			// filter by query string (if we add back the search functionality)
			.filter((document: DocumentType) =>
				query === "" || document?.document_type_name?.toLowerCase().includes(query.toLowerCase())
			)
			// filter out hidden documents (this is a redundant check, we filter out hidden documents in the documentTypes slice)
			.filter((document: DocumentType) => {
				if (hiddenDocuments) {
					return !hiddenDocuments?.includes(document?.id || "");
				}
				return true;
			})
			// sort by document type name alphabetically
			.sort((a: DocumentType, b: DocumentType) =>
				(a.document_type_name && b.document_type_name) ? a.document_type_name.localeCompare(b.document_type_name) : 0
			);

		return filteredDocumentTypes;
	};

	// update filtered documents when query, document types, or hidden documents change
	useEffect(() => {
		const filteredDocs = filterAndSortDocuments(query, documentTypes);
		setFilteredDocuments(filteredDocs);
		setSelectedDocumentObjects(filteredDocs.filter((doc: DocumentType) => selectedDocumentIds.includes(doc?.id || "")));
	}, [query, documentTypes, hiddenDocuments, selectedDocumentIds, selectedDoctor]);

	// handle setting selected documents
	const handleSetSelectedDocuments = useCallback(async (documents: DocumentType[]) => {
		// get the ids of the new documents
		const newIds = documents.map((doc) => doc.id).filter((id) => id !== null && id !== undefined);

		// determine which documents need to be added or removed
		const documentsToAdd = newIds.filter((id) => !selectedDocumentIds.includes(id));
		const documentsToRemove = selectedDocumentIds.filter((id: string) => !newIds.includes(id));

		// add new documents
		for (const id of documentsToAdd) {
			await dispatch(addDocumentForDoctor({ documentTypeId: id }));

			if (selectedDoctor) {
				await emitEvent(BroadcastEvents.documentSelection, { documentTypeId: id, remove: false, doctorId: selectedDoctor?.id });
			}

		}

		// remove deselected documents
		for (const id of documentsToRemove) {
			await dispatch(removeDocumentForDoctor({ documentTypeId: id }));

			if (selectedDoctor) {
				await emitEvent(BroadcastEvents.documentSelection, { documentTypeId: id, remove: true, doctorId: selectedDoctor?.id });
			}
		}

		// update the selected documents ref and component state
		selectedDocumentsRef.current = newIds;
		setSelectedDocumentObjects(documents);
	}, [dispatch, selectedDocumentIds, selectedDocumentsRef]);

	return (
		<div className={classNames("grid w-full gap-2", verticalLayout ? "grid-cols-1" : "grid-cols-1")}>
			<Combobox
				as="div"
				className=""
				by={'id'}
				value={selectedDocumentObjects}
				onChange={handleSetSelectedDocuments}
				multiple
				disabled={disabled}

			>
				<div
					id={`select-documents-from-${from}`}
					className="relative"
					data-pendo={`select-documents-from-${from}`}
				>
					<div className="relative">
						<ComboboxInput
							id="document-select"
							className={classNames(
								disabled ? "text-gray-400 placeholder:text-gray-400" : "text-gray-900",
								"w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10  shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700  text-xs leading-tight sm:text-sm sm:leading-6",
							)}
							onChange={(event) => {
								setQuery(event.target.value);
							}}
							displayValue={() => query}
							data-testid="document-select"
							data-pendo={`document-select`}
							placeholder="Select documents..."
						/>
						<ComboboxButton id="document-select-button" data-pendo={"document-select-button"} className="absolute inset-0" /> {/* Overlays the input so it'll open when clicked */}
					</div>

					<ComboboxButton className="absolute z-10 inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
						<ChevronUpDownIcon id="drop-down" data-pendo={"drop-down"} className="h-5 w-5 text-gray-400" aria-hidden="true" />
					</ComboboxButton>

					{filteredDocuments.length > 0 && (
						<ComboboxOptions className="absolute z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
							{filteredDocuments?.map((document: DocumentType) => (
								<ComboboxOption
									key={document.id}
									value={document}
									className={({ focus }) =>
										classNames(
											"relative cursor-default select-none py-2 pl-3 pr-9",
											focus ? "bg-max-700  text-white" : "text-gray-900",
										)
									}
								>
									{({ focus, selected }) => (
										<>
											<span id="document-select-option" data-pendo={`document-select-option-${document.id}`} className={classNames("block truncate", selected && "font-semibold")}>
												{document.document_type_name}
											</span>

											{selected && (
												<span
													className={classNames(
														"absolute inset-y-0 right-0 flex items-center pr-4",
														focus ? "text-white" : "text-max-700 ",
													)}
												>
													<CheckIcon className="h-5 w-5" aria-hidden="true" />
												</span>
											)}
										</>
									)}
								</ComboboxOption>
							))}
						</ComboboxOptions>
					)}
				</div>
			</Combobox>

			{!removeChildren && selectedDocumentObjects.length > 0 && (
				<div>
					{selectedDocumentObjects.map((document: DocumentType) => (
						<span
							key={document.id}
							id="selected-document"
							data-pendo={`selected-document-${document.id}`}
							className="inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-300 mx-1 my-1 cursor-pointer select-none hover:bg-gray-100"
							onClick={() => {
								if (!disabled) {
									const updatedDocuments = selectedDocumentObjects.filter(
										(selectedDocument) => selectedDocument.id !== document.id
									);
									handleSetSelectedDocuments(updatedDocuments);
								}
							}}
						>
							{document.document_type_name}
							<button
								type="button"
								className="group relative h-3 w-3 rounded-sm ml-1 focus:ring-2 focus:ring-inset focus:ring-max-700 focus:outline-none"
							>
								<span className="sr-only">Remove</span>
								<XIcon className="h-3 w-3 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
								<span className="absolute -inset-1" />
							</button>
						</span>
					))}
				</div>
			)}
		</div>
	);
};

export default DocumentSelect;
