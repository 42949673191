import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { RootState, AppDispatch } from "@/app/store";
import { debounce } from "lodash";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
// This is a custom hook that wraps the useDispatch and useSelector hooks from react-redux with the types of our store
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// Helper function to create a debounced thunk
export function createDebouncedThunk(action: any, wait: number) {
    const debounced = debounce(action, wait, {
        leading: true,
        trailing: false,
    });

    return (...args: any) =>
        (dispatch: any, getState: any) => {
            return debounced(() => dispatch(action(...args)))(dispatch, getState);
        };
}
