import { Transition, Dialog, DialogPanel, DialogTitle, TransitionChild } from "@headlessui/react";
import classNames from "classnames";
import { Fragment, useRef } from "react";

type ConfirmModalProps = {
	id: string;
	headerText: string;
	cancelButtonText: string;
	confirmButtonText: string;
	onCancel: () => void;
	onConfirm: () => void;
	onExit?: () => void;
	isOpen: boolean;
	confirmButtonColor: string;
	confirmHoverButtonColor: string;
	customZIndex?: string;
	contentText?: string;
};

/**
 * Confirm Modal component
 * @param id
 * @param headerText
 * @param cancelButtonText
 * @param confirmButtonText
 * @param onCancel
 * @param onConfirm
 * @param onExit
 * @param isOpen
 * @param confirmButtonColor
 * @param confirmHoverButtonColor
 * @param contentText
 * @returns JSX.Element
 */
const ConfirmModal: React.FC<ConfirmModalProps> = ({
	id,
	headerText,
	cancelButtonText,
	confirmButtonText,
	onCancel,
	onConfirm,
	onExit,
	isOpen,
	contentText,
	confirmButtonColor,
	confirmHoverButtonColor,
}) => {
	const cancelButtonRef = useRef(null);
	confirmHoverButtonColor;
	const handleClose = (e: { preventDefault: () => void }) => {
		onCancel();
	};

	const handleConfirm = (e: { preventDefault: () => void }) => {
		onConfirm();
	};

	return (
		<Transition show={isOpen} as={Fragment}>
			<Dialog as="div" className="relative z-40" open={isOpen} onClose={onExit ? onExit : onCancel}>
				<TransitionChild
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
				</TransitionChild>

				<div className="fixed inset-0 z-40 w-screen overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-[24px] text-center sm:items-center sm:p-0">
						<TransitionChild
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
								<div className=" text-left">
									<DialogTitle as="h1" className="text-xl font-semibold leading-6 text-gray-900">
										{headerText}
									</DialogTitle>
								</div>

								{contentText && (
									<div className="mt-[24px]">
										<p className="text-[14px] text-gray-500">{contentText}</p>
									</div>
								)}

								<div className="flex w-full w-10 mt-[24px] justify-between">
									<button
										type="button"
										className="focused inline-flex  justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
										onClick={handleClose}
										ref={cancelButtonRef}
									>
										{cancelButtonText}
									</button>
									<button
										type="button"
										className={classNames(
											` focused inline-flex   justify-center rounded-md ${confirmButtonColor} px-3 py-2 text-sm font-semibold text-white shadow-sm hover:${confirmHoverButtonColor} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  sm:col-start-2`,
										)}
										onClick={handleConfirm}
									>
										{confirmButtonText}
									</button>
								</div>
							</DialogPanel>
						</TransitionChild>
					</div>
				</div>
			</Dialog>
		</Transition>
	);
};

export default ConfirmModal;
