import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import CheckIcon from "@icons/check-circle.svg?react";
import ChevronDownIcon from "@icons/chevron-down.svg?react";
import classNames from 'classnames';
import React from 'react';
import { Tooltip } from 'react-tooltip';

interface HDSingleSelectProps {
	options: string[];
	value: string | null;
	onChange: (value: string) => void;
	disabled?: boolean;
	customClassNames?: string;
	placeholder?: string;
	id?: string;
	paddingStyle?: string;
	icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
	hasTooltip?: boolean;
	useSearch?: boolean;
}

const HDSingleSelect: React.FC<HDSingleSelectProps> = ({
	options,
	value,
	onChange,
	disabled = false,
	customClassNames = '',
	placeholder = 'Select...',
	id = 'hd-single-select',
	icon,
	paddingStyle = 'py-2',
	hasTooltip = false,
	useSearch = false, // pass useSearch=true to enable search on the select
}) => {

	const [query, setQuery] = React.useState('');

	// Filter options based on the search query
	const filteredOptions = useSearch
		? options.filter((option) =>
			option.toLowerCase().startsWith(query.toLowerCase())
		)
		: options;

	const isSelected = (option: string) => {
		return value === option;
	};

	return (
		<Combobox
			as="div"
			value={value}
			onChange={onChange}
			disabled={disabled}
			className={customClassNames}
			id={id}
			key={id}
			name='hd-single-select'
		>
			<div className="relative" onClick={(e) => e.stopPropagation()}>
				<div className="relative">
					{icon && (
						<div className="absolute inset-y-0 left-0 flex items-center pl-2">
							{/* Render the passed icon component */}
							{React.createElement(icon, { className: "h-5 w-5", "aria-hidden": "true" })}
						</div>
					)}
					<Tooltip
						style={{ maxWidth: "200px", zIndex: 9999, fontSize: "12px", fontFamily: "Plus Jakarta Sans" }}
						anchorSelect={`#hd-single-select-info-icon`}
						content={value || ''}
						place="left"
						disableTooltip={() => !hasTooltip}
					/>
					<ComboboxInput
						className={classNames(
							disabled ? 'text-gray-400 placeholder:text-gray-400' : 'text-gray-900',
							icon ? 'pl-8' : 'pl-3',
							'w-full rounded-md border-0 bg-white pr-10 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-max-700 text-xs leading-tight sm:text-sm sm:leading-6',
							paddingStyle
						)}
						id={`hd-single-select-info-icon`}
						displayValue={(option: string) => option || ''}
						placeholder={placeholder}
						data-testid="string-select"
						readOnly={!useSearch}
						onChange={(e) => setQuery(e.target.value)}
					/>
					<ComboboxButton className="absolute inset-0 z-1" />
				</div>

				<ComboboxButton className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
					<ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
				</ComboboxButton>

				{filteredOptions.length > 0 && (
					<ComboboxOptions className="absolute text-start z-50 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-gray-900 ring-opacity-5 focus:outline-none sm:text-sm">
						{filteredOptions.map((option) => (
							<ComboboxOption
								as='button'
								key={option}
								value={option}
								className={({ focus }) =>
									classNames(
										'w-full flex relative cursor-default select-none  py-2 pl-3 pr-9',
										focus ? 'bg-max-700 text-white' : 'text-gray-900'
									)
								}
							>
								{({ focus }) => (
									<>
										<span className={classNames('block truncate', isSelected(option) && 'font-semibold')}>
											{option}
										</span>
										{isSelected(option) && (
											<span
												className={classNames(
													'absolute inset-y-0 right-0 flex items-center pr-4',
													focus ? 'text-white' : 'text-max-700'
												)}
											>
												<CheckIcon className="h-5 w-5" aria-hidden="true" />
											</span>
										)}
									</>
								)}
							</ComboboxOption>
						))}
					</ComboboxOptions>
				)}
			</div>
		</Combobox>
	);
};

export default HDSingleSelect;