import { Menu, MenuButton, MenuItem, MenuItems, Transition } from "@headlessui/react";
import classNames from "classnames";
import React, { Fragment } from "react";
import { toast } from "react-toastify";
import MoveRecordingModal from "./MoveRecordingModal";
import { PatientRecord, TranscriptSection } from "@/common/utils/types";
import ConfirmModal from "@/common/components/ConfirmModal";
import { useAppDispatch, useAppSelector } from "@/common/hooks/useRedux";
import { getPatientData, removeTranscriptSections } from "../../state/redux/activePatientDataSlice";
import { decrementRecordingCount } from "@/state/redux/patientTableRecordsSlice";
import { deleteTranscriptSection } from "@/common/lib/supabaseClient";
import AudioDb, { INDEX_DB_VERSION } from "@/common/classes/audioDb";
import DotsVerticalIcon from "@icons/dots-vertical.svg?react";
import SwitchHorizontalIcon from "@icons/switch-horizontal-01.svg?react";
import TrashIcon from "@icons/trash-03.svg?react";
import FileSearchIcon from "@icons/file-search-02.svg?react";
import Divider from "@/common/components/Divider";
import { isTranscriptError } from "@/common/utils/helpers";
import RefreshIcon from "@icons/refresh-cw-03.svg?react";
import RetryTranscriptSection from "@/common/components/RetryTranscriptSection";

type Props = {
	transcriptSection: TranscriptSection;
	setOpenTranscriptModal: React.Dispatch<React.SetStateAction<boolean>>;
	setSelectedTranscriptSectionId: React.Dispatch<React.SetStateAction<string | null>>
};

function ThreeDotRecordingMenu({ transcriptSection, setOpenTranscriptModal, setSelectedTranscriptSectionId }: Props) {
	const [moveRecordingModalOpen, setMoveRecordingModalOpen] = React.useState(false);
	const [isDeleteOneModalOpen, setIsDeleteOneModalOpen] = React.useState(false);
	const patientData: PatientRecord = useAppSelector(getPatientData);
	const patientId = patientData?.id

	const dispatch = useAppDispatch();
	const localAudioDb = AudioDb.getInstance(INDEX_DB_VERSION);

	const removeAndDeleteTranscriptSection = async () => {
		const id = transcriptSection.id;
		const transcriptionId = transcriptSection.transcription_id;

		if (!transcriptionId) {
			toast.error("Error deleting recording");
			return;
		}

		dispatch(removeTranscriptSections([id]));
		dispatch(decrementRecordingCount({ transcriptionId: transcriptSection.transcription_id || "", sectionIds: [id] }));
		await localAudioDb?.deleteTranscriptSectionAudio(transcriptSection.transcription_id || "", id);
		await deleteTranscriptSection(id);
	};

	return (
		<>
			<Menu as="div" className="relative flex-none select-none">
				<MenuButton className="focused border border-gray-300 rounded-md p-2 bg-white text-gray-700 hover:text-gray-900 hover:bg-gray-100 focus">
					<span className="sr-only">Open options</span>
					<DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
				</MenuButton>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<MenuItems className=" absolute right-0 flex-col items-start justify-start z-20 mt-2 w-40 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
						<MenuItem>
							{({ focus }) => (
								<button
									id="move-recording"
									className={classNames(
										focus ? "bg-gray-50" : "",
										" flex justify-start items-center block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 font-medium",
									)}
									onClick={(e) => {
										setMoveRecordingModalOpen(true);
									}}
								>
									<SwitchHorizontalIcon className="h-4 w-4 mr-2" aria-hidden="true" />
									Move Clip
								</button>
							)}
						</MenuItem>
						<MenuItem>
							{({ focus }) => (
								<button
									id="view-transcript"
									className={classNames(
										focus ? "bg-gray-50" : "",
										" flex justify-start items-center block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 font-medium",
									)}
									onClick={(e) => {
										setOpenTranscriptModal(true);
										setSelectedTranscriptSectionId(transcriptSection?.id);
									}}
								>
									<FileSearchIcon className="h-4 w-4 mr-2" aria-hidden="true" />
									View Transcript
								</button>
							)}
						</MenuItem>
						{isTranscriptError(transcriptSection) &&
							<MenuItem>
								{({ focus }) => (
									<div
										id="retry"
										className={classNames(
											focus ? "bg-gray-50" : "",
											" flex justify-start items-center block w-full text-left px-3 py-1 text-sm leading-6 text-gray-900 font-medium",
										)}
									>
										<RetryTranscriptSection patientRecord={patientData} transcriptSection={transcriptSection}>
											<RefreshIcon className="h-4 w-4 mr-2 text-red-600" aria-hidden="true" />
											<span className="text-red-600 text-sm leading-6 font-medium">Retry</span>
										</RetryTranscriptSection>
									</div>
								)}
							</MenuItem>}
						<Divider customClassNames="text-gray-200" />
						<MenuItem>
							{({ focus }) => (
								<button
									id="delete-transcript"
									className={classNames(
										focus ? "bg-gray-50" : "",
										"flex  justify-start items-center block w-full text-left px-3 py-1 text-sm leading-6 font-medium text-gray-900 font-medium",
									)}
									onClick={() => {
										setIsDeleteOneModalOpen(true);
									}}
								>
									<TrashIcon className="h-4 w-4 mr-2" aria-hidden="true" />
									Delete Clip
								</button>
							)}
						</MenuItem>

					</MenuItems>
				</Transition>
			</Menu>
			<MoveRecordingModal
				patientId={patientId}
				transcriptionId={transcriptSection.transcription_id || ""}
				transcriptSection={transcriptSection}
				isOpen={moveRecordingModalOpen}
				onCancel={() => setMoveRecordingModalOpen(false)}
				onConfirm={() => {
					setMoveRecordingModalOpen(false);
				}}
			/>
			<ConfirmModal
				id="DeleteOneTranscript"
				headerText="Are you sure you want to delete this clip?"
				cancelButtonText="Cancel"
				confirmButtonText="Delete"
				confirmButtonColor="bg-red-600"
				confirmHoverButtonColor="bg-red-500"
				onCancel={() => {
					setIsDeleteOneModalOpen(false);
				}}
				onConfirm={() => {
					removeAndDeleteTranscriptSection();
					setIsDeleteOneModalOpen(false);
				}}
				isOpen={isDeleteOneModalOpen}
			/>
		</>
	);
}

export default ThreeDotRecordingMenu;
